import { Box, Link, Paper, Typography } from '@mui/material';
import OpenLink from '@/ts/Components/icons/OpenLink';
import { theme } from '@/ts/theme';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  Format,
  FormatDraft,
  getImageSourceSet,
  getLargestImage,
  imagesByIndex,
  nextDate,
  readablePrimaryType,
  sortedDates,
} from '@/ts/Types/Format';
import { PlatformIcon } from '@/ts/Components/icons/Platform';
import { ReadableFormatType } from '@/ts/Types/Readable';

export default function FormatCard({
  format,
  readableFormatTypes,
}: {
  format: Format | FormatDraft;
  readableFormatTypes: ReadableFormatType[];
}) {
  const link = '/formats/' + format.id;

  const hiddenInformationText = useRef<HTMLDivElement>(null);
  const visibleInformationText = useRef<HTMLDivElement>(null);
  const [advertisementTypeInformationTextContainerHeight, setAdvertisementTypeInformationTextContainerHeight] =
    useState(hiddenInformationText.current?.clientHeight);
  useLayoutEffect(() => {
    if (hiddenInformationText.current) {
      setAdvertisementTypeInformationTextContainerHeight(hiddenInformationText.current.clientHeight);
    }
  });
  useEffect(() => {
    function handleWindowResize() {
      if (hiddenInformationText.current && visibleInformationText.current) {
        visibleInformationText.current.style.transition = 'none';
        setAdvertisementTypeInformationTextContainerHeight(hiddenInformationText.current.clientHeight);
        visibleInformationText.current.style.transition = '';
      }
    }

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const formatImagesByIndex = imagesByIndex(format.images ?? []);

  return (
    <>
      <PlatformsHead>
        {(format.platforms ?? []).map((platform) => (
          <PlatformIcon key={platform.name} name={platform.name} size={24} />
        ))}
        <FormatLink>
          <Link href={link} underline="none" height={22}>
            <OpenLink size={22} />
          </Link>
        </FormatLink>
      </PlatformsHead>
      <FormatInformation>
        <Link href={link} underline="none">
          <Box
            sx={{
              width: 1,
              borderRadius: '22px',
              aspectRatio: 16 / 9,
              overflow: 'hidden',
              background: theme.palette.grey['500'],
            }}
          >
            {formatImagesByIndex.length > 0 ? (
              <img
                srcSet={getImageSourceSet(formatImagesByIndex[0])}
                src={getLargestImage(formatImagesByIndex[0])?.url}
                alt={format.name}
                style={{ objectFit: 'cover', height: '100%', width: '100%' }}
              />
            ) : null}
          </Box>
        </Link>
        <FormatInformationText style={{ visibility: 'hidden' }} ref={hiddenInformationText}>
          <FormatInformationContent format={format} readableFormatTypes={readableFormatTypes} />
        </FormatInformationText>
        <Link href={link} underline="none">
          <FormatInformationText
            sx={{
              height: advertisementTypeInformationTextContainerHeight,
              position: 'absolute',
              bottom: 0,
              width: '100%',
              transition: 'all 0.6s ease-in-out',
            }}
            className="formatInformationTextSlide"
            ref={visibleInformationText}
          >
            <FormatInformationContent format={format} readableFormatTypes={readableFormatTypes} />
          </FormatInformationText>
        </Link>
      </FormatInformation>
    </>
  );
}

function FormatInformationContent({
  format,
  readableFormatTypes,
}: {
  format: Format | FormatDraft;
  readableFormatTypes: ReadableFormatType[];
}) {
  const displayedDate = nextDate(format) ?? sortedDates(format).reverse()[0];

  return (
    <>
      <FormatHead>
        <Typography
          variant="subtitle2"
          dangerouslySetInnerHTML={{
            __html: readablePrimaryType(format, readableFormatTypes),
          }}
        />
        <Typography variant="body2" sx={{ lineHeight: 1 }}>
          {!!displayedDate &&
            new Date(displayedDate.start_time).toLocaleDateString('de-DE', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
        </Typography>
      </FormatHead>
      <Typography variant="h4" dangerouslySetInnerHTML={{ __html: format.name }} />
      <Typography variant="body2">{format.short_description}</Typography>
    </>
  );
}

const PlatformsHead = styled(Paper)`
  padding: 18px 26px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;

const FormatHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

const FormatInformationText = styled(Paper)`
  padding: 22px 26px;
  border-radius: 22px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const FormatInformation = styled.div`
  width: 100%;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  position: relative;
  overflow: hidden;

  h4,
  h6,
  p {
    transition: color 0.6s ease-in-out;
  }

  &:hover {
    .formatInformationTextSlide {
      height: 100%;
      background-color: #e7fd70;

      h4,
      h6,
      p {
        color: #121212;
      }
    }
  }
`;

const FormatLink = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
