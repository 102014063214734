import { FormatImage } from '@/ts/Types/Format';

export type Image = {
  url: string;
  width: number;
  aspectRatio: number;
};

export function getImageSourceSet(image: Image[]) {
  return image.reduce((sourceSetString, image) => {
    const widthSource = image.url + ' ' + image.width + 'w';
    if (sourceSetString === '') return widthSource;
    return sourceSetString + ', ' + widthSource;
  }, '');
}

export function getLargestImage(image: Image[]) {
  if (image.length === 0) return null;
  const imageSet = [...image];
  imageSet.sort((a, b) => {
    return b.width - a.width;
  });
  return imageSet[0];
}
