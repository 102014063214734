import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import { Dayjs } from 'dayjs';
import { theme } from '@/ts/theme';

export default function FilterDatePicker({
  value,
  setValue,
}: {
  value: Dayjs | null;
  setValue: (newValue: Dayjs | null) => void;
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <DatePicker
          sx={{
            width: '100%',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: value !== null ? theme.palette.primary.main : 'unset',
            },
            '.MuiInputBase-input': {
              color: value !== null ? theme.palette.primary.main : 'unset',
            },
          }}
          value={value}
          onChange={(newValue) => setValue(newValue)}
          slotProps={{
            field: { clearable: true, onClear: () => setValue(null) },
          }}
          format="DD.MM.YYYY"
        />
      </Box>
    </LocalizationProvider>
  );
}
