import Page from '@/ts/Components/Page';
import { PAGE } from '@/ts/Components/Header';
import BreadcrumbsNavigation from '@/ts/Components/BreadcrumbsNavigation';
import { Box, Button, Container, Dialog, Grid, Paper, TextField, Typography } from '@mui/material';
import { theme } from '@/ts/theme';
import React from 'react';
import { useForm, usePage } from '@inertiajs/react';
import { User } from '@/ts/Types/User';
import Delete from '@/ts/Components/icons/Delete';
import Hide from '@/ts/Components/icons/Hide';
import axios from 'axios';
import { Topic } from '@/ts/Types/Topic';

const title = 'Themen-Administration';

export default function UserManagementPage() {
  const { topics, user } = usePage<{
    topics: Topic[];
    user: User;
  }>().props;
  const [creationDialogOpen, setCreationDialogOpen] = React.useState(false);

  return (
    <Page user={user} title={title} activePage={PAGE.Administration}>
      <BreadcrumbsNavigation previousPages={[{ name: 'Administration', url: '/administration' }]} currentPage={title} />
      <Container maxWidth="xl" disableGutters>
        <Typography variant="h1" dangerouslySetInnerHTML={{ __html: title }} />
        <Button sx={{ marginBottom: 4 }} variant={'outlined'} onClick={() => setCreationDialogOpen(true)}>
          Neues Thema anlegen
        </Button>
        <Grid spacing={'20px'} container>
          {topics.map((topic) => {
            return <TopicElement topic={topic} key={topic.id} />;
          })}
        </Grid>
      </Container>
      <FormDialog open={creationDialogOpen} setOpen={setCreationDialogOpen} user={null} />
    </Page>
  );
}

type TopicFormState = {
  name: string;
};

function TopicElement({ topic }: { topic: Topic }) {
  return (
    <>
      <Grid item xs={12} sm={6} lg={4}>
        <Paper
          sx={{
            width: 1,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.grey['500'],
            borderRadius: '22px',
            paddingY: '22px',
            paddingX: '22px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h3">#{topic.name}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box sx={{ cursor: 'pointer' }} onClick={() => deleteTopic(topic)}>
              <Delete size={44} color={theme.palette.error.main} />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </>
  );
}

function FormDialog({ open, setOpen, user }: { open: boolean; setOpen: (open: boolean) => void; user: User | null }) {
  const { data, setData } = useForm<TopicFormState>({ name: '' });
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      onClose={() => setOpen(false)}
      sx={{
        backdropFilter: 'blur(5px)',
        borderRadius: '22px',
      }}
      PaperProps={{
        sx: {
          padding: '1.75rem',
          borderRadius: '22px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.75rem',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h2" sx={{ lineHeight: 1 }}>
          Thema anlegen
        </Typography>
        <Box sx={{ cursor: 'pointer' }} onClick={() => setOpen(false)}>
          <Hide size={36} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <TextField
          label="Name"
          variant="outlined"
          value={data.name}
          onChange={(e) => setData('name', e.target.value)}
          fullWidth
          required
        />
        <Button disabled={!isValidInput(data)} variant="contained" onClick={() => createTopic(data)}>
          Speichern
        </Button>
      </Box>
    </Dialog>
  );
}

function isValidInput(inputData: TopicFormState) {
  return inputData.name.trim().length !== 0;
}

function createTopic(inputData: TopicFormState) {
  const form = new FormData();
  form.append('name', inputData.name);
  axios.post('/api/content-topics', form).then(() => window.location.reload());
}

function deleteTopic(topic: Topic) {
  axios.delete('/api/content-topics/' + topic.id).then(() => window.location.reload());
}
