import { PAGE } from '@/ts/Components/Header';
import Page from '@/ts/Components/Page';
import { useForm } from '@inertiajs/react';
import { Alert, Box, Button, Container, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ForgotPassword({ status }: { status?: string }) {
  const { data, setData, post, processing, errors, reset } = useForm({
    email: '',
  });

  const { t } = useTranslation(['forms']);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    post('/forgot-password', {
      onSuccess: () => {
        reset('email');
      },
    });
  };

  return (
    <Page title={'Forgot Password'} activePage={PAGE.Other}>
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h2" sx={{ lineHeight: 1 }}>
            {t('forgot_password_title')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ lineHeight: 1 }}>
            {t('forgot_password_description')}
          </Typography>
        </Box>
        <br />
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <TextField
              label="E-Mail"
              variant="outlined"
              value={data.email}
              onChange={(e) => setData('email', e.target.value)}
              fullWidth
              required
              type="email"
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Button sx={{ mt: 2 }} disabled={processing || !isValidInput(data)} variant="contained" type="submit">
              {t('submit')}
            </Button>
          </Box>
        </form>
        {status && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
            <Alert severity="success">{status}</Alert>
          </Box>
        )}
        {errors.email && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
            <Alert severity="error">{errors.email}</Alert>
          </Box>
        )}
      </Container>
    </Page>
  );
}

function isValidInput(inputData: { email: string }) {
  if (inputData.email.trim().length === 0) return false;
  return true;
}
