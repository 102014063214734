import { FormSectionHeadline, FormSectionPaper } from '@/ts/Pages/FormatFormPage';
import { FormState } from '@/ts/Types/Format';
import { ReadableLanguage } from '@/ts/Types/Readable';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { useErrors } from './ErrorsContext';
import { useMemo } from 'react';

const FavoriteLanguageCodes = ['en', 'de'];

export default function LanguageForm({
  availableLanguages,
  formState,
  setLanguage,
}: {
  availableLanguages: ReadableLanguage[];
  formState: FormState;
  setLanguage: (language: string) => void;
}) {
  const { getError } = useErrors();
  const error = getError('language');

  const sortedLanguagesWithFavoritesAtBeginning = useMemo(() => {
    const favoriteLanguages = availableLanguages.filter((language) => FavoriteLanguageCodes.includes(language.code));
    favoriteLanguages.sort((a, b) => a.readable.localeCompare(b.readable));
    const otherLanguages = availableLanguages.filter((language) => !FavoriteLanguageCodes.includes(language.code));
    return [...favoriteLanguages, ...otherLanguages];
  }, [availableLanguages]);

  return (
    <>
      <FormSectionHeadline hasError={!!error} data-has-error={!!error}>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Sprache' }} />
      </FormSectionHeadline>
      <FormSectionPaper hasError={!!error}>
        <Autocomplete
          noOptionsText={'Keine Sprache gefunden'}
          disablePortal
          value={
            formState.language === null
              ? null
              : availableLanguages.find((language) => language.code === formState.language)
          }
          onChange={(event, newLanguage) => {
            if (newLanguage != null) setLanguage(newLanguage.code);
          }}
          options={sortedLanguagesWithFavoritesAtBeginning}
          getOptionLabel={(language) => language.readable}
          renderInput={(params) => <TextField {...params} hiddenLabel placeholder="Sprache" />}
        />
        {!!error && <Typography color="error">{error.message}</Typography>}
      </FormSectionPaper>
    </>
  );
}
