import { FormFieldLabelBox, FormSectionHeadline, FormSectionPaper } from '@/ts/Pages/FormatFormPage';
import { Container, Grid, TextField, Typography } from '@mui/material';
import ImagesForm from '@/ts/Components/Form/ImagesForm';
import PlatformsForm from '@/ts/Components/Form/PlatformsForm';
import * as Types from '../Types';

type Props = {
  data: Types.Werbeformat;
  onDataChange: (DataTransfer: Types.Werbeformat) => void;
  children?: React.ReactNode;
  errorMessages: Types.errorMessages;
  contentPlatforms: Types.ReadablePlatform[];
};

const getError = (field: string, errorMessages: Types.errorMessages): string | undefined => {
  if (field === 'list_of_platforms.*') {
    return errorMessages.find((error) => error.field.includes('list_of_platforms'))?.message;
  } else {
    const frontendError = errorMessages.find((error) => error.field.indexOf(field) === 0);
    return frontendError?.message;
  }
};

export default function WebformatForm({ data, onDataChange, children, errorMessages, contentPlatforms }: Props) {
  const nameErrorMsg = getError('name', errorMessages);
  const slugErrorMsg = getError('slug', errorMessages);
  const descriptionErrorMsg = getError('description', errorMessages);
  const imageErrorMsg = getError('image', errorMessages) || getError('old_image', errorMessages);
  const platformErrorMsg = getError('list_of_platforms.*', errorMessages);

  return (
    <Container disableGutters maxWidth="xl" sx={{ position: 'relative' }}>
      <form>
        <Grid container rowSpacing={12.5} justifyContent="center" sx={{ marginTop: 0, paddingTop: 0 }}>
          <Grid item xs={12} sm={8} style={{ paddingTop: 0 }}>
            <FormSectionHeadline
              hasError={!!nameErrorMsg || !!descriptionErrorMsg || !!slugErrorMsg}
              data-has-error={!!nameErrorMsg || !!descriptionErrorMsg || !!slugErrorMsg}
            >
              <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Werbeformat' }} />
            </FormSectionHeadline>
            <FormSectionPaper hasError={!!nameErrorMsg || !!descriptionErrorMsg || !!slugErrorMsg}>
              <FormFieldLabelBox>
                <Typography variant="subtitle2" fontSize="0.8125rem">
                  Name
                </Typography>
                <TextField
                  hiddenLabel
                  placeholder="Name"
                  variant="outlined"
                  value={data.name}
                  onChange={(e) => onDataChange({ ...data, name: e.target.value })}
                  fullWidth
                  required
                  error={!!nameErrorMsg}
                  helperText={nameErrorMsg}
                />
              </FormFieldLabelBox>
              <FormFieldLabelBox>
                <Typography variant="subtitle2" fontSize="0.8125rem">
                  Url
                </Typography>
                <TextField
                  hiddenLabel
                  placeholder="Slug"
                  variant="outlined"
                  value={data.slug}
                  onChange={(e) => onDataChange({ ...data, slug: e.target.value })}
                  fullWidth
                  required
                  error={!!slugErrorMsg}
                  helperText={slugErrorMsg}
                />
              </FormFieldLabelBox>
              <FormFieldLabelBox>
                <Typography variant="subtitle2" fontSize="0.8125rem">
                  Beschreibung
                </Typography>
                <TextField
                  hiddenLabel
                  placeholder="Beschreibung"
                  variant="outlined"
                  value={data.description}
                  onChange={(e) => onDataChange({ ...data, description: e.target.value })}
                  fullWidth
                  required
                  multiline
                  minRows={3}
                  error={!!descriptionErrorMsg}
                  helperText={descriptionErrorMsg}
                />
              </FormFieldLabelBox>
            </FormSectionPaper>
          </Grid>
          <Grid item xs={12} sm={8}>
            <ImagesForm
              data={data}
              setImages={(
                images: (File & {
                  preview: string;
                })[],
              ) => {
                const lastImage = images[images.length - 1];
                onDataChange({ ...data, images: lastImage ? [lastImage] : [] });
              }}
              setOldImages={(oldImages) => console.log('')}
              errorMessage={imageErrorMsg}
              asSingular={true}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <PlatformsForm
              availablePlatforms={contentPlatforms}
              data={{
                platforms: data.platforms.map((platform) => platform.name),
              }}
              setPlatforms={(selectedPlatforms: Types.Werbeformat['platforms'][number]['name'][]) =>
                onDataChange({ ...data, platforms: selectedPlatforms.map((name) => ({ name })) })
              }
              errorMessage={platformErrorMsg}
            />
          </Grid>
          {children}
        </Grid>
      </form>
    </Container>
  );
}
