import { Box, Link, Paper, Typography } from '@mui/material';
import OpenLink from '@/ts/Components/icons/OpenLink';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { AdvertisementType } from '@/ts/Types/AdvertisementType';
import { PlatformIcon } from '@/ts/Components/icons/Platform';
import { getImageSourceSet, getLargestImage } from '@/ts/Types/Image';
import { DEFAULT_IMAGE } from '@/ts/Pages/utils';

export default function AdvertisementTypeCard({ advertisementType }: { advertisementType: AdvertisementType }) {
  const hiddenInformationText = useRef<HTMLDivElement>(null);
  const visibleInformationText = useRef<HTMLDivElement>(null);
  const [advertisementTypeInformationTextContainerHeight, setAdvertisementTypeInformationTextContainerHeight] =
    useState(hiddenInformationText.current?.clientHeight);
  useLayoutEffect(() => {
    if (hiddenInformationText.current) {
      setAdvertisementTypeInformationTextContainerHeight(hiddenInformationText.current.clientHeight);
    }
  });
  useEffect(() => {
    function handleWindowResize() {
      if (hiddenInformationText.current && visibleInformationText.current) {
        visibleInformationText.current.style.transition = 'none';
        setAdvertisementTypeInformationTextContainerHeight(hiddenInformationText.current.clientHeight);
        visibleInformationText.current.style.transition = '';
      }
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const images = advertisementType.images.length > 0 ? advertisementType.images : DEFAULT_IMAGE;

  return (
    <>
      <PlatformsHead>
        {advertisementType.platforms.map((platform) => (
          <PlatformIcon key={platform.name} name={platform.name} size={28} />
        ))}
        <AdvertisementTypeLink>
          <Link href={'/advertisement-types/' + advertisementType.slug} underline="none" height={28}>
            <OpenLink size={28} />
          </Link>
        </AdvertisementTypeLink>
      </PlatformsHead>
      <AdvertisementTypeInformation>
        <Link href={'/advertisement-types/' + advertisementType.slug} underline="none">
          <Box sx={{ width: 1, borderRadius: '22px', aspectRatio: 16 / 9, overflow: 'hidden' }}>
            <img
              srcSet={getImageSourceSet(images)}
              src={getLargestImage(images)?.url}
              alt={advertisementType.name}
              style={{ objectFit: 'cover', height: '100%', width: '100%' }}
            />
          </Box>
        </Link>
        <HiddenAdvertisementTypeInformationText ref={hiddenInformationText}>
          <AdvertisementTypeInformationContent advertisementType={advertisementType} />
        </HiddenAdvertisementTypeInformationText>
        <Link href={'/advertisement-types/' + advertisementType.slug} underline="none">
          <AdvertisementTypeInformationText
            sx={{ height: advertisementTypeInformationTextContainerHeight }}
            className="advertisementTypeInformationTextSlide"
            ref={visibleInformationText}
          >
            <AdvertisementTypeInformationContent advertisementType={advertisementType} />
          </AdvertisementTypeInformationText>
        </Link>
      </AdvertisementTypeInformation>
    </>
  );
}

function AdvertisementTypeInformationContent({ advertisementType }: { advertisementType: AdvertisementType }) {
  return (
    <>
      <Typography variant="subtitle1" dangerouslySetInnerHTML={{ __html: 'Werbeformat' }} />
      <Typography variant="h3" dangerouslySetInnerHTML={{ __html: advertisementType.name }} />
      <Typography variant="body1">{advertisementType.description}</Typography>
    </>
  );
}

const PlatformsHead = styled(Paper)`
  padding: 22px 26px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HiddenAdvertisementTypeInformationText = styled.div`
  padding: 22px 26px;
  border-radius: 22px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  visibility: hidden;
`;

const AdvertisementTypeInformationText = styled(Paper)`
  padding: 22px 26px;
  border-radius: 22px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all 0.6s ease-in-out;
`;

const AdvertisementTypeInformation = styled.div`
  width: 100%;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  position: relative;
  overflow: hidden;

  &:hover {
    .advertisementTypeInformationTextSlide {
      height: 100%;
      background-color: #e7fd70;

      h3,
      h6,
      p {
        color: #121212;
      }
    }
  }
`;

const AdvertisementTypeLink = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
