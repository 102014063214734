import { Button, FormControl, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import { NeverNotNewInput, theme } from '@/ts/theme';
import styled from '@emotion/styled';
import { AlphabeticalSortingIcon, Sorting } from '@/ts/Components/Sorting';
import ArrowDownPrimary from '@/ts/Components/icons/ArrowDownPrimary';
import CheckboxChecked from '@/ts/Components/icons/CheckboxChecked';
import CheckboxUnchecked from '@/ts/Components/icons/CheckboxUnchecked';
import { useTranslation } from 'react-i18next';

type SortFilterBarProps = {
  nameSorting: Sorting;
  toggleSorting: () => void;
  currentPlatformFilters: string[];
  platformsFilters: string[];
  handlePlatformFilterSelect: (event: SelectChangeEvent<string[]>) => void;
};

export default function SortFilterBar({
  nameSorting,
  toggleSorting,
  currentPlatformFilters,
  platformsFilters,
  handlePlatformFilterSelect,
}: SortFilterBarProps) {
  const { t } = useTranslation('common');

  return (
    <Background>
      <Typography color="primary" fontSize="1.5rem" textTransform="uppercase">
        {t('sort_by')}
      </Typography>
      <Button
        variant="outlined"
        endIcon={<AlphabeticalSortingIcon sorting={nameSorting} />}
        onClick={toggleSorting}
        sx={{
          borderRadius: 3,
          textTransform: 'uppercase',
          width: 220,
          lineHeight: 1.5,
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
          border: '1px solid rgba(231, 253, 112, 0.8)',
          fontSize: '1rem',
          justifyContent: 'space-between',
          padding: 2,
        }}
      >
        Name
      </Button>
      <FormControl sx={{ maxWidth: 270 }}>
        <Select
          multiple
          displayEmpty
          value={currentPlatformFilters}
          renderValue={(selected) => {
            return (
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  textTransform: 'uppercase',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {selected.length === platformsFilters.length ? 'Alle Plattformen' : selected.join(', ')}
              </Typography>
            );
          }}
          onChange={handlePlatformFilterSelect}
          inputProps={{ 'aria-label': 'Without label' }}
          input={
            <NeverNotNewInput
              sx={{
                borderRadius: '12px',
                border: '1px solid ' + theme.palette.primary.main,
              }}
            />
          }
          IconComponent={ArrowDownPrimary}
          MenuProps={{
            sx: {
              '& .MuiMenu-paper': {
                borderRadius: '12px',
                border: '1px solid ' + theme.palette.primary.main,
              },
              '& .MuiMenuItem-root': {
                opacity: 0.32,
                transition: 'all 0.2s ease-in-out',
                paddingY: 1,
              },
              '& .MuiMenuItem-root:hover': {
                opacity: 0.72,
              },
              '& .MuiMenuItem-root:focus': {
                backgroundColor: theme.palette.grey['100'],
              },
              '& .Mui-selected': {
                opacity: 0.72,
              },
            },
          }}
        >
          {platformsFilters.map((filterOption) => {
            return (
              <MenuItem
                key={filterOption}
                value={filterOption}
                sx={{
                  textTransform: 'capitalize',
                  color: 'white',
                  backgroundColor: theme.palette.grey['100'],
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>{filterOption}</div>
                {currentPlatformFilters.includes(filterOption) ? <CheckboxChecked /> : <CheckboxUnchecked />}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Background>
  );
}
const Background = styled(Paper)`
  padding: 1rem 26px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
  justify-content: stretch;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }

  p {
    flex: 1;
  }

  button {
    @media (max-width: 767.9px) {
      width: 100%;
    }
  }
`;
