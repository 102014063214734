import { SvgIcon } from '@mui/material';

export default function SortingGeneralAscending() {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24">
      <path
        d="M4 17H9M4 12H12M17 9.5V18.5M17 18.5L20 15.5M17 18.5L14 15.5M4 7H15"
        stroke="#E7FD70"
        strokeOpacity="0.8"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
