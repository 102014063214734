export type ContentPlatform = {
  name: ContentPlatformName;
};

export type ContentPlatformName = 'twitch' | 'joyn' | 'youtube' | 'podcast';

export type CreatorPlatform = {
  name: CreatorPlatformName;
};

export type CreatorPlatformName = 'twitch' | 'youtube' | 'instagram' | 'tiktok' | 'twitter';

export function getPlatformUrl(platformName: CreatorPlatformName, handle: string) {
  switch (platformName) {
    case 'twitch':
      return 'https://www.twitch.tv/' + handle;
    case 'youtube':
      return 'https://www.youtube.com/@' + handle;
    case 'instagram':
      return 'https://www.instagram.com/' + handle;
    case 'tiktok':
      return 'https://www.tiktok.com/@' + handle;
    case 'twitter':
      return 'https://twitter.com/' + handle;
  }
}
