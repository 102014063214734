import FilterDatePicker from '@/ts/Components/Formats/FilterDatePicker';
import FormatCard from '@/ts/Components/Formats/FormatCard';
import MultiSelectFilter from '@/ts/Components/Formats/MultiSelectFilter';
import SortBar from '@/ts/Components/Formats/SortBar';
import { PAGE } from '@/ts/Components/Header';
import { Sorting } from '@/ts/Components/Sorting';
import {
  Format,
  FormatDraft,
  Language,
  dateIsEarlierOrEqual,
  dateIsLaterOrEqual,
  getEarliestDate,
  getLastDate,
  nextDate,
} from '@/ts/Types/Format';
import { ReadableFormatType, ReadablePlatform } from '@/ts/Types/Readable';
import { ROLE_ADMIN, ROLE_ADVERTISER, ROLE_CREATOR_MANAGEMENT, User } from '@/ts/Types/User';
import { Link, usePage } from '@inertiajs/react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import BreadcrumbsNavigation from '../Components/BreadcrumbsNavigation';
import Page from '../Components/Page';
import { Topic } from '../Types/Topic';

export type FilterProps = {
  allFilterOptions: string[];
  selectedFilterOptions: string[];
  handleFilterSelect: (event: SelectChangeEvent<string[]>) => void;
};

export default function FormatListPage() {
  const DEFAULT_FALLBACK_FILTERING_MISSING_VALUES = false; // true: keep entries, false: filter out

  const { formats, user, contentPlatforms, formatTypes } = usePage<{
    formats: (Format | FormatDraft)[];
    user: User;
    contentPlatforms: ReadablePlatform[];
    formatTypes: ReadableFormatType[];
  }>().props;

  const [filteringEnabled, setFilteringEnabled] = useState(true);
  const title = user.role === ROLE_CREATOR_MANAGEMENT ? 'Meine Showformate' : 'Showformate';

  const formatsToDisplay =
    user.role === ROLE_ADVERTISER ? formats.filter((format) => nextDate(format) !== undefined) : formats;

  const hosts = Array.from(new Set(formatsToDisplay.map((format) => format.host?.name).filter(Boolean)));

  const [nameSorting, setNameSorting] = useState(Sorting.None);
  const [dateSorting, setDateSorting] = useState(Sorting.Ascending);
  const toggleNameSorting = () => {
    if (nameSorting === Sorting.None) {
      setNameSorting(Sorting.Ascending);
      setDateSorting(Sorting.None);
    }
    if (nameSorting === Sorting.Ascending) {
      setNameSorting(Sorting.Descending);
      setDateSorting(Sorting.None);
    }
    if (nameSorting === Sorting.Descending) {
      setNameSorting(Sorting.None);
    }
  };

  const toggleDateSorting = () => {
    if (dateSorting === Sorting.None) {
      setDateSorting(Sorting.Ascending);
      setNameSorting(Sorting.None);
    }
    if (dateSorting === Sorting.Ascending) {
      setDateSorting(Sorting.Descending);
      setNameSorting(Sorting.None);
    }
    if (dateSorting === Sorting.Descending) {
      setDateSorting(Sorting.Ascending);
      setNameSorting(Sorting.None);
    }
  };

  const [filterFormatDrafts, setFilterFormatDrafts] = useState(false);

  const getSortedFormats = () => {
    if (dateSorting === Sorting.None && nameSorting !== Sorting.None) {
      if (nameSorting === Sorting.Ascending) return [...formatsToDisplay].sort((a, b) => a.name.localeCompare(b.name));
      if (nameSorting === Sorting.Descending) return [...formatsToDisplay].sort((a, b) => b.name.localeCompare(a.name));
    }

    if (nameSorting === Sorting.None && dateSorting !== Sorting.None) {
      if (dateSorting === Sorting.Ascending)
        return [...formatsToDisplay].sort((a, b) => {
          return new Date(nextDate(a)?.start_time ?? 0).getTime() - new Date(nextDate(b)?.start_time ?? 0).getTime();
        });
      if (dateSorting === Sorting.Descending)
        return [...formatsToDisplay].sort((a, b) => {
          return new Date(nextDate(b)?.start_time ?? 0).getTime() - new Date(nextDate(a)?.start_time ?? 0).getTime();
        });
    }
    if (nameSorting === Sorting.None && dateSorting === Sorting.None) {
      // same as date ascending
      return [...formatsToDisplay].sort((a, b) => {
        return new Date(nextDate(a)?.start_time ?? 0).getTime() - new Date(nextDate(b)?.start_time ?? 0).getTime();
      });
    }
    console.error('Sorting not implemented');
    return formatsToDisplay;
  };

  const [selectedPlatformFilters, setSelectedPlatformFilters] = useState<ReadablePlatform['readable'][]>([]);
  const [selectedHostFilters, setSelectedHostFilters] = useState<string[]>([]);

  const platformIntersection = (format: Format | FormatDraft) =>
    (format.platforms ?? []).filter((platform) =>
      contentPlatforms
        .filter((_) => selectedPlatformFilters.includes(_.readable))
        .map((_) => _.name)
        .includes(platform.name),
    );

  const [selectedFormatTypeFilters, setSelectedFormatTypeFilters] = useState<ReadableFormatType['readable'][]>([]);
  const formatTypeIntersection = (format: Format | FormatDraft) =>
    (format.types ?? []).filter((formatType) =>
      formatTypes
        .filter((_) => selectedFormatTypeFilters.includes(_.readable))
        .map((_) => _.name)
        .includes(formatType.format_type),
    );

  const availableLanguages = formats
    .map((_) => _.languages)
    .filter(Boolean)
    .flat()
    .filter(
      (value, index, self) =>
        value && index === self.findIndex((t) => t && t.language === value.language && t.name === value.name),
    ) as Language[];

  const [selectedLanguageFilters, setSelectedLanguageFilters] = useState<Language['name'][]>([]);
  const languagesIntersection = (format: Format | FormatDraft) =>
    (format.languages ?? []).filter((language) => selectedLanguageFilters.includes(language.name));

  const availableTopics = formats
    .map((_) => (_.topics ?? []).map((_) => _.topic))
    .flat()
    .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id && t.name === value.name));
  const [selectedTopicFilters, setSelectedTopicFilters] = useState<Topic['name'][]>([]);
  const topicsIntersection = (format: Format | FormatDraft) =>
    (format.topics ?? [])
      .map((_) => _.topic)
      .filter((topic) => availableTopics.filter((_) => selectedTopicFilters.includes(_.name)).includes(topic));

  const availableGenders = ['männlich', 'weiblich', 'divers'];
  const [selectedGenderFilters, setSelectedGenderFilters] = useState<typeof availableGenders>([]);
  const genderIntersection = (format: Format | FormatDraft) =>
    selectedGenderFilters.filter((gender) => {
      if (!format.target_audience) return DEFAULT_FALLBACK_FILTERING_MISSING_VALUES;

      const index = availableGenders.indexOf(gender);
      const formatGenders = [
        format.target_audience.gender_male_percentage,
        format.target_audience.gender_female_percentage,
        format.target_audience.gender_diverse_percentage,
      ];
      const sortedFormatGenders = [...formatGenders];
      sortedFormatGenders.sort((a, b) => b - a);
      return sortedFormatGenders[0] === formatGenders[index];
    });

  const availableAgeRanges = ['unter 18', '18-24', '25-34', '35-44', '45+'];
  const [selectedAgeRangeFilters, setSelectedAgeRangeFilters] = useState<typeof availableAgeRanges>([]);
  const ageRangeIntersection = (format: Format | FormatDraft) =>
    selectedAgeRangeFilters.filter((ageRange) => {
      if (!format.target_audience) return DEFAULT_FALLBACK_FILTERING_MISSING_VALUES;

      const index = availableAgeRanges.indexOf(ageRange);
      const formatAgeRanges = [...format.target_audience.age_ranges];
      formatAgeRanges.sort((a, b) => b.percentage - a.percentage);
      return formatAgeRanges[0] === format.target_audience.age_ranges[index];
    });

  const [startDateFilter, setStartDateFilter] = React.useState<Dayjs | null>(null);
  const [endDateFilter, setEndDateFilter] = React.useState<Dayjs | null>(null);

  const [showExpired, setShowExpired] = useState(false);
  const dateIsExpired = (format: Format | FormatDraft) => {
    const lastDate = getLastDate(format);

    if (!lastDate) {
      return !DEFAULT_FALLBACK_FILTERING_MISSING_VALUES;
    }
    // today is at least 24 hours after lastDate
    return dateIsLaterOrEqual(lastDate, dayjs());
  };

  const filteredFormats = () => {
    // Show all

    if (!filteringEnabled) {
      return getSortedFormats();
    }

    // Show drafts only
    if (filterFormatDrafts) {
      let data = getSortedFormats().filter((format) => format.is_draft);
      if (selectedHostFilters.length > 0) {
        data = data.filter((format) => {
          const hostName = format.host?.name || '';
          return selectedHostFilters.includes(hostName);
        });
      }

      return data;
    }

    return getSortedFormats().filter((format) => {
      // Filter out drafts
      if (!filterFormatDrafts) {
        if (format.is_draft) return false;
      }

      // Show published only
      if (selectedPlatformFilters.length > 0 && platformIntersection(format).length === 0) {
        // if no values were given, only filter out if default fallback for missing values is REMOVE/false
        if ((format.platforms ?? []).length > 0 || !DEFAULT_FALLBACK_FILTERING_MISSING_VALUES) return false;
      }

      // Show only host selected
      if (selectedHostFilters.length > 0) {
        const hostName = format.host?.name || '';
        if (!selectedHostFilters.includes(hostName)) return false;
      }

      if (selectedFormatTypeFilters.length > 0 && formatTypeIntersection(format).length === 0) {
        if ((format.types ?? []).length > 0 || !DEFAULT_FALLBACK_FILTERING_MISSING_VALUES) return false;
      }
      if (selectedLanguageFilters.length > 0 && languagesIntersection(format).length === 0) {
        if ((format.languages ?? []).length > 0 || !DEFAULT_FALLBACK_FILTERING_MISSING_VALUES) return false;
      }
      if (selectedTopicFilters.length > 0 && topicsIntersection(format).length === 0) {
        if ((format.topics ?? []).length > 0 || !DEFAULT_FALLBACK_FILTERING_MISSING_VALUES) return false;
      }
      if (selectedGenderFilters.length > 0 && genderIntersection(format).length === 0) return false;
      if (selectedAgeRangeFilters.length > 0 && ageRangeIntersection(format).length === 0) return false;
      if (startDateFilter !== null) {
        const earliestDate = getEarliestDate(format);
        if (!earliestDate) {
          // if filter out
          if (!DEFAULT_FALLBACK_FILTERING_MISSING_VALUES) return false;
        } else {
          if (!dateIsEarlierOrEqual(earliestDate, startDateFilter)) return false;
        }
      }

      if (endDateFilter !== null) {
        const lastDate = getLastDate(format);
        if (!lastDate) {
          // if filter out
          if (!DEFAULT_FALLBACK_FILTERING_MISSING_VALUES) return false;
        } else {
          if (!dateIsLaterOrEqual(lastDate, endDateFilter)) return false;
        }
      }

      if (!showExpired && dateIsExpired(format)) return false;

      return true;
    });
  };
  return (
    <Page user={user} title={title} activePage={PAGE.Formats}>
      <BreadcrumbsNavigation currentPage={title} />
      <Container maxWidth="xl" disableGutters>
        <Typography variant="h1" dangerouslySetInnerHTML={{ __html: title }} />

        {(user.role === ROLE_CREATOR_MANAGEMENT || user.role === ROLE_ADMIN) && (
          <Link href={'/formats/create'} style={{ textDecoration: 'none' }}>
            <Button variant="outlined" color="secondary" sx={{ marginBottom: '30px', marginTop: 0 }}>
              Showformat anlegen
            </Button>
          </Link>
        )}

        <Grid spacing={{ sx: 0, md: '20px' }} xs={12} container>
          <Grid xs={12} md={9} lg={9} item sx={{ display: 'flex', flexDirection: 'column', order: { xs: 1, sm: 0 } }}>
            <SortBar
              isAuthenticated={!!user}
              nameSorting={nameSorting}
              toggleNameSorting={toggleNameSorting}
              dateSorting={dateSorting}
              filterFormatDrafts={filterFormatDrafts}
              setFilterFormatDrafts={setFilterFormatDrafts}
              toggleDateSorting={toggleDateSorting}
            />
            <Grid spacing={2.5} container>
              {filteredFormats().map((format) => {
                return (
                  <Grid
                    item
                    key={format.id}
                    xs={12}
                    md={6}
                    lg={4}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}
                  >
                    <FormatCard format={format} readableFormatTypes={formatTypes} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid xs={12} md={3} lg={3} item sx={{ display: 'flex', flexDirection: 'column', order: { xs: 0, sm: 1 } }}>
            <Paper
              sx={{
                p: '1.75rem',
                borderRadius: '22px',
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
                overflow: 'hidden',
              }}
            >
              <MultiSelectFilter
                allFilterOptions={contentPlatforms.map((_) => _.readable)}
                selectedFilterOptions={selectedPlatformFilters}
                handleFilterSelect={(event: SelectChangeEvent<string[]>) => {
                  setSelectedPlatformFilters(event.target.value as typeof selectedPlatformFilters);
                }}
                title="Plattform"
                placeholder="Plattformen wählen"
              />
              <MultiSelectFilter
                allFilterOptions={hosts as string[]}
                selectedFilterOptions={selectedHostFilters}
                handleFilterSelect={(event: SelectChangeEvent<string[]>) => {
                  setSelectedHostFilters(event.target.value as any);
                }}
                title="Host"
                placeholder="Host wählen"
              />
              <MultiSelectFilter
                allFilterOptions={formatTypes.map((_) => _.readable)}
                selectedFilterOptions={selectedFormatTypeFilters}
                handleFilterSelect={(event: SelectChangeEvent<string[]>) => {
                  setSelectedFormatTypeFilters(event.target.value as typeof selectedFormatTypeFilters);
                }}
                title="Kategorie"
                placeholder="Kategorien wählen"
              />
              <MultiSelectFilter
                allFilterOptions={availableLanguages.map((_) => _.name)}
                selectedFilterOptions={selectedLanguageFilters}
                handleFilterSelect={(event: SelectChangeEvent<string[]>) => {
                  setSelectedLanguageFilters(event.target.value as typeof selectedLanguageFilters);
                }}
                title="Sprache"
                placeholder="Sprachen wählen"
              />
              <MultiSelectFilter
                allFilterOptions={availableTopics.map((_) => _.name)}
                selectedFilterOptions={selectedTopicFilters}
                handleFilterSelect={(event: SelectChangeEvent<string[]>) => {
                  setSelectedTopicFilters(event.target.value as typeof selectedTopicFilters);
                }}
                title="Thema"
                placeholder="Themen wählen"
              />
              <MultiSelectFilter
                allFilterOptions={availableGenders}
                selectedFilterOptions={selectedGenderFilters}
                handleFilterSelect={(event: SelectChangeEvent<string[]>) => {
                  setSelectedGenderFilters(event.target.value as typeof selectedGenderFilters);
                }}
                title="Überwiegendes Geschlecht"
                placeholder="Wählen"
              />
              <MultiSelectFilter
                allFilterOptions={availableAgeRanges}
                selectedFilterOptions={selectedAgeRangeFilters}
                handleFilterSelect={(event: SelectChangeEvent<string[]>) => {
                  setSelectedAgeRangeFilters(event.target.value as typeof selectedAgeRangeFilters);
                }}
                title="Überwiegende Altersgruppe"
                placeholder="Wählen"
              />
              <Box>
                <Typography color="primary" fontSize="0.8125rem" textTransform="uppercase" sx={{ mb: '6px' }}>
                  Frühestes Startdatum
                </Typography>
                <FilterDatePicker value={startDateFilter} setValue={setStartDateFilter} />
              </Box>
              <Box>
                <Typography color="primary" fontSize="0.8125rem" textTransform="uppercase" sx={{ mb: '6px' }}>
                  Spätestes Enddatum
                </Typography>
                <FilterDatePicker value={endDateFilter} setValue={setEndDateFilter} />
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(evt) => {
                      setShowExpired(evt.target.checked);
                    }}
                  />
                }
                label="Abgelaufene Formate anzeigen"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(evt) => {
                      setFilteringEnabled(!evt.target.checked);
                    }}
                  />
                }
                checked={!filteringEnabled}
                label="Filter deaktivieren"
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
