import { Grid, Paper, Typography } from '@mui/material';
import GuestInformation from '@/ts/Components/FormatDetail/GuestInformation';
import React from 'react';
import styled from '@emotion/styled';
import { ContentCreator } from '@/ts/Types/ContentCreator';
import { ContentCreatorData } from '@/ts/Types/ContentCreatorData';

export default function Guests({
  contentCreators,
  contentCreatorData,
}: {
  contentCreators: ContentCreator[];
  contentCreatorData: ContentCreatorData[];
}) {
  return (
    <EpisodeGuestsPaper sx={{ gap: '1.75rem' }}>
      <Typography variant="h2">Gäste der Show</Typography>
      <Grid container columns={9} spacing={2}>
        {contentCreators.map((contentCreator) => {
          return (
            <Grid key={contentCreator.name} item xs={9} sm={4.5} lg={3}>
              <GuestInformation contentCreator={contentCreator} contentCreatorData={contentCreatorData} />
            </Grid>
          );
        })}
      </Grid>
    </EpisodeGuestsPaper>
  );
}

const EpisodeGuestsPaper = styled(Paper)(() => ({
  padding: '1.75rem',
  borderRadius: '22px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
