import { User } from "@/ts/Types/User";
import axios from "axios";
import { UserFormState } from "./types";

const updateUser = (inputData: UserFormState) =>  {
    const form = new FormData();
    form.append('name', inputData.name);
    form.append('email', inputData.email);
    if (inputData.password.length !== 0) form.append('password', inputData.password);
    form.append('role', inputData.role as string);
    form.append('_method', 'put');
    return axios.post('/api/users/' + inputData.id, form)
  }
  
const createUser = (inputData: UserFormState) =>  {
    const form = new FormData();
    form.append('name', inputData.name);
    form.append('email', inputData.email);
    form.append('password', inputData.password);
    form.append('role', inputData.role as string);
    axios.post('/api/users', form).then(() => window.location.reload());
  }
  
const deleteUser = (user: User) => {
    axios.delete('/api/users/' + user.id).then(() => window.location.reload());
  }
  
const approveUser = (user: User) => {
    return axios.put(`/api/users/${user.id}/approve`)
      .catch(error => {
        console.error('Error approving user:', error);
      });
  }

const rejectUser = (user: User) => {
    return axios.delete(`/api/users/${user.id}/reject`)
    .then(() => window.location.reload())
      .catch(error => {
        console.error('Error rejecting user:', error);
      });
  }


const approveRequests = async (user: User, inputData: UserFormState) => {
    try {
        const [approveResponse, updateResponse] = await Promise.all([
            approveUser(user),
            updateUser(inputData)
        ]);
        window.location.reload()
    } catch (error) {
        console.error('Error occurred:', error);
    }
}


export { updateUser, createUser, deleteUser, approveUser, approveRequests, rejectUser}