import { Box, Typography } from '@mui/material';
import React from 'react';
import { theme } from '@/ts/theme';

export default function MetadataChip({ information }: { information: string }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box
        sx={{
          paddingY: 1,
          paddingX: 1.5,
          borderRadius: '12px',
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      >
        <Typography
          sx={{ textTransform: 'uppercase', color: theme.palette.primary.main, fontSize: '1rem', whiteSpace: 'nowrap' }}
        >
          {information}
        </Typography>
      </Box>
    </Box>
  );
}
