import { Box, Typography } from '@mui/material';
import { theme } from '@/ts/theme';
import GenderDistributionPieChart from '@/ts/Components/FormatDetail/GenderDistributionPieChart';
import React from 'react';
import { FormatTargetAudience } from '@/ts/Types/Format';

export default function GenderDistribution({ targetAudience }: { targetAudience: FormatTargetAudience }) {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 0, gap: 1.25 }}>
        <Typography variant="h2" sx={{ marginBottom: 3 }}>
          Gender
        </Typography>
        <Legend name="Männlich" percentage={targetAudience.gender_male_percentage} color={theme.palette.info.main} />
        <Legend name="Weiblich" percentage={targetAudience.gender_female_percentage} color={theme.palette.error.main} />
        <Legend
          name="Divers"
          percentage={targetAudience.gender_diverse_percentage}
          color={theme.palette.warning.main}
        />
      </Box>
      <Box sx={{ maxHeight: '200px' }}>
        <GenderDistributionPieChart
          male={targetAudience.gender_male_percentage}
          female={targetAudience.gender_female_percentage}
          diverse={targetAudience.gender_diverse_percentage}
        />
      </Box>
    </>
  );
}

type LegendProps = {
  name: string;
  percentage: number;
  color: string;
};

function Legend({ name, percentage, color }: LegendProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box
        sx={{
          width: '1rem',
          height: '1rem',
          backgroundColor: color,
          borderRadius: '6px',
        }}
      />
      <Typography sx={{ color: color, textTransform: 'uppercase' }}>{name}</Typography>
      <Typography>{percentage}%</Typography>
    </Box>
  );
}
