import { Breadcrumbs, Typography } from '@mui/material';
import React from 'react';
import { Link } from '@inertiajs/react';
import { theme } from '@/ts/theme';

type Breadcrumb = {
  name: string;
  url: string;
};

type BreadcrumbsProps = {
  previousPages?: Breadcrumb[];
  currentPage: string;
};

export default function BreadcrumbsNavigation({ previousPages = [], currentPage }: BreadcrumbsProps) {
  return (
    <Breadcrumbs
      separator={
        <Typography color="secondary" fontSize="small" fontWeight={300}>
          •
        </Typography>
      }
      aria-label="breadcrumb"
      color="secondary"
      sx={{
        maxWidth: '103rem',
        marginInline: 'auto',
      }}
    >
      {previousPages.map((breadcrumb, index) => {
        return (
          <Link key={index} href={breadcrumb.url} style={{ textDecoration: 'none' }}>
            <Typography
              fontSize="small"
              color={theme.palette.secondary.main}
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {breadcrumb.name}
            </Typography>
          </Link>
        );
      })}
      <Typography color="secondary" fontSize="small">
        {currentPage}
      </Typography>
    </Breadcrumbs>
  );
}
