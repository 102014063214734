import React from 'react';
import Page from '../Components/Page';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { PAGE } from '@/ts/Components/Header';
import { usePage } from '@inertiajs/react';
import { User } from '@/ts/Types/User';

export default function MainPage() {
  const { user } = usePage<{
    user: User;
  }>().props;

  return (
    <Page user={user} title={'MAIN'} activePage={PAGE.About}>
      <Container maxWidth="xl">
        <Box
          sx={{
            width: '100%',
            aspectRatio: 16 / 9,
            backgroundColor: 'primary.dark',
          }}
        ></Box>
      </Container>
    </Page>
  );
}
