export default function AddFile({ size, color }: { size: number; color: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.2012 27.9934H28.0021M28.0021 27.9934H14.8027M28.0021 27.9934V14.7941M28.0021 27.9934V41.1927"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </svg>
  );
}
