import { FormFieldLabelBox, FormSectionHeadline, FormSectionPaper } from '@/ts/Pages/FormatFormPage';
import { FormState } from '@/ts/Types/Format';
import { Box, TextField, Typography } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useErrors } from './ErrorsContext';
import React, { useEffect } from 'react';

function formatNumber(value: number | string, currentUnit: 'M' | 'K', roundedDecimal?: number): number {
  let valueFormmated = value;
  switch (currentUnit) {
    case 'M':
      valueFormmated = Number(value) / 1000000;
      break;
    case 'K':
      valueFormmated = Number(value) / 1000;
      break;
    default:
      valueFormmated = Number(value);
      break;
  }
  return roundedDecimal ? Math.round(valueFormmated * 10 ** roundedDecimal) / 10 ** roundedDecimal : valueFormmated;
}

export default function EstimatedReachForm({
  formState,
  setEstimatedUniqueViews,
  setEstimatedSocialMediaViews,
  setEstimatedTotalViews,
}: {
  formState: FormState;
  setEstimatedUniqueViews: (estimatedViews: number) => void;
  setEstimatedSocialMediaViews: (estimatedViews: number) => void;
  setEstimatedTotalViews: (estimatedViews: number) => void;
}) {
  const { getError } = useErrors();
  const euvError = getError('estimatedUniqueViews') || getError('expected_unique_views');
  const esmvError = getError('estimatedSocialMediaViews') || getError('expected_social_media_views');
  const etvError = getError('estimatedTotalViews') || getError('expected_total_views');
  const anyHasError = !!euvError || !!esmvError || !!etvError;

  return (
    <>
      <FormSectionHeadline hasError={anyHasError} data-has-error={anyHasError}>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Reichweitenprognose' }} />
      </FormSectionHeadline>
      <FormSectionPaper hasError={anyHasError}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <FormFieldLabelBox>
            <FormattedInputs
              label="UNIQUE VIEWS"
              initValue={formState.estimatedUniqueViews ?? ''}
              callbackChangeValue={(value: number | string) => {
                setEstimatedUniqueViews(Number(value));
              }}
            />
          </FormFieldLabelBox>

          <FormFieldLabelBox>
            <FormattedInputs
              label="SOCIAL MEDIA VIEWS"
              initValue={formState.estimatedSocialMediaViews ?? ''}
              callbackChangeValue={(value: number | string) => {
                setEstimatedSocialMediaViews(Number(value));
              }}
            />
          </FormFieldLabelBox>

          <FormFieldLabelBox>
            <FormattedInputs
              label="GESAMTVIEWS"
              initValue={formState.estimatedTotalViews ?? ''}
              callbackChangeValue={(value: number | string) => {
                setEstimatedTotalViews(Number(value));
              }}
            />
          </FormFieldLabelBox>
        </Box>
      </FormSectionPaper>
    </>
  );
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix=""
    />
  );
});

type Props = {
  label: string;
  initValue: string | number;
  callbackChangeValue: (value: number | string) => void;
};

const closestUnit = (value: number | string) => {
  if (Number(value) < 1000000) {
    return 'K';
  } else {
    return 'M';
  }
};

const FormattedInputs: React.FC<Props> = ({ label, initValue, callbackChangeValue }) => {
  const [value, setValue] = React.useState(initValue);
  const [labelWithUnit, setLabelWithUnit] = React.useState('');

  useEffect(() => {
    const unit = closestUnit(value);
    setLabelWithUnit(`${label} (${formatNumber(value, unit, unit === 'K' ? 2 : 1)} ${unit})`);
  }, [initValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    callbackChangeValue(event.target.value);
    setValue(event.target.value);
  };

  return (
    <>
      <Typography variant="subtitle2" fontSize="0.8125rem">
        {labelWithUnit}
      </Typography>
      <TextField
        hiddenLabel
        label=""
        value={value}
        onChange={handleChange}
        InputProps={{
          inputComponent: NumericFormatCustom as any,
        }}
        variant="outlined"
        placeholder="0"
        required
      />
    </>
  );
};
