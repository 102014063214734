import { AdvertisementType } from '@/ts/Types/AdvertisementType';
import { ContentCreator, FormContentCreator } from '@/ts/Types/ContentCreator';
import { Image } from '@/ts/Types/Image';
import { ContentPlatform, ContentPlatformName } from '@/ts/Types/Platform';
import { ReadableFormatType } from '@/ts/Types/Readable';
import { Topic } from '@/ts/Types/Topic';
import dayjs, { Dayjs } from 'dayjs';

export type Format = {
  id: string;
  name: string;
  description: string;
  short_description: string;
  languages: Language[];
  platforms: ContentPlatform[];
  minimum_advertising_cost: number;
  advertisement_types: { advertisement_type: AdvertisementType }[];
  images: FormatImage[];
  dates: FormatDate[];
  expected_unique_views: number;
  expected_social_media_views: number;
  expected_total_views: number;
  topics: { topic: Topic }[];
  types: FormatType[];
  host: ContentCreator;
  target_audience: FormatTargetAudience;
  is_draft: boolean;
};

export type FormatDraft = Pick<Format, 'id' | 'name' | 'is_draft'> & Partial<Omit<Format, 'id' | 'name'>>;

export type FormState = {
  id: string | null;
  name: string;
  description: string;
  shortDescription: string;
  images: (File & { preview: string })[];
  oldImages: OldImage[];
  primaryFormatType: string | null;
  secondaryFormatTypes: string[];
  platforms: ContentPlatformName[];
  language: string | null;
  topics: string[];
  host: FormContentCreator | null;
  dates: FormDate[];
  estimatedUniqueViews: number | null;
  estimatedSocialMediaViews: number | null;
  estimatedTotalViews: number | null;
  targetAudienceMalePercentage: number | null;
  targetAudienceFemalePercentage: number | null;
  targetAudienceDiversePercentage: number | null;
  targetAudienceCountries: FormTargetAudienceCountry[];
  targetAudienceTo18Percentage: number | null;
  targetAudienceTo24Percentage: number | null;
  targetAudienceTo34Percentage: number | null;
  targetAudienceTo44Percentage: number | null;
  targetAudienceOver44Percentage: number | null;
  minimumAdvertisingCost: number | null;
  advertisementTypes: string[];
  isDraft: boolean;
};

export type FormDate = {
  startingDate: number | null; // Unix Timestamp (milliseconds)
  startingTime: number | null; // Unix Timestamp (milliseconds)
  duration: string | null;
  guests: (FormContentCreator | null)[];
};

export type FormTargetAudienceCountry = {
  country: string;
  percentage: number | null;
};

export type OldImage = {
  url: string;
  deleted: boolean;
  newIndex: number | null;
};

export type Language = {
  language: string;
  name: string;
};

export type FormatImage = Image & {
  index: number;
};

export type FormatDate = {
  start_time: string;
  end_time: string;
  guests: { content_creator: ContentCreator }[];
};

export type FormatType = {
  format_type: string;
  is_primary: boolean;
};

export type FormatTargetAudience = {
  gender_diverse_percentage: number;
  gender_female_percentage: number;
  gender_male_percentage: number;
  countries: FormatTargetAudienceCountry[];
  age_ranges: FormatTargetAudienceAgeRange[];
};

export type FormatTargetAudienceCountry = {
  country: string;
  name: string;
  percentage: number;
};

export type FormatTargetAudienceAgeRange = {
  start: number;
  end: number;
  percentage: number;
};

export function getImageSourceSet(image: FormatImage[]): string | undefined {
  if (image.length === 0) return undefined;
  return image.reduce((sourceSetString, image) => {
    const widthSource = image.url + ' ' + image.width + 'w';
    if (sourceSetString === '') return widthSource;
    return sourceSetString + ', ' + widthSource;
  }, '');
}

export function getLargestImage(image: FormatImage[]): FormatImage | undefined {
  if (image.length === 0) return undefined;
  const imageSet = [...image];
  imageSet.sort((a, b) => {
    return b.width - a.width;
  });
  return imageSet[0];
}

export function sortedDates(format: Format | FormatDraft) {
  return [...(format.dates ?? [])]
    .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime())
    .map((date): FormatDate => {
      return {
        start_time: new Date(
          new Date(date.start_time).getTime() - new Date(date.start_time).getTimezoneOffset() * 60 * 1000,
        ).toISOString(),
        end_time: new Date(
          new Date(date.end_time).getTime() - new Date(date.end_time).getTimezoneOffset() * 60 * 1000,
        ).toISOString(),
        guests: date.guests,
      };
    });
}

export function getEarliestDate(format: Format | FormatDraft): Dayjs | undefined {
  const sorted = sortedDates(format);
  if (sorted.length === 0) return undefined;
  return dayjs(sorted[0].start_time);
}

export function getLastDate(format: Format | FormatDraft): Dayjs | undefined {
  const sorted = sortedDates(format);
  if (sorted.length === 0) return undefined;
  return dayjs(sorted.reverse()[0].end_time);
}

/**
 * b is at least 24 hours later than a
 */
export function dateIsLaterOrEqual(a: Dayjs, b: Dayjs) {
  return b.diff(a, 'day', true) > 1;
}

/**
 * b is earlier than a or equal
 */
export function dateIsEarlierOrEqual(a: Dayjs, b: Dayjs) {
  return a.diff(b, 'day', true) >= 0;
}

interface Event {
  start_time: string;
  end_time: string;
  guests: any[];
}

function getNextDate(events: FormatDate[] | undefined): FormatDate | undefined {
  if (!events || events.length === 0) {
    return undefined; // No events
  }

  const today = new Date();

  // Parse the start_time to Date objects and filter future dates
  const futureEvents = events
    .map((event) => ({
      ...event,
      start_time: new Date(event.start_time),
    }))
    .filter((event) => event.start_time > today);

  if (futureEvents.length === 0) {
    return undefined; // No future events
  }

  // Find the event with the closest start_time
  futureEvents.sort((a, b) => a.start_time.getTime() - b.start_time.getTime());

  return futureEvents.map((event) => ({
    ...event,
    // start_time: event.start_time, //.toISOString(),
    start_time: new Date(
      new Date(event.start_time).getTime() - new Date(event.start_time).getTimezoneOffset() * 60 * 1000,
    ).toISOString(),
    end_time: new Date(
      new Date(event.end_time).getTime() - new Date(event.end_time).getTimezoneOffset() * 60 * 1000,
    ).toISOString(),
  }))[0];
}

export function nextDate(format: Format | FormatDraft): FormatDate | undefined {
  // const sorted = sortedDates(format);

  const nextDate = getNextDate(format.dates);
  // return getNextDate(format.dates);

  return nextDate;

  // return sorted.find(
  //   (date) =>
  //     new Date(date.start_time).getTime() + new Date(date.start_time).getTimezoneOffset() * 60 * 1000 > Date.now(),
  // ) ?? sorted.length > 0
  //   ? sorted[sorted.length - 1]
  //   : undefined;
}

export function readablePrimaryType(format: Format | FormatDraft, readableFormatTypes: ReadableFormatType[]): string {
  if (!format.types) return '';
  if (format.types.length === 0) return '';

  const primaryType = format.types.find((type) => type.is_primary);
  if (!primaryType) return '';

  return readableFormatTypes.find((readable) => readable.name === primaryType.format_type)?.readable ?? '';
}

export function imagesByIndex(formatImages: FormatImage[]) {
  return formatImages.reduce((accumulator, currentValue) => {
    accumulator[currentValue.index] = accumulator[currentValue.index] || [];
    accumulator[currentValue.index].push(currentValue);
    return accumulator;
  }, [] as FormatImage[][]);
}
