export default function Hide({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28">
      <line x1="4" y1="4" x2="24" y2="24" stroke="#E7FD70" strokeWidth="2.4" strokeLinecap="square" />
      <line x1="24" y1="4" x2="4" y2="24" stroke="#E7FD70" strokeWidth="2.4" strokeLinecap="square" />
    </svg>
  );
}

// const oldCloseArrows = () => (
//   <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path
//       d="M4.5 21H15M15 21V31.5M15 21L9.95911 25.9364L4.91821 30.8728L4.76068 31.0271"
//       stroke="#E7FD70"
//       strokeOpacity="0.8"
//       strokeWidth="1.6"
//       strokeLinecap="square"
//       fill="transparent"
//     />
//     <path
//       d="M31.5 15H21M21 15V4.5M21 15L26.0409 10.0636L31.0818 5.1272L31.2393 4.97294"
//       stroke="#E7FD70"
//       strokeOpacity="0.8"
//       strokeWidth="1.6"
//       strokeLinecap="square"
//       fill="transparent"
//     />
//   </svg>
// );
