import { Box, Chip, Grid, Typography } from '@mui/material';
import { theme } from '@/ts/theme';
import React from 'react';
import Divider from '@mui/material/Divider';
import OpenLink from '@/ts/Components/icons/OpenLink';
import { ContentCreator, ContentCreatorPlatform, getPrimaryPlatform } from '@/ts/Types/ContentCreator';
import { PlatformIcon } from '@/ts/Components/icons/Platform';
import { ContentCreatorData } from '@/ts/Types/ContentCreatorData';
import { getPlatformUrl } from '@/ts/Types/Platform';

export default function ContentCreatorFullInformation({
  contentCreator,
  additionalPlatformData,
}: {
  contentCreator: ContentCreator;
  additionalPlatformData: ContentCreatorData[];
}) {
  return (
    <Box
      sx={{
        borderRadius: '16px',
        border: '1px solid rgba(255, 255, 255, 0.5)',
        padding: '20px',
        display: 'flex',
        gap: '20px',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >
      <Box sx={{ height: 256, overflow: 'hidden', borderRadius: '16px', aspectRatio: 1, flexShrink: 0 }}>
        <img
          src={
            additionalPlatformData.find((data) => data.platform === contentCreator.main_platform)?.imageUrl ??
            'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
          }
          height="100%"
          width="auto"
          alt={'Host der Show:' + contentCreator.name}
          style={{ objectFit: 'cover' }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '28px', flex: '1' }}>
        <Box sx={{ marginTop: '20px' }}>
          <Typography sx={{ fontSize: '1.125rem', color: theme.palette.primary.main }}>
            {contentCreator.name}
          </Typography>
          <Typography variant="body1">@{getPrimaryPlatform(contentCreator).social_handle}</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '20px 40px', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="subtitle2">Hauptplattform</Typography>
            <PlatformIcon name={getPrimaryPlatform(contentCreator).name} size={24} color={theme.palette.common.white} />
          </Box>
          {contentCreator.platforms.filter((platform) => platform.name != contentCreator.main_platform).length > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="subtitle2">Weitere Plattformen</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                {contentCreator.platforms
                  .filter((platform) => platform.name != contentCreator.main_platform)
                  .map((platform) => {
                    return (
                      <PlatformIcon
                        key={platform.name}
                        name={platform.name}
                        size={24}
                        color={theme.palette.common.white}
                      />
                    );
                  })}
              </Box>
            </Box>
          )}
        </Box>
        <Divider sx={{ borderTop: '1px solid rgba(255, 255, 255, 0.32)' }} />
        <Grid container spacing="16px">
          {contentCreator.platforms.map((platform) => {
            return (
              <Grid key={platform.name} item sm={6}>
                <PlatformLink platform={platform} additionalPlatformData={additionalPlatformData} />
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="subtitle2">Themen</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {contentCreator.topics.map((topic) => {
              return <Chip key={topic.topic.name} label={'#' + topic.topic.name} variant="outlined" />;
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function PlatformLink({
  platform,
  additionalPlatformData,
}: {
  platform: ContentCreatorPlatform;
  additionalPlatformData: ContentCreatorData[];
}) {
  return (
    <Box
      sx={{
        border: '1px solid rgba(255, 255, 255, 0.32)',
        borderRadius: '12px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', flex: 1 }}>
          <PlatformIcon name={platform.name} size={32} />
          <Typography variant="subtitle2">{platform.name}</Typography>
        </Box>
        <a href={getPlatformUrl(platform.name, platform.social_handle)} target="_blank" rel="noreferrer">
          <OpenLink size={20} />
        </a>
      </Box>
      <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
          <Typography
            sx={{
              fontSize: '0.8125rem',
              lineHeight: 1,
              color: theme.palette.primary.main,
              textTransform: 'uppercase',
            }}
          >
            Name
          </Typography>
          <Typography variant="body2">@{platform.social_handle}</Typography>
        </Box>
        {additionalPlatformData.find((data) => data.platform === platform.name)?.followers && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
            <Typography
              sx={{
                fontSize: '0.8125rem',
                lineHeight: 1,
                color: theme.palette.primary.main,
                textTransform: 'uppercase',
              }}
            >
              Follower
            </Typography>
            <Typography variant="body2">
              {new Intl.NumberFormat('de').format(
                additionalPlatformData.find((data) => data.platform === platform.name)!.followers,
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
