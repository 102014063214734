import Page from '@/ts/Components/Page';
import { PAGE } from '@/ts/Components/Header';
import BreadcrumbsNavigation from '@/ts/Components/BreadcrumbsNavigation';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { theme } from '@/ts/theme';
import React from 'react';
import { usePage } from '@inertiajs/react';
import Delete from '@/ts/Components/icons/Delete';
import axios from 'axios';
import { ContentCreator } from '@/ts/Types/ContentCreator';
import { PlatformIcon } from '@/ts/Components/icons/Platform';
import { User } from '@/ts/Types/User';
import ConfirmDialog from '../Components/Form/ConfirmDialog';

const title = 'Content Creator-Administration';

export default function ContentCreatorManagementPage() {
  const { contentCreators, user } = usePage<{
    contentCreators: ContentCreator[];
    user: User;
  }>().props;
  return (
    <Page user={user} title={title} activePage={PAGE.Administration}>
      <BreadcrumbsNavigation previousPages={[{ name: 'Administration', url: '/administration' }]} currentPage={title} />
      <Container maxWidth="xl" disableGutters>
        <Typography variant="h1" dangerouslySetInnerHTML={{ __html: title }} />
        <Grid spacing={'20px'} container>
          {contentCreators.map((contentCreator) => {
            return <ContentCreatorElement contentCreator={contentCreator} key={contentCreator.id} />;
          })}
        </Grid>
      </Container>
    </Page>
  );
}

function ContentCreatorElement({ contentCreator }: { contentCreator: ContentCreator }) {
  return (
    <>
      <Grid item xs={12} sm={6} lg={4}>
        <Paper
          sx={{
            width: 1,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.grey['500'],
            borderRadius: '22px',
            paddingY: '22px',
            paddingX: '22px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h3">{contentCreator.name}</Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {contentCreator.platforms.map((platform) => (
                <PlatformIcon name={platform.name} size={24} key={platform.name} />
              ))}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <ConfirmDialog
              button={
                <Button
                  onClick={() => deleteContentCreator(contentCreator)}
                  sx={{
                    ':hover': {
                      background: 'transparent',
                    },
                  }}
                >
                  <Delete size={44} color={theme.palette.error.main} />
                </Button>
              }
              dialogTitle="Content Creator löschen"
            />
          </Box>
        </Paper>
      </Grid>
    </>
  );
}

function deleteContentCreator(contentCreator: ContentCreator) {
  axios
    .delete('/api/content-creators/' + contentCreator.id)
    .then(() => window.location.reload())
    .catch(() =>
      window.alert('Der Content Creator kann nicht gelöscht werden, weil er in Showformaten referenziert wird!'),
    );
}
