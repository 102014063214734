import SortingNone from '@/ts/Components/icons/Sorting/SortingNone';
import SortingAlphabeticalAscending from '@/ts/Components/icons/Sorting/SortingAlphabeticalAscending';
import SortingAlphabeticalDescending from '@/ts/Components/icons/Sorting/SortingAlphabeticalDescending';
import React from 'react';
import SortingGeneralAscending from '@/ts/Components/icons/Sorting/SortingGeneralAscending';
import SortingGeneralDescending from '@/ts/Components/icons/Sorting/SortingGeneralDescending';

export enum Sorting {
  None,
  Ascending,
  Descending,
}

export function AlphabeticalSortingIcon({ sorting }: { sorting: Sorting }) {
  switch (sorting) {
    case Sorting.None:
      return <SortingNone />;
    case Sorting.Ascending:
      return <SortingAlphabeticalAscending />;
    case Sorting.Descending:
      return <SortingAlphabeticalDescending />;
  }
}

export function GeneralSortingIcon({ sorting }: { sorting: Sorting }) {
  switch (sorting) {
    case Sorting.None:
      return <SortingNone />;
    case Sorting.Ascending:
      return <SortingGeneralAscending />;
    case Sorting.Descending:
      return <SortingGeneralDescending />;
  }
}
