import { BarElement, CategoryScale, Chart as ChartJS, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { theme } from '@/ts/theme';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormatTargetAudienceCountry } from '@/ts/Types/Format';

export default function CountryDistributionBarChart({
  countryDistribution,
}: {
  countryDistribution: FormatTargetAudienceCountry[];
}) {
  ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);
  return (
    <Bar
      options={{
        indexAxis: 'y' as const,
        scales: {
          x: {
            beginAtZero: true,
            suggestedMax: 100,
            grid: {
              color: 'rgba(255, 255, 255, 0.12)',
            },
            ticks: {
              stepSize: 25,
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              color: theme.palette.common.white,
              font: {
                family: 'Roboto',
                size: 16,
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 50,
          },
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function (value) {
              return value + '%';
            },

            color: theme.palette.common.white,
            font: {
              family: 'Roboto',
              size: 16,
            },
          },
        },
      }}
      data={{
        labels: countryDistribution.map((country) => country.country.toUpperCase()),
        datasets: [
          {
            label: 'Zielgruppenanteil',
            data: countryDistribution.map((country) => country.percentage),
            backgroundColor: theme.palette.secondary.main,
            hoverBackgroundColor: theme.palette.secondary.main,
            borderWidth: 0,
            barThickness: 32,
          },
        ],
      }}
    />
  );
}
