import React, { useState } from 'react';
import Page from '../Components/Page';
import { Button, Container, Grid, SelectChangeEvent, Typography } from '@mui/material';
import BreadcrumbsNavigation from '../Components/BreadcrumbsNavigation';
import SortFilterBar from '../Components/AdvertisementTypes/SortFilterBar';
import AdvertisementTypeCard from '@/ts/Components/AdvertisementTypes/AdvertisementTypeCard';
import { Sorting } from '@/ts/Components/Sorting';
import { PAGE } from '@/ts/Components/Header';
import { AdvertisementType } from '@/ts/Types/AdvertisementType';
import { Link, usePage } from '@inertiajs/react';
import { ROLE_ADMIN, User } from '@/ts/Types/User';
import { ReadableFormatType, ReadablePlatform } from '@/ts/Types/Readable';

const title = 'Werbeformate';

export default function AdvertisementTypeListPage() {
  const { advertisementTypes, user, contentPlatforms } = usePage<{
    advertisementTypes: AdvertisementType[];
    user: User;
    contentPlatforms: ReadablePlatform[];
  }>().props;

  const [selectedPlatformFilters, setSelectedPlatformFilters] = useState(contentPlatforms.map((p) => p.name as string));
  const handlePlatformFilterSelect = (event: SelectChangeEvent<typeof selectedPlatformFilters>) => {
    if ((event.target.value as string[]).length > 0) setSelectedPlatformFilters(event.target.value as string[]);
  };
  const [nameSorting, setNameSorting] = useState(Sorting.Ascending);
  const toggleSorting = () => {
    if (nameSorting === Sorting.None) setNameSorting(Sorting.Ascending);
    if (nameSorting === Sorting.Ascending) setNameSorting(Sorting.Descending);
    if (nameSorting === Sorting.Descending) setNameSorting(Sorting.None);
  };

  const sortedAdvertisementTypes = () => {
    if (nameSorting === Sorting.Ascending) return [...advertisementTypes].sort((a, b) => a.name.localeCompare(b.name));
    else if (nameSorting === Sorting.Descending)
      return [...advertisementTypes].sort((a, b) => b.name.localeCompare(a.name));
    else return advertisementTypes;
  };

  return (
    <Page user={user} title={title} activePage={PAGE.AdvertisementTypes}>
      <BreadcrumbsNavigation currentPage={title} />

      <Container maxWidth="xl" disableGutters>
        <Typography variant="h1" dangerouslySetInnerHTML={{ __html: title }} />
        {user && user.role === ROLE_ADMIN && (
          <Link href={'/werbeformats/create'} style={{ textDecoration: 'none' }}>
            <Button variant="outlined" color="secondary" sx={{ marginBottom: '30px', marginTop: 0 }}>
              Werbeformat anlegen
            </Button>
          </Link>
        )}
        <SortFilterBar
          nameSorting={nameSorting}
          toggleSorting={toggleSorting}
          platformsFilters={contentPlatforms.map((p) => p.name as string)}
          currentPlatformFilters={selectedPlatformFilters}
          handlePlatformFilterSelect={handlePlatformFilterSelect}
        />
        <Grid spacing={2.5} container>
          {sortedAdvertisementTypes()
            .filter((advertisementType) => {
              const platformIntersection = advertisementType.platforms.filter((platform) =>
                selectedPlatformFilters.includes(platform.name),
              );
              return platformIntersection.length !== 0;
            })
            .map((advertisementType, index) => {
              return (
                <Grid item key={index} xs={12} md={6} lg={4} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <AdvertisementTypeCard advertisementType={advertisementType} />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </Page>
  );
}
