import { User } from '@/ts/Types/User';
import { theme } from '@/ts/theme';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { rejectUser } from './api';
import ApprovalModal from './Modals/Approval';

const PendingApprovalUser = ({ user }: { user: User }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            width: 1,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.grey['500'],
            borderRadius: '22px',
            paddingY: '22px',
            paddingX: '22px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h3">{user.name}</Typography>
            <Typography marginTop={1} variant="body1">
              {user.email}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              width: '100%',
              '@media (min-width: 768px)': {
                width: 'auto',
              },
            }}
          >
            <Box sx={{ cursor: 'pointer' }}>
              <Button sx={{ marginBottom: 0, marginRight: 2 }} variant="outlined" onClick={() => setDialogOpen(true)}>
                Genehmigen
              </Button>
              <Button sx={{ marginBottom: 0 }} variant="text" onClick={() => rejectUser(user)}>
                Ablehnen
              </Button>
            </Box>
          </Box>
        </Paper>
        <ApprovalModal open={dialogOpen} setOpen={setDialogOpen} user={user} />
      </Grid>
    </>
  );
};

export default PendingApprovalUser;
