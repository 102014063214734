import Guests from '@/ts/Components/FormatDetail/Guests';
import ExpandAccordion from '@/ts/Components/icons/ExpandAccordion';
import { ContentCreatorData } from '@/ts/Types/ContentCreatorData';
import { FormatDate } from '@/ts/Types/Format';
import { theme } from '@/ts/theme';
import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography } from '@mui/material';

type EpisodeProps = {
  episode: FormatDate;
  number: number;
  variant: 'past' | 'next' | 'future';
};

export default function Episode({
  episode,
  number,
  variant,
  contentCreatorData,
}: EpisodeProps & {
  contentCreatorData: ContentCreatorData[];
}) {
  if (!episode) return <></>;

  return (
    <Accordion
      disableGutters={true}
      defaultExpanded={variant === 'next'}
      sx={{ margin: 0, padding: 0, background: 'transparent', borderRadius: '22px' }}
    >
      {
        {
          past: (
            <PastEpisodeSummary expandIcon={!!episode.guests.length && <ExpandAccordion size={32} color="white" />}>
              <EpisodeSummaryContent variant={variant} episode={episode} number={number} />
            </PastEpisodeSummary>
          ),
          next: (
            <NextEpisodeSummary expandIcon={!!episode.guests.length && <ExpandAccordion size={32} color="white" />}>
              <EpisodeSummaryContent variant={variant} episode={episode} number={number} />
            </NextEpisodeSummary>
          ),
          future: (
            <FutureEpisodeSummary
              expandIcon={!!episode.guests.length && <ExpandAccordion size={32} color={theme.palette.grey['50']} />}
            >
              <EpisodeSummaryContent variant={variant} episode={episode} number={number} />
            </FutureEpisodeSummary>
          ),
        }[variant]
      }

      {!!episode.guests.length && (
        <AccordionDetails sx={{ marginTop: '20px', padding: 0 }}>
          <Guests
            contentCreators={episode.guests.map((g) => g.content_creator)}
            contentCreatorData={contentCreatorData}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
}

function EpisodeSummaryContent({ episode, number, variant }: EpisodeProps) {
  const startingDate = new Date(episode.start_time);

  return (
    <>
      <Typography variant="h4" color="inherit" sx={{ flex: 1 }}>
        Episode {number}
      </Typography>

      {variant === 'past' && (
        <Box>
          <Chip
            label="GELAUFEN"
            variant="outlined"
            style={{
              color: theme.palette.secondary.main,
              borderColor: theme.palette.secondary.main,
              fontSize: '1.125rem',
              transform: 'rotate(-12deg)',
            }}
          />
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: 10 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography className="datetime-information-headline">Datum</Typography>
          <Typography className="datetime-information">{startingDate.toLocaleDateString('de-DE')}</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography className="datetime-information-headline">Uhrzeit</Typography>
          <Typography className="datetime-information">
            {startingDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

const EpisodeSummary = styled(AccordionSummary)(() => ({
  margin: 0,
  padding: '1rem 1.74rem',
  borderRadius: '22px',
  width: '100%',
  display: 'flex',
  gap: '60px',
  alignItems: 'center',
  '& .MuiAccordionSummary-content': {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '60px',
  },
}));

const PastEpisodeSummary = styled(EpisodeSummary)(() => ({
  color: 'white',
  backgroundColor: theme.palette.grey['50'],
  '& .datetime-information-headline': {
    fontSize: '0.75rem',
    lineHeight: 1,
    textTransform: 'uppercase',
    color: theme.palette.common.white,
  },
  '& .datetime-information': {
    fontSize: '1.25rem',
    lineHeight: 1.2,
    color: 'white',
  },
}));

const NextEpisodeSummary = styled(EpisodeSummary)(() => ({
  color: 'white',
  backgroundColor: theme.palette.secondary.main,
  '& .datetime-information-headline': {
    fontSize: '0.75rem',
    lineHeight: 1,
    textTransform: 'uppercase',
    color: theme.palette.common.white,
  },
  '& .datetime-information': {
    fontSize: '1.25rem',
    lineHeight: 1.2,
    color: 'white',
  },
}));

const FutureEpisodeSummary = styled(EpisodeSummary)(() => ({
  color: theme.palette.grey['50'],
  backgroundColor: 'rgb(231, 253, 112)',
  '& .datetime-information-headline': {
    fontSize: '0.75rem',
    lineHeight: 1,
    textTransform: 'uppercase',
    color: 'rgba(19, 19, 19, 0.72)',
  },
  '& .datetime-information': {
    fontSize: '1.25rem',
    lineHeight: 1.2,
    color: theme.palette.grey['50'],
  },
}));
