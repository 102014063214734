import { Box, Grid } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormatImage, getImageSourceSet, getLargestImage, imagesByIndex } from '@/ts/Types/Format';

export default function ImagesGallery({ formatImages }: { formatImages: FormatImage[] }) {
  const [focusImage, setFocusImage] = React.useState(imagesByIndex(formatImages)[0]);

  const bigImage = useRef<HTMLDivElement>(null);

  const [bigImageHeight, setBigImageHeight] = useState(bigImage.current?.clientHeight);
  useLayoutEffect(() => {
    if (bigImage.current) {
      setBigImageHeight(bigImage.current.clientHeight);
    }
  });
  useEffect(() => {
    function handleWindowResize() {
      if (bigImage.current) {
        setBigImageHeight(bigImage.current.clientHeight);
      }
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Grid container columns={9} spacing={'20px'}>
      <Grid
        item
        sm={7}
        sx={{
          aspectRatio: 16 / 9,
        }}
      >
        <Box
          sx={{
            width: 1,
            aspectRatio: 16 / 9,
            height: 'auto',
            borderRadius: 2,
            overflow: 'hidden',
          }}
          ref={bigImage}
        >
          {!!focusImage && (
            <img
              alt=""
              srcSet={getImageSourceSet(focusImage)}
              src={getLargestImage(focusImage)?.url}
              style={{ objectFit: 'cover', height: '100%', width: '100%' }}
            />
          )}
        </Box>
      </Grid>
      <Grid item sm={2}>
        <Box
          sx={{
            width: 1,
            height: '100%',
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              maxHeight: bigImageHeight,
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5,
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {imagesByIndex(formatImages).map((image: FormatImage[], index: number) => {
              return (
                <Box
                  key={index}
                  sx={{
                    width: 1,
                    aspectRatio: 16 / 9,
                    borderRadius: 2,
                    overflow: 'hidden',
                    flexShrink: 0,
                    cursor: 'pointer',
                  }}
                  onClick={() => setFocusImage(image)}
                >
                  {!!image && (
                    <img
                      alt=""
                      srcSet={getImageSourceSet(image)}
                      src={getLargestImage(image)?.url}
                      style={{
                        objectFit: 'cover',
                        height: '100%',
                        width: '100%',
                        outline: image === focusImage ? '2px solid rgba(231, 253, 112, 0.32)' : '0',
                        outlineOffset: '-2px',
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
