import {
  ContentCreator,
  FormContentCreator,
  contentCreatorDBToForm,
  contentCreatorFormToDB,
  contentCreatorFormToPosting,
} from '@/ts/Types/ContentCreator';
import axios from 'axios';
import { FormDate, FormState, Format, FormatImage, imagesByIndex } from './Types/Format';

type AgeRange = {
  start: number;
  end: number;
  percentage: number;
};

type Country = {
  code: string; // 2letter
  percentage: number;
};

type Date = {
  start_time: number; // ms timestamp
  end_time: number; // ms timestamp
  guests: ContentCreator[];
};

type FormatPostData = {
  id: string | null;
  name: string;
  description: string;
  shortDescription: string;
  images: File[];
  target_audience_age_ranges: AgeRange[];
  target_audience_diverse_percentage: number;
  target_audience_male_percentage: number;
  target_audience_female_percentage: number;
  target_audience_countries: Country[];
  format_types: string[];
  topics: string[]; // IDs
  languages: string[]; // always only one for now, 2letter
  platforms: string[]; // Name, not readable!
  expected_unique_views: number;
  expected_social_media_views: number;
  expected_total_views: number;
  host: ContentCreator;
  dates: Date[];
  minimum_advertising_cost: number;
  advertisement_types: string[];
};

export type DateWithContentCreatorIds = {
  start_time: number; // ms timestamp
  end_time: number; // ms timestamp
  guests: string[];
};

export type FormatPostDataWithContentCreatorIds = {
  id: string | null;
  name: string;
  description: string;
  images: File[];
  target_audience_age_ranges: AgeRange[];
  target_audience_diverse_percentage: number;
  target_audience_male_percentage: number;
  target_audience_female_percentage: number;
  target_audience_countries: Country[];
  format_types: string[];
  topics: string[]; // IDs
  languages: string[]; // always only one for now, 2letter
  platforms: string[]; // Name, not readable!
  expected_unique_views: number;
  expected_social_media_views: number;
  expected_total_views: number;
  host: string;
  dates: DateWithContentCreatorIds[];
  advertisement_types: string[];
};

export function getFormState(format?: Format): FormState {
  if (!format) {
    return {
      id: null,
      name: '',
      description: '',
      shortDescription: '',
      images: [],
      oldImages: [],
      primaryFormatType: null,
      secondaryFormatTypes: [],
      platforms: [],
      language: null,
      topics: [],
      host: null,
      dates: [],
      estimatedUniqueViews: null,
      estimatedSocialMediaViews: null,
      estimatedTotalViews: null,
      targetAudienceMalePercentage: null,
      targetAudienceFemalePercentage: null,
      targetAudienceDiversePercentage: null,
      targetAudienceCountries: [],
      targetAudienceTo18Percentage: null,
      targetAudienceTo24Percentage: null,
      targetAudienceTo34Percentage: null,
      targetAudienceTo44Percentage: null,
      targetAudienceOver44Percentage: null,
      minimumAdvertisingCost: null,
      advertisementTypes: [],
      isDraft: false,
    };
  }

  return {
    id: format.id ?? null,
    name: format.name ?? '',
    description: format.description ?? '',
    shortDescription: format.short_description ?? '',
    images: [],
    oldImages: imagesByIndex(format.images).map((imagesForIndex: FormatImage[], index: number) => {
      const sortedDescendingByWidth = [...imagesForIndex].sort((a, b) => b.width - a.width);
      return {
        url: sortedDescendingByWidth[0].url,
        deleted: false,
        newIndex: index,
      };
    }),
    primaryFormatType: format.types?.[0]?.format_type,
    secondaryFormatTypes: format.types?.slice(1).map((t) => t.format_type) ?? [],
    platforms: format.platforms?.map((p) => p.name) ?? [],
    language: format.languages?.[0]?.language,
    topics: format.topics?.map((t) => t.topic.id) ?? [],
    host: format.host ? contentCreatorDBToForm(format.host) : null,
    dates:
      format.dates?.map((d) => {
        // const startingTime = new Date(d.start_time);
        // const endingTime = new Date(d.end_time);

        const startingTime = new Date(Date.parse(d.start_time + ' UTC'));
        const endingTime = new Date(Date.parse(d.end_time + ' UTC'));

        const startingMs = startingTime.getTime() - startingTime.getTimezoneOffset();
        const durationMs = endingTime.getTime() - startingTime.getTime();
        const diffHrs = Math.floor(durationMs / 1000 / 60 / 60);
        const diffMins = Math.floor((durationMs / 1000 / 60 / 60 - diffHrs) * 60);
        return {
          startingDate: startingMs,
          startingTime: startingMs,
          duration: diffHrs + ':' + diffMins,
          guests: d.guests.map((g) => {
            return contentCreatorDBToForm(g.content_creator);
          }),
        };
      }) ?? [],
    // estimatedUniqueViews: format?.expected_unique_views / 1000,
    // estimatedSocialMediaViews: format?.expected_social_media_views / 1000000,
    // estimatedTotalViews: format?.expected_total_views / 1000000,
    estimatedUniqueViews: format?.expected_unique_views,
    estimatedSocialMediaViews: format?.expected_social_media_views,
    estimatedTotalViews: format?.expected_total_views,
    targetAudienceMalePercentage: format?.target_audience?.gender_male_percentage,
    targetAudienceFemalePercentage: format?.target_audience?.gender_female_percentage,
    targetAudienceDiversePercentage: format?.target_audience?.gender_diverse_percentage,

    targetAudienceCountries:
      format.target_audience?.countries.map((c) => {
        return { country: c.country, percentage: c.percentage };
      }) ?? [],
    targetAudienceTo18Percentage: format.target_audience?.age_ranges[0]?.percentage,
    targetAudienceTo24Percentage: format.target_audience?.age_ranges[1]?.percentage,
    targetAudienceTo34Percentage: format.target_audience?.age_ranges[2]?.percentage,
    targetAudienceTo44Percentage: format.target_audience?.age_ranges[3]?.percentage,
    targetAudienceOver44Percentage: format.target_audience?.age_ranges[4]?.percentage,
    minimumAdvertisingCost: format?.minimum_advertising_cost,
    advertisementTypes: format.advertisement_types?.map((a) => a.advertisement_type.id) ?? [],
    isDraft: format.is_draft,
  };
}

export function getFormData(data: FormState): FormData {
  const transformedData = { ...transformFormForPosting(data) };
  const form = new FormData();

  form.append('name', transformedData.name || '');

  if (transformedData.description) form.append('description', transformedData.description);

  if (transformedData.shortDescription) form.append('short_description', transformedData.shortDescription);

  if (data.id === undefined || data.id === null) {
    transformedData.images?.map((image, index) => form.append('images[' + index + ']', image));
  } else {
    let oldImagesIndex = 0;

    data.oldImages.map((oldImage, index) => {
      form.append('old_images[' + index + '][deleted]', oldImage.deleted ? '1' : '0');
      if (!oldImage.deleted) {
        form.append('old_images[' + index + '][new_index]', oldImagesIndex.toString());
        oldImagesIndex++;
      }
    });

    transformedData.images?.map((image, index) => {
      form.append('images[' + index + '][file]', image);
      form.append(
        'images[' + index + '][index]',
        (data.oldImages.filter((oldImage) => !oldImage.deleted).length + index).toString(),
      );
    });
  }

  if (transformedData.target_audience_age_ranges)
    transformedData.target_audience_age_ranges?.map((targetAudienceAgeRange, index) => {
      form.append('target_audience_age_ranges[' + index + '][start]', targetAudienceAgeRange.start.toString());
      form.append('target_audience_age_ranges[' + index + '][end]', targetAudienceAgeRange.end.toString());
      form.append(
        'target_audience_age_ranges[' + index + '][percentage]',
        targetAudienceAgeRange.percentage.toString(),
      );
    });

  form.append(
    'target_audience_diverse_percentage',
    transformedData.target_audience_diverse_percentage?.toString() ?? '',
  );

  form.append('target_audience_male_percentage', transformedData.target_audience_male_percentage?.toString() ?? '');

  form.append('target_audience_female_percentage', transformedData.target_audience_female_percentage?.toString() ?? '');

  if (transformedData.target_audience_countries)
    transformedData.target_audience_countries?.map((targetAudienceCountry, index) => {
      form.append('target_audience_countries[' + index + '][code]', targetAudienceCountry.code);
      form.append('target_audience_countries[' + index + '][percentage]', targetAudienceCountry.percentage.toString());
    });

  if (transformedData.format_types)
    transformedData.format_types?.map((formatType) => form.append('format_types[]', formatType));

  if (transformedData.topics) transformedData.topics?.map((topic) => form.append('topics[]', topic));

  if (transformedData.languages) form.append('languages[]', transformedData.languages?.[0]);

  form.append('minimum_advertising_cost', transformedData.minimum_advertising_cost?.toString() ?? '0');

  form.append('expected_unique_views', (transformedData.expected_unique_views ?? 0 * 1000)?.toString() ?? '0');

  form.append(
    'expected_social_media_views',
    (transformedData.expected_social_media_views ?? 0 * 1000000).toString() ?? '0',
  );

  form.append('expected_total_views', (transformedData.expected_total_views ?? 0 * 1000000).toString() ?? '0');

  if (transformedData.advertisement_types)
    transformedData.advertisement_types?.map((advertisementType) =>
      form.append('advertisement_types[]', advertisementType),
    );

  if (transformedData.platforms) transformedData.platforms?.map((platform) => form.append('platforms[]', platform));

  // TODO: refactor to only use append
  if (transformedData.host) form.append('host', transformedData.host?.id);

  if (transformedData.dates)
    transformedData.dates?.map((transformedDate, dateIndex) => {
      form.append(
        'dates[' + dateIndex + '][start_time]',
        new Date(transformedDate.start_time).toISOString().replace('.000Z', '+00:00'),
      );

      form.append(
        'dates[' + dateIndex + '][end_time]',
        new Date(transformedDate.end_time).toISOString().replace('.000Z', '+00:00'),
      );

      transformedDate.guests.forEach((guest, guestIndex) => {
        form.append('dates[' + dateIndex + '][guests][' + guestIndex + ']', guest.id);
      });
    });

  return form;
}

export async function saveContentCreator(contentCreator: FormContentCreator) {
  const method = contentCreator.id === null ? 'post' : 'put';

  const transformedContentCreator = contentCreatorFormToPosting(contentCreator);

  const data = {
    name: transformedContentCreator.name,
    topics: transformedContentCreator.topics,
    main_platform: transformedContentCreator.main_platform,
    _method: method,
  };

  const platforms = transformedContentCreator.platforms
    .filter((p) => p.social_handle != '')
    .map((platform) => {
      const name: string = platform.name;
      return { [name]: platform.social_handle };
    });

  platforms.map((platform) => Object.assign(data, platform));

  const url = method === 'post' ? '/api/content-creators' : '/api/content-creators/' + transformedContentCreator.id;

  return axios.post(url, data);
}

export function transformFormForPosting(formState: FormState): Partial<FormatPostData> {
  function transformDate(formDate: FormDate): Date {
    const startDateOnly = new Date(formDate?.startingDate ?? 0);
    const startTimeOnly = new Date(formDate?.startingTime ?? 0);

    const startDateTime = new Date(
      startDateOnly.getFullYear(),
      startDateOnly.getMonth(),
      startDateOnly.getDate(),
      startTimeOnly.getHours(),
      startTimeOnly.getMinutes(),
    );

    // HH:mm
    const duration = formDate.duration ? formDate.duration.split(':') : '00:00';
    const durationMs = (parseInt(duration[0]) * 60 * 60 + parseInt(duration[1]) * 60) * 1000;
    const endDateTime = new Date(startDateTime.getTime() + durationMs);

    return {
      start_time: startDateTime.getTime(),
      end_time: endDateTime.getTime(),
      guests: formDate.guests.filter((g) => g !== null).map((guest) => contentCreatorFormToDB(guest!)),
    };
  }

  return {
    id: null,
    name: formState?.name,
    description: formState?.description,
    shortDescription: formState?.shortDescription,
    images: formState.images as File[],
    target_audience_age_ranges: [
      { start: 0, end: 17, percentage: formState.targetAudienceTo18Percentage ?? 0 },
      { start: 18, end: 24, percentage: formState.targetAudienceTo24Percentage ?? 0 },
      { start: 25, end: 34, percentage: formState.targetAudienceTo34Percentage ?? 0 },
      { start: 35, end: 44, percentage: formState.targetAudienceTo44Percentage ?? 0 },
      { start: 45, end: 99, percentage: formState.targetAudienceOver44Percentage ?? 0 },
    ],
    target_audience_diverse_percentage: formState.targetAudienceDiversePercentage ?? 0,
    target_audience_male_percentage: formState.targetAudienceMalePercentage ?? 0,
    target_audience_female_percentage: formState.targetAudienceFemalePercentage ?? 0,
    target_audience_countries: formState.targetAudienceCountries?.map((c) => {
      return { code: c.country, percentage: c.percentage ?? 0 };
    }),
    format_types: formState.primaryFormatType
      ? [formState.primaryFormatType, ...(formState.secondaryFormatTypes || undefined)]
      : undefined,
    topics: formState?.topics,
    languages: formState.language ? [formState.language] : undefined,
    platforms: formState?.platforms,
    expected_unique_views: formState.estimatedUniqueViews ?? 0,
    expected_social_media_views: formState.estimatedSocialMediaViews || 0,
    expected_total_views: formState.estimatedTotalViews ?? 0,
    host: formState.host ? contentCreatorFormToDB(formState.host) : undefined,
    dates: formState.dates?.map((date) => transformDate(date)) || undefined,
    minimum_advertising_cost: formState?.minimumAdvertisingCost ?? 0,
    advertisement_types: formState?.advertisementTypes,
  };
}
