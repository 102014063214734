import { FormFieldLabelBox, FormSectionHeadline, FormSectionPaper } from '@/ts/Pages/FormatFormPage';
import { FormState } from '@/ts/Types/Format';
import { TextField, Typography } from '@mui/material';
import { useErrors } from './ErrorsContext';

export default function NameAndDescriptionForm({
  formState,
  setName,
  setDescription,
  setShortDescription,
  setMinimumAdvertisingCost,
}: {
  formState: FormState;
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  setShortDescription: (shortDescription: string) => void;
  setMinimumAdvertisingCost: (cost: number) => void;
}) {
  const { getError } = useErrors();

  const nameError = getError('name');
  const shortDescriptionError = getError('shortDescription') || getError('short_description');
  const descriptionError = getError('description');
  const minimumAdvertisingCostError = getError('minimumAdvertisingCost') || getError('minimum_advertising_cost');
  const anyFieldHasError =
    !!nameError || !!shortDescriptionError || !!descriptionError || !!minimumAdvertisingCostError;
  return (
    <>
      <FormSectionHeadline hasError={anyFieldHasError} data-has-error={anyFieldHasError}>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Show-Format' }} />
      </FormSectionHeadline>
      <FormSectionPaper hasError={anyFieldHasError}>
        <FormFieldLabelBox>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Name des Showformats
          </Typography>
          <TextField
            hiddenLabel
            placeholder="Name"
            variant="outlined"
            value={formState.name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
            error={!!nameError}
            helperText={nameError?.message}
          />
        </FormFieldLabelBox>
        <FormFieldLabelBox>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Kurzbeschreibung
          </Typography>
          <TextField
            hiddenLabel
            placeholder="Kurzbeschreibung"
            variant="outlined"
            value={formState.shortDescription}
            onChange={(e) => e.target.value.length < 255 && setShortDescription(e.target.value)}
            fullWidth
            required
            error={!!shortDescriptionError}
            helperText={shortDescriptionError?.message}
          />
        </FormFieldLabelBox>
        <FormFieldLabelBox>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Beschreibung
          </Typography>
          <TextField
            hiddenLabel
            placeholder="Beschreibung"
            variant="outlined"
            value={formState.description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            required
            multiline
            minRows={3}
            error={!!descriptionError}
            helperText={descriptionError?.message}
          />
        </FormFieldLabelBox>
        <FormFieldLabelBox>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Mindestwerbekosten in €
          </Typography>
          <TextField
            hiddenLabel
            placeholder="0 €"
            variant="outlined"
            value={formState.minimumAdvertisingCost ?? ''}
            onChange={(event) => setMinimumAdvertisingCost(parseInt(event.target.value))}
            fullWidth
            required
            type="number"
            error={!!minimumAdvertisingCostError}
            helperText={minimumAdvertisingCostError?.message}
          />
        </FormFieldLabelBox>
      </FormSectionPaper>
    </>
  );
}
