import { Box, Typography } from '@mui/material';
import { theme } from '@/ts/theme';
import AgeDistributionLineChart from '@/ts/Components/FormatDetail/AgeDistributionLineChart';
import React from 'react';
import { FormatTargetAudienceAgeRange } from '@/ts/Types/Format';

export default function AgeDistribution({
  targetAudienceAgeDistribution,
}: {
  targetAudienceAgeDistribution: FormatTargetAudienceAgeRange[];
}) {
  const sortedAgeDistributionWithLegend = [...targetAudienceAgeDistribution]
    .sort((a, b) => a.start - b.start)
    .map((ageRange, index) => {
      const legend =
        ageRange.start === 0
          ? 'Unter ' + ageRange.end
          : index === targetAudienceAgeDistribution.length - 1
          ? ageRange.start + '+'
          : ageRange.start + '-' + ageRange.end;
      return { ...ageRange, legend: legend };
    });

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: 3 }}>
        Zielgruppe
      </Typography>
      <Typography variant="subtitle2" sx={{ marginBottom: 1.5 }}>
        Alter:
      </Typography>
      <Box sx={{ display: 'flex', gap: '8px 16px', flexWrap: 'wrap', marginBottom: 8 }}>
        {sortedAgeDistributionWithLegend.map((ageRange) => {
          return <Legend key={ageRange.legend} range={ageRange.legend} percentage={ageRange.percentage} />;
        })}
      </Box>
      <Box style={{ flex: 1 }}>
        <AgeDistributionLineChart
          labels={sortedAgeDistributionWithLegend.map((ageRange) => ageRange.legend)}
          percentages={sortedAgeDistributionWithLegend.map((ageRange) => ageRange.percentage)}
        />
      </Box>
    </>
  );
}

type LegendProps = {
  range: string;
  percentage: number;
};
function Legend({ range, percentage }: LegendProps) {
  return (
    <Box sx={{ display: 'flex', gap: 0.75 }}>
      <Typography variant="subtitle2" sx={{ color: theme.palette.secondary.main }}>
        {range}
      </Typography>
      <Typography variant="subtitle2" sx={{ color: theme.palette.common.white }}>
        {percentage}%
      </Typography>
    </Box>
  );
}
