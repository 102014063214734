import { SvgIcon } from '@mui/material';

export default function SortingGeneralDescending() {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24">
      <path
        d="M4 17L15 17M4 12H12M4 7H9M17 14.5V5.5M17 5.5L20 8.5M17 5.5L14 8.5"
        stroke="#E7FD70"
        strokeOpacity="0.8"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
