import Hide from "@/ts/Components/icons/Hide";
import { User, UserRole, ROLE_ADMIN, ROLE_CREATOR_MANAGEMENT, ROLE_ADVERTISER } from "@/ts/Types/User";
import { useForm } from "@inertiajs/react";
import { Dialog, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { createUser, updateUser } from "../api";
import { UserFormState } from "../types";



const CreateEditModal = ({ open, setOpen, user }: { open: boolean; setOpen: (open: boolean) => void; user: User | null }) => {
    const defaultInput: UserFormState =
      user === null
        ? {
            id: null,
            email: '',
            name: '',
            password: '',
            role: null,
          }
        : {
            id: user.id,
            email: user.email,
            name: user.name,
            password: '',
            role: user.role,
          };
  
    const { data, setData } = useForm<UserFormState>(defaultInput);
    return (
      <Dialog
        fullWidth
        open={open}
        maxWidth="lg"
        onClose={() => setOpen(false)}
        sx={{
          backdropFilter: 'blur(5px)',
          borderRadius: '22px',
        }}
        PaperProps={{
          sx: {
            padding: '1.75rem',
            borderRadius: '22px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1.75rem',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h2" sx={{ lineHeight: 1 }}>
            Benutzer {data.id === null ? 'anlegen' : 'bearbeiten'}
          </Typography>
          <Box sx={{ cursor: 'pointer' }} onClick={() => setOpen(false)}>
            <Hide size={36} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <TextField
            label="Name"
            variant="outlined"
            value={data.name}
            onChange={(e) => setData('name', e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="E-Mail"
            variant="outlined"
            value={data.email}
            onChange={(e) => setData('email', e.target.value)}
            fullWidth
            required
            type="email"
          />
          <TextField
            label="Passwort"
            variant="outlined"
            value={data.password}
            onChange={(e) => setData('password', e.target.value)}
            fullWidth
            type="password"
          />
          <FormControl fullWidth>
            <InputLabel>Rolle</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.role}
              label="Age"
              onChange={(e) => setData('role', e.target.value as UserRole)}
            >
              <MenuItem value={ROLE_ADMIN}>Administrator</MenuItem>
              <MenuItem value={ROLE_CREATOR_MANAGEMENT}>Content Creator Management</MenuItem>
              <MenuItem value={ROLE_ADVERTISER}>Werbetreibender</MenuItem>
            </Select>
          </FormControl>
          <Button
            disabled={!isValidInput(data)}
            variant="contained"
            onClick={() => (data.id === null ? createUser(data) : updateUser(data).then(() => window.location.reload()))}
          >
            Speichern
          </Button>
        </Box>
      </Dialog>
    );
  }

  function isValidInput(inputData: UserFormState) {
    if (inputData.name.trim().length === 0) return false;
    if (inputData.email.trim().length === 0) return false;
    if (inputData.id === null && inputData.password.length === 0) return false;
    if (inputData.role === null) return false;
    return true;
  }


  export default CreateEditModal;