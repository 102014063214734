import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Logo from '/public/assets/logo.svg';
import { useScrollTrigger } from '@mui/material';
import Slide from '@mui/material/Slide';
import { Link } from '@inertiajs/react';
import { theme } from '@/ts/theme';
import { ROLE_ADMIN, User } from '@/ts/Types/User';
import { css } from '@emotion/react';

// const drawerWidth = "240";
const drawerWidth = '100%';

export enum PAGE {
  About,
  AdvertisementTypes,
  Formats,
  Administration,
  Other,
}

type NavigationItem = {
  name: string;
  url: string;
  activePage: PAGE | null;
  external?: boolean;
};

export default function Header({ activePage, user }: { activePage: PAGE; user: User | null }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigationItems: NavigationItem[] = [
    // { name: 'About us', url: 'https://www.nevernotnew.com/about-us', activePage: null, external: true },
    { name: 'Werbeformate', url: '/advertisement-types', activePage: PAGE.AdvertisementTypes },
  ];
  if (user !== null && user !== undefined) {
    navigationItems.push({ name: 'Showformate', url: '/formats', activePage: PAGE.Formats });
  }
  if (user?.role === ROLE_ADMIN)
    navigationItems.push({ name: 'Administration', url: '/administration', activePage: PAGE.Administration });
  if (user === null || user === undefined) {
    navigationItems.push({ name: 'Log In', url: '/login', activePage: null });
  } else {
    navigationItems.push({ name: 'Log out', url: '/logout', activePage: null });
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', paddingTop: 3 }}>
      {/* <a href="/formats">
        <MobileLogo src={Logo} alt="" />
      </a> */}
      {/* <Divider /> */}
      <List>
        {navigationItems.map((item) => {
          const Element = item.external ? 'a' : Link;
          return (
            <Element key={item.name} href={item.url} style={{ textDecoration: 'none' }}>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <CustomListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            </Element>
          );
        })}
      </List>
    </Box>
  );

  const scrollTrigger = useScrollTrigger();

  return (
    <>
      <Slide appear={false} direction="down" in={!scrollTrigger}>
        <CustomAppBar
          position="fixed"
          sx={{
            pointerEvents: 'none',
            '@media (min-width: 768px)': {
              flexDirection: 'row',
            },
          }}
        >
          <Toolbar
            sx={{
              maxWidth: 'calc(103rem + 48px)',
              marginInline: 'auto',
              width: '100%',
              padding: '0px 24px',
              minHeight: '0 !important',
              height: 'auto',
              marginBlock: '20px 20px',
              pointerEvents: 'auto',
              '@media (min-width: 768px)': {
                paddingInline: '24px',
                marginBlock: '10px 40px 40px 40px',
              },
            }}
          >
            <ToolbarLogo>
              <a href="/formats" style={{ display: 'inline-block' }}>
                <DesktopLogo src={Logo} alt="" />
              </a>
            </ToolbarLogo>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ mr: 0, p: 0, display: { sm: 'none' } }}
            >
              {/* <MenuIcon sx={{ color: theme.palette.secondary.main }} /> */}
              {mobileOpen ? (
                <CloseIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />
              ) : (
                <MenuIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />
              )}
            </IconButton>
            <CustomNavContainer sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navigationItems.map((item, index) => (
                <MenuButton
                  link={item.url}
                  active={item.activePage === activePage}
                  value={item.name}
                  key={item.name}
                  external={item.external}
                />
              ))}
            </CustomNavContainer>
          </Toolbar>
        </CustomAppBar>
      </Slide>
      <nav>
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiModal-backdrop': {
              opacity: 1,
              backgroundColor: 'transparent',
            },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              background: theme.palette.background.default,
              marginTop: '64px',
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}

const CustomAppBar = styled(AppBar)`
  color: rgba(255, 255, 255, 0.8);
  box-shadow: none;
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`;

const CustomNavContainer = styled(Box)`
  border-radius: 22px;
  padding: 18px 26px 18px 26px;
  background: #121212;
  a:last-child {
    border-left: 1px solid rgb(255 255 255 / 32%);
  }
`;
const CustomListItemText = styled(ListItemText)`
  span {
    font-family: Roboto, sans-serif;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
  }
`;

const MobileLogo = styled.img`
  width: 60%;
  height: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ToolbarLogo = styled.div`
  flex-grow: 1;
`;

const DesktopLogo = styled.img`
  display: block;
  height: 1.75rem;
  @media (min-width: 1920px) {
    height: 2rem;
  }
`;

function MenuButton({
  active,
  value,
  link,
  external,
}: {
  active: boolean;
  value: string;
  link: string;
  external?: boolean;
}) {
  const Element = external ? 'a' : Link;
  return (
    <Element href={link} style={{ textDecoration: 'none' }}>
      <Button
        variant="text"
        disableRipple
        sx={{
          marginLeft: '1.5rem',
          marginRight: '1.5rem',
          paddingY: 0,
          fontSize: '.87rem',
          fontWeight: '400',
          lineHeight: 1.5,
          letterSpacing: '.02rem',
          color: active ? theme.palette.primary.light : 'rgba(255, 255, 255, 0.72)',
          textDecoration: active ? 'underline' : 'none',
          background: 'none',
          '@media (min-width: 992px)': {
            fontSize: '1rem',
          },
          '&:hover': {
            color: theme.palette.primary.light,
            background: 'none',
            textDecoration: 'underline',
          },
        }}
      >
        {value}
      </Button>
    </Element>
  );
}
