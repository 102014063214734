import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { Format, nextDate, sortedDates } from '@/ts/Types/Format';

export default function KeyInformation({ format }: { format: Format }) {
  const sortedEpisodes = sortedDates(format);
  const nextEpisode = nextDate(format);
  return (
    <>
      <Typography variant="h2">{format.name}</Typography>
      <Box sx={{ display: 'flex', gap: '40px 80px', flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography variant="subtitle2">Episode</Typography>
          <Typography sx={{ fontSize: '1.5rem', lineHeight: 1.5 }}>
            {nextEpisode === undefined && '-'}
            {nextEpisode !== undefined &&
              sortedEpisodes.findIndex((episode) => episode.start_time === nextEpisode.start_time) +
                1 +
                '/' +
                sortedEpisodes.length}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography variant="subtitle2">Nächste Episode</Typography>
          <Typography sx={{ fontSize: '1.5rem', lineHeight: 1.5 }}>
            {nextEpisode === undefined && '-'}
            {nextEpisode !== undefined && new Date(nextEpisode.start_time).toLocaleDateString('de-DE')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography variant="subtitle2">Uhrzeit</Typography>
          <Typography sx={{ fontSize: '1.5rem', lineHeight: 1.5 }}>
            {nextEpisode === undefined && '-'}
            {nextEpisode !== undefined &&
              new Date(nextEpisode.start_time).toLocaleTimeString('de-DE', {
                hour: '2-digit',
                minute: '2-digit',
              })}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography variant="subtitle2">Dauer</Typography>
          <Typography sx={{ fontSize: '1.5rem', lineHeight: 1.5 }}>
            {nextEpisode === undefined && '-'}
            {nextEpisode !== undefined &&
              (() => {
                const difference =
                  new Date(nextEpisode.end_time).getTime() - new Date(nextEpisode.start_time).getTime();
                const differenceHours = Math.floor((difference % 86400000) / 3600000);
                const differenceMinutes = Math.round(((difference % 86400000) % 3600000) / 60000);
                return differenceHours + ':' + differenceMinutes.toString().padStart(2, '0') + ' h';
              })()}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="subtitle2">Themen</Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {format.topics.map((topic, index) => {
              return <Chip key={index} label={'#' + topic.topic.name} variant="outlined" />;
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
}
