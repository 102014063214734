import React from 'react';
import { router } from '@inertiajs/react';
import { Button } from '@mui/material';

export default function LogoutButton() {
  return (
    <Button onClick={() => router.post('/logout')} fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
      Logout
    </Button>
  );
}
