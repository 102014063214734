export default function Expand({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 21H3M3 21V14M3 21L6.36059 17.7091L9.7212 14.4181L9.82621 14.3153"
        stroke="#E7FD70"
        strokeOpacity="0.8"
        strokeWidth="1.6"
        strokeLinecap="square"
        fill="transparent"
      />
      <path
        d="M14 3H21M21 3V10M21 3L17.6394 6.29093L14.2788 9.58187L14.1738 9.68471"
        stroke="#E7FD70"
        strokeOpacity="0.8"
        strokeWidth="1.6"
        strokeLinecap="square"
        fill="transparent"
      />
    </svg>
  );
}
