import React, { PropsWithChildren } from 'react';
import { Head } from '@inertiajs/react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import Header, { PAGE } from '../Components/Header';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { theme } from '../theme';
import { Global, css } from '@emotion/react';
import { User } from '@/ts/Types/User';

type PageProps = {
  title: string;
  activePage: PAGE;
  user?: User;
};

export default function Page(props: PropsWithChildren<PageProps>) {
  return (
    <>
      <Head>
        <title>{props.title}</title>
      </Head>
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }
          @media screen and (prefers-reduced-motion: reduce) {
            html {
              scroll-behavior: auto;
            }
          }
        `}
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header activePage={props.activePage} user={props.user ?? null} />
        <Box component="main" sx={{ mt: 11, p: 3, overflow: 'hidden' }}>
          {props.children}
        </Box>
      </ThemeProvider>
    </>
  );
}
