import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { theme } from '@/ts/theme';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';

type ConfirmDialogProps = {
  button: React.ReactElement;
  dialogTitle: string;
};

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const [open, setOpen] = React.useState(false);

  const buttonOnClick = props.button.props.onClick;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAbort = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    buttonOnClick && buttonOnClick();
  };

  return (
    <React.Fragment>
      {React.cloneElement(props.button, { ...props.button.props, onClick: handleClickOpen })}
      {open && (
        <Dialog
          open
          onClose={handleAbort}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
          PaperProps={{
            sx: {
              padding: '1.75rem 0',
              flexDirection: 'column',
              gap: '1.5rem',
            },
          }}
          slotProps={{
            backdrop: {
              sx: {
                backdropFilter: 'blur(12px)',
              },
            },
          }}
        >
          <DialogTitle
            id="confirm-dialog-title"
            sx={{
              padding: '0 1.75rem',
            }}
          >
            <>
              <Typography
                paragraph
                variant="h2"
                dangerouslySetInnerHTML={{
                  __html: props.dialogTitle,
                }}
              />
            </>
          </DialogTitle>
          <DialogActions sx={{ gap: '.75rem', padding: '0 1.75rem' }}>
            <ActionButtonYes onClick={handleConfirm} autoFocus>
              Ja
            </ActionButtonYes>
            <ActionButtonNo onClick={handleAbort}>Nein</ActionButtonNo>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}

const ActionButtonYes = styled(Button)`
  flex: 1 1 100%;
  max-width: 50%;
  padding: 0.75rem 1rem;
  border: 1px solid ${() => theme.palette.error.light};
  color: ${() => theme.palette.error.light};
  border-radius: 16px;

  &:hover {
    border-color: ${() => theme.palette.error.main};
    color: ${() => theme.palette.error.main};
    background-color: rgba(255, 255, 255, 0.04);
  }
`;

const ActionButtonNo = styled(Button)`
  flex: 1 1 100%;
  max-width: 50%;
  padding: 0.75rem 1rem;
  border: 1px solid ${() => theme.palette.grey['500']};
  border-radius: 16px;
`;
