export type User = {
  id: string;
  name: string;
  email: string;
  role: UserRole;
};

export const ROLE_ADMIN = 'admin';
export const ROLE_CREATOR_MANAGEMENT = 'creator-management';
export const ROLE_ADVERTISER = 'advertiser';

export type UserRole = typeof ROLE_ADMIN | typeof ROLE_CREATOR_MANAGEMENT | typeof ROLE_ADVERTISER;
