import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';

import '@/css/global.scss';

import './i18n';
import initSentry from './sentry';

createInertiaApp({
  resolve: (name) => {
    const pages = import.meta.glob('./Pages/**/*.tsx', { eager: true });
    return pages[`./Pages/${name}.tsx`];
  },
  setup({ el, App, props }) {
    initSentry();
    createRoot(el).render(<App {...props} />);
  },
});
