import { SvgIcon } from '@mui/material';

export default function SortingAlphabeticalAscending() {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24">
      <path
        d="M18.1322 16.05L17.3446 16.8951M17.3446 16.8951C17.2795 16.965 17.1941 16.9999 17.1087 17M17.3446 16.8951L20.9995 12.9738M16.0852 16.05L16.8723 16.8951M16.8723 16.8951C16.9376 16.9651 17.0232 17.0001 17.1087 17M16.8723 16.8951L13.2178 12.9738M17.1087 17V6"
        stroke="#E7FD70"
        strokeOpacity="0.8"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.64045 17.1767C3.54263 17.4522 3.2819 17.6364 2.98949 17.6364C2.50921 17.6364 2.17552 17.1584 2.34106 16.7075L6.0321 6.65531C6.17665 6.26165 6.55146 6 6.97082 6H7.02918C7.44854 6 7.82335 6.26165 7.9679 6.65531L11.6589 16.7075C11.8245 17.1584 11.4908 17.6364 11.0105 17.6364C10.7181 17.6364 10.4574 17.4522 10.3595 17.1767L7.06066 7.88375C7.05155 7.85807 7.02725 7.84091 7 7.84091C6.97275 7.84091 6.94845 7.85807 6.93934 7.88374L3.64045 17.1767ZM4.02273 13.7159C4.02273 13.3707 4.30255 13.0909 4.64773 13.0909H9.35227C9.69745 13.0909 9.97727 13.3707 9.97727 13.7159C9.97727 14.0611 9.69745 14.3409 9.35227 14.3409H4.64773C4.30255 14.3409 4.02273 14.0611 4.02273 13.7159Z"
        fill="#E7FD70"
        fillOpacity="0.8"
      />
    </SvgIcon>
  );
}
