export default function OpenLink({ size }: { size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28">
      <path
        d="M4 4H24M24 4V24M24 4L14.3983 13.4027L4.79658 22.8053L4.49653 23.0992"
        stroke="#E7FD70"
        strokeWidth="2.4"
        strokeLinecap="square"
      />
    </svg>
  );
}
