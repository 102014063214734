import AddFile from '@/ts/Components/icons/AddFile';
import DeleteImage from '@/ts/Components/icons/DeleteImage';
import { FormSectionHeadline, FormSectionPaper } from '@/ts/Pages/FormatFormPage';
import { FormState, OldImage } from '@/ts/Types/Format';
import { theme } from '@/ts/theme';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useErrors } from './ErrorsContext';

type Data = {
  images: (File & { preview: string })[];
  oldImages?: {
    url: string;
    deleted: boolean;
    newIndex: number | null;
  }[];
};

export default function ImagesForm({
  data,
  setImages,
  setOldImages,
  errorMessage,
  asSingular = false,
}: {
  data: Data;
  setImages: (images: (File & { preview: string })[]) => void;
  setOldImages: (oldImages: OldImage[]) => void;
  errorMessage?: string;
  asSingular?: boolean;
}) {
  const { getError } = useErrors();

  const handleGetErrors = () => {
    if (errorMessage) {
      return { field: 'images', message: errorMessage };
    } else {
      return getError('images') || getError('old_images');
    }
  };

  const error = handleGetErrors();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      setImages(
        data.images
          .filter((image) => !acceptedFiles.map((i) => i.name).includes(image.name))
          .concat(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
            ),
          ),
      );
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => data.images.forEach((image) => URL.revokeObjectURL(image.preview));
  }, []);

  return (
    <>
      <FormSectionHeadline hasError={!!error} data-has-error={!!error}>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: asSingular ? 'Bild' : 'Bilder' }} />
      </FormSectionHeadline>
      <FormSectionPaper hasError={!!error}>
        <Typography>
          Das Seitenverhältnis der Bilder muss 16:9 betragen.{' '}
          <span style={{ fontStyle: 'italic' }}>Die ideale Auflösung der Bilder ist 1920x1080 Pixel.</span>
        </Typography>
        <Grid container rowSpacing={2} columnSpacing={1}>
          {data.oldImages
            ?.filter((oldImage) => !oldImage.deleted)
            .map((oldImage) => {
              return (
                <ImagePreview
                  imageSrc={oldImage.url}
                  key={oldImage.url}
                  removeImage={() => {
                    if (!data.oldImages) return;
                    const imageToDelete = data.oldImages.find((img) => img.url === oldImage.url)!;
                    const updatedOldImages: OldImage[] = [
                      ...data.oldImages.filter((img) => img.url !== oldImage.url),
                      {
                        url: imageToDelete.url,
                        deleted: true,
                        newIndex: null,
                      },
                    ];
                    setOldImages(updatedOldImages);
                  }}
                />
              );
            })}

          {data.images.map((image) => {
            return (
              <ImagePreview
                imageSrc={image.preview}
                removeImage={() => setImages(data.images.filter((img) => img.name != image.name))}
                key={image.name}
              />
            );
          })}
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                width: 1,
                aspectRatio: 16 / 9,
                borderRadius: 2,
                borderWidth: '1px',
                borderColor: 'rgba(255, 255, 255, 0.3)',
                borderStyle: 'dashed',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                padding: '2rem',
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <AddFile size={56} color={theme.palette.common.white} />
              <Typography textAlign="center" fontSize="0.8125rem">
                Hinzufügen oder Ziehen und Ablegen eines Bildes
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {!!error && <Typography color="error">{error.message}</Typography>}
      </FormSectionPaper>
    </>
  );
}

function ImagePreview({ imageSrc, removeImage }: { imageSrc: string; removeImage: () => void }) {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Box sx={{ width: 1, aspectRatio: 16 / 9, borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
        <img
          alt=""
          src={imageSrc}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          onLoad={() => {
            URL.revokeObjectURL(imageSrc);
          }}
        />
        <Box sx={{ position: 'absolute', margin: 0.75, top: 0, right: 0, cursor: 'pointer' }} onClick={removeImage}>
          <DeleteImage size={20} color="#FD7070" />
        </Box>
      </Box>
    </Grid>
  );
}
