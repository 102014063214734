import { theme } from '@/ts/theme';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AdvertisementType } from '@/ts/Types/AdvertisementType';
import { Format } from '@/ts/Types/Format';
import { ContentPlatform } from '@/ts/Types/Platform';
import AdvertisementTypeCard from '@/ts/Components/AdvertisementTypes/AdvertisementTypeCard';

export default function AdvertisementTypes({ format }: { format: Format }) {
  const availableAdvertisementTypesPlatforms = format.platforms;
  const [selectedAdvertisementTypesPlatformsFilter, setAdvertisementTypesPlatformsFilter] =
    useState<ContentPlatform | null>(null);

  return (
    <>
      <Paper
        sx={{
          padding: '1.75rem',
          borderRadius: '22px',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          backgroundColor: theme.palette.secondary.main,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ color: 'white', fontSize: '1.5rem', textTransform: 'uppercase' }}>Werbeformate</Typography>
        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <FilterButton
            name="Alle"
            handleFilter={() => {
              setAdvertisementTypesPlatformsFilter(null);
            }}
            active={selectedAdvertisementTypesPlatformsFilter === null}
          />
          {availableAdvertisementTypesPlatforms.map((platform) => {
            return (
              <FilterButton
                key={platform.name}
                name={platform.name}
                handleFilter={() => {
                  setAdvertisementTypesPlatformsFilter(platform);
                }}
                active={selectedAdvertisementTypesPlatformsFilter === platform}
              />
            );
          })}
        </Box>
      </Paper>
      <Grid container spacing={2.5} marginTop={1}>
        {format.advertisement_types
          .filter((advertisementType) => {
            if (selectedAdvertisementTypesPlatformsFilter === null) return true;
            else
              return advertisementType.advertisement_type.platforms
                .map((p) => p.name)
                .includes(selectedAdvertisementTypesPlatformsFilter.name);
          })
          .map((advertisementType) => (
            <Grid
              item
              key={advertisementType.advertisement_type.id}
              xs={12}
              md={6}
              lg={4}
              sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
            >
              <AdvertisementTypeCard advertisementType={advertisementType.advertisement_type} />
            </Grid>
          ))}
      </Grid>
    </>
  );
}

type FilterButtonProps = {
  name: string;
  handleFilter: () => void;
  active: boolean;
};
function FilterButton({ name, handleFilter, active }: FilterButtonProps) {
  return (
    <Button
      variant="text"
      sx={{ borderRadius: '12px', fontSize: '1rem', color: active ? 'white' : theme.palette.common.white }}
      onClick={handleFilter}
    >
      {name}
    </Button>
  );
}
