import { AdvertisementType } from '../Types/AdvertisementType';

const DEFAULT_IMAGE = [
  {
    width: 512,
    aspectRatio: 1.5,
    url: 'https://d2gjr4s3b17y3i.cloudfront.net/advertisement_type/17005038002e529f99-ae2a-4050-8a7e-efc42db84874_512.webp',
  },
] as AdvertisementType['images'];

export { DEFAULT_IMAGE };
