import { Box, Button } from '@mui/material';
import { theme } from '@/ts/theme';
import React from 'react';
import ContactDialog from '@/ts/Components/FormatDetail/ContactDialog';

export default function ContactButton() {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 40,
          right: 40,
        }}
      >
        <a href="mailto:marcel.kern@fuseint.com" style={{ textDecoration: 'none' }}>
          <Button
            variant="outlined"
            sx={{
              fontSize: '1rem',
              padding: '1.2rem 1.375rem',
              borderRadius: '22px',
              color: theme.palette.common.white,
              borderColor: theme.palette.common.white,
              ':hover': {
                borderColor: 'white',
                color: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
              },
            }}
            // onClick={() => setDialogOpen(true)}
          >
            Kontakt
          </Button>
        </a>
      </Box>
      <ContactDialog open={dialogOpen} setDialogOpen={setDialogOpen} />
    </>
  );
}
