import { Box, FormControl, Typography } from '@mui/material';
import FilterDropdown from '@/ts/Components/Formats/FilterDropdown';
import { theme } from '@/ts/theme';
import React from 'react';
import { FilterProps } from '@/ts/Pages/FormatListPage';

export default function MultiSelectFilter(props: FilterProps & { title: string; placeholder: string }) {
  return (
    <Box>
      <Typography color="primary" fontSize="0.8125rem" textTransform="uppercase" sx={{ mb: '6px' }}>
        {props.title}
      </Typography>
      <FormControl sx={{ width: '100%' }}>
        <FilterDropdown
          currentFilterOptions={props.selectedFilterOptions}
          availableFilterOptions={props.allFilterOptions}
          handleFilterOptionsSelect={props.handleFilterSelect}
          renderValue={(selected) => {
            if (selected.length === props.allFilterOptions.length || selected.length === 0) {
              return (
                <Typography
                  sx={{
                    color: 'rgba(255, 255, 255, 0.32)',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.placeholder}
                </Typography>
              );
            }
            return (
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  color: theme.palette.primary.main,
                }}
              >
                {selected.join(', ')}
              </Typography>
            );
          }}
          active={
            props.selectedFilterOptions.length !== props.allFilterOptions.length &&
            props.selectedFilterOptions.length !== 0
          }
        />
      </FormControl>
    </Box>
  );
}
