import { Autocomplete, Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { ReadablePlatform } from '@/ts/Types/Readable';
import { ContentCreator, FormContentCreator, contentCreatorDBToForm } from '@/ts/Types/ContentCreator';
import { Topic } from '@/ts/Types/Topic';
import ContentCreatorFormDialog from './ContentCreatorFormDialog';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

export default function ContentCreatorForm({
  contentCreatorFormState,
  setContentCreator,
  availableTopics,
  availableContentCreators,
  availablePlatforms,
  excludeContentCreatorId,
}: {
  contentCreatorFormState: FormContentCreator | null;
  setContentCreator: (contentCreator: FormContentCreator) => void;
  availableTopics: Topic[];
  availableContentCreators: ContentCreator[];
  availablePlatforms: ReadablePlatform[];
  excludeContentCreatorId?: string;
}) {
  const [contentCreatorNameInput, setContentCreatorNameInput] = React.useState<string | null>(null);
  const [dialogContentCreator, setDialogContentCreator] = React.useState<FormContentCreator | null>(null);
  const [showContentCreatorDialog, setShowContentCreatorDialog] = React.useState<boolean>(false);

  const selectedContentCreator = contentCreatorFormState
    ? availableContentCreators.find((aCC) => aCC.id === contentCreatorFormState.id)
    : undefined;

  const autocompleteOptions: { id: string; label: string }[] = useMemo(() => {
    return [
      {
        id: '-1',
        label: 'Auswählen',
      },
      ...availableContentCreators
        .filter((cc) => cc.id !== excludeContentCreatorId)
        .map((contentCreator) => ({
          id: contentCreator.id,
          label: contentCreator.name,
        })),
    ];
  }, [availableContentCreators]);

  const autocompleteValue = selectedContentCreator
    ? autocompleteOptions.find((option) => option.id === contentCreatorFormState?.id) ?? autocompleteOptions[0]
    : autocompleteOptions[0];

  function newContentCreator(name: string): FormContentCreator {
    return {
      id: null,
      name: name,
      main_platform: null,
      platforms: [],
      topics: [],
    };
  }

  return (
    <>
      <FormFieldBox>
        <Typography variant="subtitle2" fontSize="0.8125rem">
          Content Creator X
        </Typography>
        <Box display="flex" alignItems="center" gap={0.5}>
          <Autocomplete
            noOptionsText={'Keine Content Creators gefunden'}
            disablePortal
            disableClearable
            fullWidth
            inputValue={contentCreatorNameInput ?? ''}
            value={autocompleteValue}
            onInputChange={(event: any, newValue: string) => setContentCreatorNameInput(newValue)}
            onChange={(event: any, newValue: typeof autocompleteValue | null) => {
              let cC = newContentCreator('');
              if (newValue && newValue.id !== '-1') {
                const contentCreator = availableContentCreators.find((cc) => cc.id === newValue.id);
                if (contentCreator) {
                  cC = contentCreatorDBToForm(contentCreator);
                }
              }
              setContentCreator(cC);
            }}
            options={autocompleteOptions}
            getOptionLabel={(option) => option.label}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} fullWidth label="Name" />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={`${option.id}`}>
                  {option.label}
                </li>
              );
            }}
          />
          {selectedContentCreator && (
            <>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (!selectedContentCreator) return;
                    setDialogContentCreator(contentCreatorDBToForm(selectedContentCreator));
                    setShowContentCreatorDialog(true);
                  }}
                  title="Bearbeiten"
                  sx={{
                    borderRadius: '50%',
                    padding: '0.48rem',
                    minWidth: 'unset',
                    borderColor: 'currentcolor',
                    width: '1.75rem',
                    height: '1.75rem',
                  }}
                >
                  <EditIcon sx={{ width: '0.8rem' }} />
                </Button>
              </Box>
              <Box>
                <IconButton
                  title="Zurücksetzen"
                  onClick={() => {
                    setContentCreator(newContentCreator(''));
                  }}
                  sx={{
                    borderRadius: '50%',
                    padding: '0.48rem',
                    minWidth: 'unset',
                    width: '1.75rem',
                    height: '1.75rem',
                    color: '#FD7070',
                    border: '1px solid currentcolor',
                  }}
                >
                  <ClearIcon sx={{ width: '0.8rem' }} />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            setDialogContentCreator(newContentCreator(contentCreatorNameInput ?? ''));
            setShowContentCreatorDialog(true);
          }}
        >
          Neu erstellen
        </Button>
      </FormFieldBox>
      {showContentCreatorDialog && dialogContentCreator && (
        <ContentCreatorFormDialog
          contentCreator={dialogContentCreator}
          onUpdate={setContentCreator}
          onClose={() => setShowContentCreatorDialog(false)}
          availableTopics={availableTopics}
          availablePlatforms={availablePlatforms}
        />
      )}
    </>
  );
}

const FormFieldBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));
