import { FormContentCreator } from './Types/ContentCreator';
import { FormDate, FormState } from './Types/Format';

interface ValidationRule {
  isInValid: (fs: FormState) => boolean;
  error: (fs: FormState) => void;
}

interface ValidationRules {
  [key: string]: ValidationRule | ValidationRule[];
}

export function formStateIsValid(formState: FormState, isDraft = false): boolean {
  return getFormStateErrors(formState, isDraft).length === 0;
}

export type FormError = { field: string; message: string };

// Main validation function
// Topics can be empty

export function getFormStateErrors(formState: FormState, isDraft = false): FormError[] {
  const errors: FormError[] = [];

  const insertError = (field: string, message: string) => {
    errors.push({ field, message });
  };

  const validationRules: ValidationRules = {
    name: {
      isInValid: (fs: FormState) => fs.name.trim().length === 0,
      error: (fs: FormState) => insertError('name', 'Name ist erforderlich'),
    },
    description: {
      isInValid: (fs: FormState) => fs.description?.trim().length === 0,
      error: (fs: FormState) => insertError('description', 'Beschreibung ist erforderlich'),
    },
    shortDescription: {
      isInValid: (fs: FormState) => fs.shortDescription?.trim().length === 0,
      error: (fs: FormState) => insertError('shortDescription', 'Kurzbeschreibung ist erforderlich'),
    },
    images: {
      isInValid: (fs: FormState) => fs.images.length === 0 && fs.id === null,
      error: (fs: FormState) => insertError('images', 'Bild ist erforderlich'),
    },
    primaryFormatType: {
      isInValid: (fs: FormState) => fs.primaryFormatType === null,
      error: (fs: FormState) => insertError('primaryFormatType', 'Hauptformat ist erforderlich'),
    },
    platforms: {
      isInValid: (fs: FormState) => fs.platforms.length === 0,
      error: (fs: FormState) => insertError('platforms', 'Mindestens eine Plattform muss ausgewählt sein'),
    },
    language: {
      isInValid: (fs: FormState) => fs.language === null,
      error: (fs: FormState) => insertError('language', 'Sprache ist erforderlich'),
    },
    dates: {
      isInValid: (fs: FormState) => !datesAreValidForPosting(fs.dates) || fs.dates.length === 0,
      error: (fs: FormState) => {
        insertError('dates', 'Mindestens ein Datum ist ungültig');
        getDatesErrors(fs.dates).map((error) => insertError(error.field, error.message));
      },
    },
    host: [
      {
        isInValid: (fs: FormState) => fs.host === null,
        error: (fs: FormState) => insertError('host', 'Host ist erforderlich'),
      },
      {
        isInValid: (fs: FormState) => !contentCreatorIsValidForPosting(fs.host),
        error: (fs: FormState) => insertError('host', 'Host ist ungültig'),
      },
    ],
    estimatedUniqueViews: {
      isInValid: (fs: FormState) => fs.estimatedUniqueViews === null,
      error: (fs: FormState) => insertError('estimatedUniqueViews', 'Feld ist erforderlich'),
    },
    estimatedSocialMediaViews: {
      isInValid: (fs: FormState) => fs.estimatedSocialMediaViews === null,
      error: (fs: FormState) => insertError('estimatedSocialMediaViews', 'Feld ist erforderlich'),
    },
    estimatedTotalViews: {
      isInValid: (fs: FormState) => fs.estimatedTotalViews === null,
      error: (fs: FormState) => insertError('estimatedTotalViews', 'Feld ist erforderlich'),
    },
    targetAudienceMalePercentage: {
      isInValid: (fs: FormState) =>
        fs.targetAudienceMalePercentage === null || !isValidPercentage(fs.targetAudienceMalePercentage),
      error: (fs: FormState) => insertError('targetAudienceMalePercentage', 'Feld ist erforderlich'),
    },
    targetAudienceFemalePercentage: {
      isInValid: (fs: FormState) =>
        fs.targetAudienceFemalePercentage === null || !isValidPercentage(fs.targetAudienceFemalePercentage),
      error: (fs: FormState) => insertError('targetAudienceFemalePercentage', 'Feld ist erforderlich'),
    },
    targetAudienceDiversePercentage: {
      isInValid: (fs: FormState) =>
        fs.targetAudienceDiversePercentage === null || !isValidPercentage(fs.targetAudienceDiversePercentage),
      error: (fs: FormState) => insertError('targetAudienceDiversePercentage', 'Feld ist erforderlich'),
    },
    targetAudienceTo18Percentage: {
      isInValid: (fs: FormState) =>
        fs.targetAudienceTo18Percentage === null || !isValidPercentage(fs.targetAudienceTo18Percentage),
      error: (fs: FormState) => insertError('targetAudienceTo18Percentage', 'Feld ist erforderlich'),
    },
    targetAudienceTo24Percentage: {
      isInValid: (fs: FormState) =>
        fs.targetAudienceTo24Percentage === null || !isValidPercentage(fs.targetAudienceTo24Percentage),
      error: (fs: FormState) => insertError('targetAudienceTo24Percentage', 'Feld ist erforderlich'),
    },
    targetAudienceTo34Percentage: {
      isInValid: (fs: FormState) =>
        fs.targetAudienceTo34Percentage === null || !isValidPercentage(fs.targetAudienceTo34Percentage),
      error: (fs: FormState) => insertError('targetAudienceTo34Percentage', 'Feld ist erforderlich'),
    },
    targetAudienceTo44Percentage: {
      isInValid: (fs: FormState) =>
        fs.targetAudienceTo44Percentage === null || !isValidPercentage(fs.targetAudienceTo44Percentage),
      error: (fs: FormState) => insertError('targetAudienceTo44Percentage', 'Feld ist erforderlich'),
    },
    targetAudienceOver44Percentage: {
      isInValid: (fs: FormState) =>
        fs.targetAudienceOver44Percentage === null || !isValidPercentage(fs.targetAudienceOver44Percentage),
      error: (fs: FormState) => insertError('targetAudienceOver44Percentage', 'Feld ist erforderlich'),
    },
    targetAudienceCountries: [
      {
        isInValid: (fs: FormState) => fs.targetAudienceCountries?.length === 0,
        error: (fs: FormState) => insertError('targetAudienceCountries', 'Mindestens ein Zielland erforderlich'),
      },
      {
        isInValid: (fs: FormState) =>
          fs.targetAudienceCountries?.filter(
            (country) => country.percentage === null || !isValidPercentage(country.percentage),
          ).length !== 0,
        error: (fs: FormState) => insertError('targetAudienceCountries', 'Mindestens eine Prozentangabe ist falsch'),
      },
    ],
    minimumAdvertisingCost: {
      isInValid: (fs: FormState) => fs.minimumAdvertisingCost === null,
      error: (fs: FormState) => insertError('minimumAdvertisingCost', 'Feld ist erforderlich'),
    },
  };

  const validateField = (key: keyof FormState, rule: ValidationRule | ValidationRule[], formState: FormState) => {
    // Enhancing the check for drafts to consider non-empty strings and non-empty arrays as valid
    if (isDraft) {
      const value = formState[key];
      const isEmptyString = typeof value === 'string' && value.trim().length === 0;
      const isEmptyArray = Array.isArray(value) && value.length === 0;

      // Special case for 'name' field - always validate in draft mode
      if (key !== 'name') {
        // Skip validation if the field is not present, is an empty string, or an empty array
        if (value === undefined || value === null || isEmptyString || isEmptyArray) {
          return;
        }
      }
    }

    // Apply validation
    if (Array.isArray(rule)) {
      rule.forEach((subRule) => {
        if (subRule.isInValid(formState)) {
          subRule.error(formState);
        }
      });
    } else {
      if (rule.isInValid(formState)) {
        rule.error(formState);
      }
    }
  };

  // Iterate over each rule and apply validations
  Object.keys(validationRules).forEach((key) => {
    const rule = validationRules[key];

    validateField(key as keyof FormState, rule, formState);
  });

  return errors;
}

export function getDateErrors(formDate: FormDate): FormError[] {
  const errors = [];

  if (formDate.startingDate === null) errors.push({ field: 'startingDate', message: 'Datum ist erforderlich' });
  if (formDate.startingTime === null) errors.push({ field: 'startingTime', message: 'Uhrzeit ist erforderlich' });
  if (formDate.duration === null) errors.push({ field: 'duration', message: 'Dauer ist erforderlich' });
  if (formDate.duration !== null && !dateDurationIsValid(formDate.duration))
    errors.push({ field: 'duration', message: 'Dauer ist ungültig' });
  if (formDate.guests.filter((g) => g !== null && !contentCreatorIsValidForPosting(g)).length !== 0)
    errors.push({ field: 'guests', message: 'Mindestens ein Gast ist ungültig' });

  return errors;
}

function dateDurationIsValid(duration: string) {
  // Check if the duration is not empty
  if (duration.trim().length === 0) {
    return false;
  }

  // Split the duration into hours and minutes
  const splitDuration = duration.split(':');
  if (splitDuration.length !== 2) {
    return false; // Return false if there aren't exactly two parts
  }

  // Parse hours and minutes as base-10 integers
  const hours = parseInt(splitDuration[0], 10);
  const minutes = parseInt(splitDuration[1], 10);

  // Validate parsed hours and minutes
  if (isNaN(hours) || isNaN(minutes)) {
    return false; // Check for NaN values
  }

  // Check if hours and minutes are within their respective ranges
  if (hours < 0 || hours > 23 || minutes < 0 || minutes >= 60) {
    return false;
  }

  return true;
}

function getDatesErrors(dates: FormDate[]): FormError[] {
  const errors: FormError[] = [];
  dates.forEach((date, index) => {
    const dateErrors = getDateErrors(date);
    dateErrors.forEach((error) => {
      errors.push({ field: 'dates[' + index + '].' + error.field, message: error.message });
    });
  });
  return errors;
}

export function datesAreValidForPosting(dates: FormDate[]) {
  const invalidDatesArray = dates.map((date: FormDate, idx: number) => {
    const errors = getDateErrors(date);
    if (errors.length === 0) {
      return false;
    }
    return date;
  });
  const invalidDates = invalidDatesArray.filter(Boolean);

  return invalidDates.length === 0;
}

export function contentCreatorIsValidForPosting(contentCreator: FormContentCreator | null): boolean {
  if (!contentCreator) return false;
  if (contentCreator.name.trim().length === 0) return false;
  if (contentCreator.platforms.length === 0) return false;
  if (contentCreator.main_platform === null) return false;
  const invalidPlatforms = contentCreator.platforms.filter((p) => p.social_handle.trim().length === 0);
  if (invalidPlatforms.length !== 0) return false;
  if (contentCreator.platforms.find((p) => p.name === contentCreator.main_platform) === undefined) return false;
  return true;
}

function isValidPercentage(number: number): boolean {
  return number >= 0 && number <= 100;
}
