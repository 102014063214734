import { ArcElement, Chart as ChartJS } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { theme } from '@/ts/theme';

export default function GenderDistributionPieChart({
  male,
  female,
  diverse,
}: {
  male: number;
  female: number;
  diverse: number;
}) {
  ChartJS.register(ArcElement, ChartDataLabels);
  return (
    <Pie
      data={{
        labels: ['männlich', 'weiblich', 'divers'],
        datasets: [
          {
            label: 'Zielgruppenanteil',
            data: [male, female, diverse],
            backgroundColor: [theme.palette.info.light, theme.palette.error.light, theme.palette.warning.light],
            borderWidth: 0,
            hoverBackgroundColor: [theme.palette.info.main, theme.palette.error.main, theme.palette.warning.main],
          },
        ],
      }}
      options={{
        rotation: 90,
        plugins: {
          datalabels: {
            align: 'end',
            formatter: function (value) {
              return value + '%';
            },

            color: 'white',
            font: {
              family: 'Roboto',
              size: 16,
            },
          },
        },
      }}
    />
  );
}
