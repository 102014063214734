import { CreatorPlatformName } from '@/ts/Types/Platform';
import { Topic } from '@/ts/Types/Topic';

export type ContentCreator = {
  id: string;
  name: string;
  main_platform: CreatorPlatformName;
  platforms: ContentCreatorPlatform[];
  topics: { topic: Topic }[];
};

export type ContentCreatorForPosting = {
  id: string | null;
  name: string;
  main_platform: CreatorPlatformName;
  platforms: ContentCreatorPlatform[];
  topics: string[]; // IDs
};

export type FormContentCreator = {
  id: string | null;
  name: string;
  main_platform: CreatorPlatformName | null;
  platforms: ContentCreatorPlatform[];
  topics: string[];
};

export type ContentCreatorPlatform = {
  name: CreatorPlatformName;
  social_handle: string;
};

export function getPrimaryPlatform(contentCreator: ContentCreator) {
  const match = contentCreator.platforms.find((platform) => platform.name === contentCreator.main_platform);
  if (!match) throw new Error('Primary platform not found in platforms');
  return match;
}

export function contentCreatorDBToForm(creator: ContentCreator): FormContentCreator {
  return {
    id: creator.id,
    name: creator.name,
    main_platform: creator.main_platform,
    platforms: creator.platforms,
    topics: creator.topics.map((t) => t.topic.id),
  };
}

export function contentCreatorFormToDB(creator: FormContentCreator): ContentCreator {
  if (!creator.main_platform) throw new Error('Main platform must be set');
  if (!creator.id || creator.id === '-1') throw new Error('ID must be set');

  return {
    id: creator.id,
    name: creator.name,
    main_platform: creator.main_platform,
    platforms: creator.platforms,
    topics: creator.topics.map((t) => ({ topic: { id: t } as Topic })),
  };
}

// for ajax request, can be posted without id
export function contentCreatorFormToPosting(creator: FormContentCreator): ContentCreatorForPosting {
  if (!creator.main_platform) throw new Error('Main platform must be set');

  return {
    id: creator.id,
    name: creator.name,
    main_platform: creator.main_platform,
    platforms: creator.platforms,
    topics: creator.topics,
  };
}

export function isFormContentCreator(obj?: unknown): obj is FormContentCreator {
  if (typeof obj === 'object' && obj) {
    if (!('id' in obj) || (!obj.id && obj.id !== null)) return false;
    if (!('name' in obj) || !obj.name) return false;
    if (!('main_platform' in obj) || (!obj.main_platform && obj.main_platform !== null)) return false;
    if (!('platforms' in obj) || !obj.platforms || !(obj.platforms instanceof Array)) return false;

    // TODO: check main platform names as constants

    for (const platform of obj.platforms) {
      if (typeof platform !== 'object') return false;
      if (!('name' in platform) || !platform.name) return false;
      if (!('social_handle' in platform) || !platform.social_handle) return false;
    }

    if (!('topics' in obj) || !obj.topics || !(obj.topics instanceof Array)) return false;

    return true;
  }
  return false;
}
