import { Head } from '@inertiajs/react';

export default function Register() {
  return (
    <>
      <Head title="Register Success" />
      <h1>Registrierung erfolgreich.</h1>

      <p>Danke für deine Anmeldung bei Never Not New. Unsere Mitarbeitenden werden deine Anmeldung nun prüfen.</p>
    </>
  );
}
