export default function CloseContactDialog() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.6672 26.6665L20.0007 19.9999M20.0007 19.9999L13.334 13.3333M20.0007 19.9999L26.6673 13.3333M20.0007 19.9999L13.334 26.6666"
        stroke="white"
        strokeOpacity="0.72"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="19.5"
        stroke="white"
        strokeOpacity="0.72"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
