import { Box, Chip, Typography } from '@mui/material';
import { theme } from '@/ts/theme';
import React from 'react';
import Expand from '@/ts/Components/icons/Expand';
import ContentCreatorDetailDialog from '@/ts/Components/FormatDetail/ContentCreatorDetailDialog';
import { ContentCreator, getPrimaryPlatform } from '@/ts/Types/ContentCreator';
import { PlatformIcon } from '@/ts/Components/icons/Platform';
import { ContentCreatorData } from '@/ts/Types/ContentCreatorData';

export default function HostInformation({
  contentCreator,
  contentCreatorData,
}: {
  contentCreator: ContentCreator;
  contentCreatorData: ContentCreatorData[];
}) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const additionalPlatformData = contentCreator.platforms
    .map((platform) =>
      contentCreatorData.find((data) => data.platform === platform.name && data.handle === platform.social_handle),
    )
    .filter((item): item is ContentCreatorData => !!item);

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: 1 }}>
        Host
      </Typography>
      <Box
        sx={{
          borderRadius: '16px',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          padding: '20px',
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          position: 'relative',
        }}
      >
        <Box sx={{ height: 256, overflow: 'hidden', borderRadius: '16px', aspectRatio: 1, flexShrink: 0 }}>
          <img
            src={
              additionalPlatformData.find((data) => data.platform === contentCreator.main_platform)?.imageUrl ??
              'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
            }
            height="100%"
            width="auto"
            alt={'Host der Show: ' + contentCreator.name}
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box marginBottom="24px">
            <Typography sx={{ fontSize: '1.125rem', color: theme.palette.primary.main }}>
              {contentCreator.name}
            </Typography>
            <Typography variant="body1">@{getPrimaryPlatform(contentCreator).social_handle}</Typography>
          </Box>
          <Box marginBottom="48px" sx={{ display: 'flex', gap: '20px 40px', flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="subtitle2">Gesamtfollower</Typography>
              <Typography variant="body1">
                {additionalPlatformData.reduce((acc, current) => acc + current.followers, 0).toLocaleString()}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="subtitle2">Hauptplattform</Typography>
              <PlatformIcon
                name={getPrimaryPlatform(contentCreator).name}
                size={24}
                color={theme.palette.common.white}
              />
            </Box>
            {contentCreator.platforms.filter((platform) => platform.name != contentCreator.main_platform).length >
              0 && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="subtitle2">Weitere Plattformen</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  {contentCreator.platforms
                    .filter((platform) => platform.name != contentCreator.main_platform)
                    .map((platform) => {
                      return (
                        <PlatformIcon
                          key={platform.name}
                          name={platform.name}
                          size={24}
                          color={theme.palette.common.white}
                        />
                      );
                    })}
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="subtitle2">Themen</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {contentCreator.topics.map((topic) => {
                return <Chip key={topic.topic.name} label={'#' + topic.topic.name} variant="outlined" />;
              })}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ position: 'absolute', top: '18px', right: '18px', cursor: 'pointer' }}
          onClick={() => setDialogOpen(true)}
        >
          <Expand size={24} />
        </Box>
      </Box>
      <ContentCreatorDetailDialog
        open={dialogOpen}
        isHost={true}
        setDialogOpen={setDialogOpen}
        contentCreator={contentCreator}
        additionalPlatformData={additionalPlatformData}
      />
    </>
  );
}
