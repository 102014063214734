import DateForm from '@/ts/Components/Form/DateForm';
import { ContentCreator } from '@/ts/Types/ContentCreator';
import { FormDate, FormState } from '@/ts/Types/Format';
import { ReadablePlatform } from '@/ts/Types/Readable';
import { Topic } from '@/ts/Types/Topic';
import { theme } from '@/ts/theme';
import styled from '@emotion/styled';
import { Button, Paper } from '@mui/material';
import { useErrors } from './ErrorsContext';

export default function EpisodesForm({
  formState,
  setEpisodes,
  availableContentCreators,
  availablePlatforms,
  availableTopics,
}: {
  formState: FormState;
  setEpisodes: (episodes: FormDate[]) => void;
  availableContentCreators: ContentCreator[];
  availablePlatforms: ReadablePlatform[];
  availableTopics: Topic[];
}) {
  const { getError } = useErrors();
  const error = getError('dates');

  return (
    <>
      {formState.dates.map((episode, index) => {
        return (
          <SectionPaper key={index} hasError={!!error}>
            <DateForm
              hostId={formState.host?.id ?? undefined}
              formDate={episode}
              setDate={(date: FormDate) => {
                setEpisodes(formState.dates.map((d, i) => (i === index ? date : d)));
                // setEpisodes([...formState.dates.filter((d) => d !== episode), date]);
              }}
              deleteDate={() => setEpisodes(formState.dates.filter((d) => d != episode))}
              availableContentCreators={availableContentCreators}
              availablePlatforms={availablePlatforms}
              availableTopics={availableTopics}
              dateIndex={index}
            />
          </SectionPaper>
        );
      })}
      <Button
        variant="outlined"
        fullWidth
        onClick={() =>
          setEpisodes([
            ...formState.dates,
            {
              startingDate: null,
              startingTime: null,
              duration: null,
              guests: [],
            },
          ])
        }
      >
        Weitere Episode hinzufügen
      </Button>
    </>
  );
}

const SectionPaper = styled(Paper, { shouldForwardProp: (prop) => prop !== 'hasError' })<{ hasError?: boolean }>(
  (props) => ({
    padding: '1.75rem',
    borderRadius: '22px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '24px',
    marginBottom: '20px',
    border: '1px',
    borderStyle: 'solid',
    borderColor: props.hasError ? theme.palette.error.main : theme.palette.background.paper,
  }),
);
