export default function Edit({ size, color }: { size: number; color: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="white" strokeOpacity="0.72" fill="transparent" />
      <path
        d="M26.6228 19.9487L18.4879 29.7285C18.4432 29.7822 18.3787 29.8151 18.3094 29.8195L15.5088 29.9995C15.3711 30.0083 15.2516 29.9047 15.2396 29.766L15.001 27.0182C14.9951 26.9505 15.0161 26.8832 15.0594 26.8311L23.1966 17.0486M26.6228 19.9487L23.1966 17.0486M26.6228 19.9487L28.4395 17.7648L28.4435 17.7598C28.4947 17.693 29.8187 15.9297 28.1921 14.5528C26.5653 13.1758 25.0737 14.7976 25.0175 14.8598L25.0133 14.8647L23.1966 17.0486"
        stroke={color}
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
