import React, { FormEvent } from 'react';
import { Link, useForm } from '@inertiajs/react';
import { Alert, Box, Button, Container, TextField, Typography, css } from '@mui/material';
import Page from '../Components/Page';
import { PAGE } from '@/ts/Components/Header';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

export default function LoginPage() {
  const { data, setData, post, reset, processing, errors } = useForm({
    email: '',
    password: '',
  });

  const { t } = useTranslation('forms');

  function login(event: FormEvent) {
    event.preventDefault();
    post('/login', {
      preserveScroll: true,
      onError: () => {
        reset('password');
      },
    });
  }

  return (
    <Page title={'LOGIN'} activePage={PAGE.Other}>
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            {t('login')}
          </Typography>
          <Box component="form" onSubmit={login} noValidate sx={{ mt: 1 }}>
            <TextField
              value={data.email}
              onChange={(e) => setData('email', e.target.value)}
              error={errors.email != undefined}
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('email_label')}
              placeholder={t('email_label')}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              value={data.password}
              onChange={(e) => setData('password', e.target.value)}
              error={errors.password != undefined}
              margin="normal"
              required
              fullWidth
              id="password"
              label={t('password_label')}
              placeholder={t('password_label')}
              name="password"
              type="password"
              autoComplete="current-password"
            />
            {errors.email && (
              <h2>
                <Alert severity="error">{errors.email}</Alert>
              </h2>
            )}
            {errors.password && (
              <h2>
                <Alert severity="error">{errors.password}</Alert>
              </h2>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={processing}>
              {t('login')}
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <StyledLink href={'/forgot-password'}>{t('forgot_password_label')}</StyledLink>
            <StyledLink href={'/register'}>{t('register')}</StyledLink>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: rgba(255, 255, 255, 0.72);
    text-decoration: auto;
    font-weight: normal;

    &:hover {
      font-weight: bold;
    }
  `,
);
