import { NeverNotNewInput, theme } from '@/ts/theme';
import ArrowDownPrimary from '@/ts/Components/icons/ArrowDownPrimary';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CheckboxChecked from '@/ts/Components/icons/CheckboxChecked';
import CheckboxUnchecked from '@/ts/Components/icons/CheckboxUnchecked';
import React, { ReactNode } from 'react';
import ArrowDownSecondary from '@/ts/Components/icons/ArrowDownSecondary';

type FilterDropdownProps = {
  currentFilterOptions: string[];
  availableFilterOptions: string[];
  handleFilterOptionsSelect: (event: SelectChangeEvent<string[]>) => void;
  renderValue: (selected: string[]) => ReactNode;
  active: boolean;
};

export default function FilterDropdown({
  currentFilterOptions,
  availableFilterOptions,
  handleFilterOptionsSelect,
  renderValue,
  active,
}: FilterDropdownProps) {
  return (
    <Select
      multiple
      displayEmpty
      value={currentFilterOptions}
      renderValue={renderValue}
      onChange={handleFilterOptionsSelect}
      inputProps={{ 'aria-label': 'Without label' }}
      input={
        <NeverNotNewInput
          sx={{
            borderRadius: '12px',
            border: active ? '1px solid ' + theme.palette.primary.main : '1px solid ' + theme.palette.grey['500'],
          }}
        />
      }
      IconComponent={active ? ArrowDownPrimary : ArrowDownSecondary}
      MenuProps={{
        sx: {
          '& .MuiMenu-paper': {
            borderRadius: '12px',
            border: active ? '1px solid ' + theme.palette.primary.main : '1px solid ' + theme.palette.grey['500'],
          },
          '& .MuiMenuItem-root': {
            opacity: 0.32,
            transition: 'all 0.2s ease-in-out',
            paddingY: 1,
          },
          '& .MuiMenuItem-root:hover': {
            opacity: 0.72,
          },
          '& .MuiMenuItem-root:focus': {
            backgroundColor: theme.palette.grey['100'],
          },
          '& .Mui-selected': {
            opacity: 0.72,
          },
        },
      }}
    >
      {availableFilterOptions.map((filterOption) => {
        return (
          <MenuItem
            key={filterOption}
            value={filterOption}
            sx={{
              textTransform: 'capitalize',
              color: 'white',
              backgroundColor: theme.palette.grey['100'],
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>{filterOption}</div>
            {currentFilterOptions.includes(filterOption) ? <CheckboxChecked /> : <CheckboxUnchecked />}
          </MenuItem>
        );
      })}
    </Select>
  );
}
