import { FormFieldLabelBox, FormSectionHeadline, FormSectionPaper } from '@/ts/Pages/FormatFormPage';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

import { FormState, FormTargetAudienceCountry } from '@/ts/Types/Format';
import { ReadableCountry } from '@/ts/Types/Readable';
import { useErrors } from './ErrorsContext';

export default function TargetAudienceForm({
  availableCountries,
  formState,
  setMalePercentage,
  setFemalePercentage,
  setDiversePercentage,
  setCountries,
  setTo18Percentage,
  setTo24Percentage,
  setTo34Percentage,
  setTo44Percentage,
  setOver44Percentage,
}: {
  availableCountries: ReadableCountry[];
  formState: FormState;
  setMalePercentage: (percentage: number) => void;
  setFemalePercentage: (percentage: number) => void;
  setDiversePercentage: (percentage: number) => void;
  setCountries: (countries: FormTargetAudienceCountry[]) => void;
  setTo18Percentage: (percentage: number) => void;
  setTo24Percentage: (percentage: number) => void;
  setTo34Percentage: (percentage: number) => void;
  setTo44Percentage: (percentage: number) => void;
  setOver44Percentage: (percentage: number) => void;
}) {
  const { errors, getError } = useErrors();
  const tampError = getError('targetAudienceMalePercentage') || getError('target_audience_male_percentage');
  const tafpError = getError('targetAudienceFemalePercentage') || getError('target_audience_female_percentage');
  const tadpError = getError('targetAudienceDiversePercentage') || getError('target_audience_diverse_percentage');
  const tacError = getError('targetAudienceCountries') || getError('target_audience_countries');
  const tato18pError = getError('targetAudienceTo18Percentage') || getError('target_audience_age_range.0');
  const tato24pError = getError('targetAudienceTo24Percentage') || getError('target_audience_age_range.1');
  const tato34pError = getError('targetAudienceTo34Percentage') || getError('target_audience_age_range.2');
  const tato44pError = getError('targetAudienceTo44Percentage') || getError('target_audience_age_range.3');
  const tao44pError = getError('targetAudienceOver44Percentage') || getError('target_audience_age_range.4');
  const anyHasError =
    !!tampError ||
    !!tafpError ||
    !!tadpError ||
    !!tacError ||
    !!tato18pError ||
    !!tato24pError ||
    !!tato34pError ||
    !!tato44pError ||
    !!tao44pError;

  return (
    <>
      <FormSectionHeadline hasError={anyHasError} data-has-error={anyHasError}>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Zielgruppe' }} />
      </FormSectionHeadline>
      <FormSectionPaper hasError={anyHasError}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <FormFieldLabelBox>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              Männlich
            </Typography>
            <TextField
              hiddenLabel
              placeholder="0%"
              variant="outlined"
              value={formState.targetAudienceMalePercentage ?? ''}
              onChange={(event) =>
                (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) || event.target.value === ''
                  ? setMalePercentage(parseInt(event.target.value) ?? null)
                  : {}
              }
              fullWidth
              required
              type="number"
              error={!!tampError}
              helperText={tampError?.message}
            />
          </FormFieldLabelBox>
          <FormFieldLabelBox>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              Weiblich
            </Typography>
            <TextField
              hiddenLabel
              placeholder="0%"
              variant="outlined"
              value={formState.targetAudienceFemalePercentage ?? ''}
              onChange={(event) =>
                (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) || event.target.value === ''
                  ? setFemalePercentage(parseInt(event.target.value) ?? null)
                  : {}
              }
              fullWidth
              required
              type="number"
              error={!!tafpError}
              helperText={tafpError?.message}
            />
          </FormFieldLabelBox>
          <FormFieldLabelBox>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              Divers
            </Typography>
            <TextField
              hiddenLabel
              placeholder="0%"
              variant="outlined"
              value={formState.targetAudienceDiversePercentage ?? ''}
              onChange={(event) =>
                (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) || event.target.value === ''
                  ? setDiversePercentage(parseInt(event.target.value) ?? null)
                  : {}
              }
              fullWidth
              required
              type="number"
              error={!!tadpError}
              helperText={tadpError?.message}
            />
          </FormFieldLabelBox>
        </Box>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Länder' }} />
        <Countries availableCountries={availableCountries} setCountries={setCountries} formState={formState} />
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Altersgruppen' }} />
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <FormFieldLabelBox>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              Unter 18
            </Typography>
            <TextField
              hiddenLabel
              placeholder="0%"
              variant="outlined"
              value={formState.targetAudienceTo18Percentage ?? ''}
              onChange={(event) =>
                (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) || event.target.value === ''
                  ? setTo18Percentage(parseInt(event.target.value) ?? null)
                  : {}
              }
              fullWidth
              required
              type="number"
              error={!!tato18pError}
              helperText={tato18pError?.message}
            />
          </FormFieldLabelBox>
          <FormFieldLabelBox>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              18-24
            </Typography>
            <TextField
              hiddenLabel
              placeholder="0%"
              variant="outlined"
              value={formState.targetAudienceTo24Percentage ?? ''}
              onChange={(event) =>
                (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) || event.target.value === ''
                  ? setTo24Percentage(parseInt(event.target.value) ?? null)
                  : {}
              }
              fullWidth
              required
              type="number"
              error={!!tato24pError}
              helperText={tato24pError?.message}
            />
          </FormFieldLabelBox>
          <FormFieldLabelBox>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              25-34
            </Typography>
            <TextField
              hiddenLabel
              placeholder="0%"
              variant="outlined"
              value={formState.targetAudienceTo34Percentage ?? ''}
              onChange={(event) =>
                (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) || event.target.value === ''
                  ? setTo34Percentage(parseInt(event.target.value) ?? null)
                  : {}
              }
              fullWidth
              required
              type="number"
              error={!!tato34pError}
              helperText={tato34pError?.message}
            />
          </FormFieldLabelBox>
          <FormFieldLabelBox>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              35-44
            </Typography>
            <TextField
              hiddenLabel
              placeholder="0%"
              variant="outlined"
              value={formState.targetAudienceTo44Percentage ?? ''}
              onChange={(event) =>
                (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) || event.target.value === ''
                  ? setTo44Percentage(parseInt(event.target.value) ?? null)
                  : {}
              }
              fullWidth
              required
              type="number"
              error={!!tato44pError}
              helperText={tato44pError?.message}
            />
          </FormFieldLabelBox>
          <FormFieldLabelBox>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              45+
            </Typography>
            <TextField
              hiddenLabel
              placeholder="0%"
              variant="outlined"
              value={formState.targetAudienceOver44Percentage ?? ''}
              onChange={(event) =>
                (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) || event.target.value === ''
                  ? setOver44Percentage(parseInt(event.target.value) ?? null)
                  : {}
              }
              fullWidth
              required
              type="number"
              error={!!tao44pError}
              helperText={tao44pError?.message}
            />
          </FormFieldLabelBox>
        </Box>
      </FormSectionPaper>
    </>
  );
}

function Countries({
  formState,
  availableCountries,
  setCountries,
}: {
  formState: FormState;
  availableCountries: ReadableCountry[];
  setCountries: (countries: FormTargetAudienceCountry[]) => void;
}) {
  const { getError } = useErrors();
  const tacError = getError('targetAudienceCountries') || getError('target_audience_countries');

  const [newCountryFieldInputValue, setNewCountryFieldInputValue] = useState<string | null>(null);
  const [newCountryFieldValue, setNewCountryFieldValue] = useState<string | null>(null);

  function addCountry(event: any, newValue: string | null) {
    const countryCode = availableCountries.find((c) => c.readable === newValue)?.code;
    if (
      newValue != null &&
      countryCode != undefined &&
      !formState.targetAudienceCountries?.map((c) => c.country).includes(countryCode)
    ) {
      setCountries([...formState.targetAudienceCountries, { country: countryCode, percentage: null }]);
      setNewCountryFieldValue(null);
      setNewCountryFieldInputValue(null);
    }
  }

  function savePercentage(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    country: FormTargetAudienceCountry,
  ) {
    setCountries(
      formState.targetAudienceCountries?.map((targetAudienceCountry) => {
        if (targetAudienceCountry.country === country.country)
          return { country: targetAudienceCountry.country, percentage: parseInt(event.target.value) ?? null };
        else return targetAudienceCountry;
      }),
    );
  }
  return (
    <>
      {formState.targetAudienceCountries?.map((country) => {
        return (
          <Box key={country.country} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography flex={1} textAlign={'right'}>
              {availableCountries.find((c) => c.code === country.country)?.readable ?? 'upsi'}
            </Typography>
            <TextField
              hiddenLabel
              placeholder="0%"
              variant="outlined"
              value={country.percentage ?? ''}
              onChange={(event) =>
                (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) || event.target.value === ''
                  ? savePercentage(event, country)
                  : {}
              }
              fullWidth
              required
              type="number"
              sx={{ flex: 1 }}
            />
            <Button
              onClick={() =>
                setCountries(
                  formState.targetAudienceCountries?.filter((savedCountry) => savedCountry.country != country.country),
                )
              }
            >
              x
            </Button>
          </Box>
        );
      })}

      <Autocomplete
        noOptionsText={'Keine Länder gefunden'}
        disablePortal
        options={availableCountries
          .filter(
            (displayCountry) =>
              !formState.targetAudienceCountries
                ?.map((targetAudienceCountry) => targetAudienceCountry.country)
                .includes(displayCountry.code),
          )
          .map((country) => country.readable)}
        renderInput={(params) => (
          <TextField {...params} hiddenLabel placeholder="Land" error={!!tacError} helperText={tacError?.message} />
        )}
        value={newCountryFieldValue}
        inputValue={newCountryFieldInputValue ?? ''}
        onInputChange={(event, newInputValue) => {
          setNewCountryFieldInputValue(newInputValue);
        }}
        onChange={addCountry}
      />
    </>
  );
}
