import { Box, Typography } from '@mui/material';
import React from 'react';
import { theme } from '@/ts/theme';
import Expand from '@/ts/Components/icons/Expand';
import ContentCreatorDetailDialog from '@/ts/Components/FormatDetail/ContentCreatorDetailDialog';
import { ContentCreator, getPrimaryPlatform } from '@/ts/Types/ContentCreator';
import { ContentCreatorData } from '@/ts/Types/ContentCreatorData';

export default function GuestInformation({
  contentCreator,
  contentCreatorData,
}: {
  contentCreator: ContentCreator;
  contentCreatorData: ContentCreatorData[];
}) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const additionalPlatformData: ContentCreatorData[] = contentCreator.platforms
    .map((platform) =>
      contentCreatorData.find((data) => data.platform === platform.name && data.handle === platform.social_handle),
    )
    .filter((item): item is ContentCreatorData => !!item);

  return (
    <>
      <Box
        sx={{
          borderRadius: '16px',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          padding: '16px',
          display: 'flex',
          gap: '16px',
          alignItems: 'flex-end',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={() => setDialogOpen(true)}
      >
        <Box sx={{ height: 92, overflow: 'hidden', borderRadius: '8px', aspectRatio: 1, flexShrink: 0 }}>
          <img
            src={
              additionalPlatformData.find((data) => data.platform === contentCreator.main_platform)?.imageUrl ??
              'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
            }
            height="100%"
            width="auto"
            alt={'Gast der Show: ' + contentCreator.name}
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Typography sx={{ fontSize: '1.125rem', color: theme.palette.primary.main }}>
            {contentCreator.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              minWidth: 0,
            }}
          >
            @{getPrimaryPlatform(contentCreator).social_handle}
          </Typography>
        </Box>
        <Box sx={{ position: 'absolute', top: '14px', right: '14px' }}>
          <Expand size={20} />
        </Box>
      </Box>
      <ContentCreatorDetailDialog
        open={dialogOpen}
        isHost={false}
        setDialogOpen={setDialogOpen}
        contentCreator={contentCreator}
        additionalPlatformData={additionalPlatformData}
      />
    </>
  );
}
