import { useEffect, useState } from 'react';
import { useForm } from '@inertiajs/react';
import { PAGE } from '@/ts/Components/Header';
import Page from '@/ts/Components/Page';
import { Container, Box, Typography, TextField, Button, Alert } from '@mui/material';
import axios from 'axios';

const routeToPasswordStore = '/reset-password';

type UserFormState = {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export default function ResetPassword({ token, email }: UserFormState) {
  const { data, setData, post, processing, errors, reset } = useForm({
    token: token,
    email: email,
    password: '',
    password_confirmation: '',
  });

  useEffect(() => {
    return () => {
      reset('password', 'password_confirmation');
    };
  }, []);

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  return (
    <Page title={'Reset Password'} activePage={PAGE.Other}>
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h2" sx={{ lineHeight: 1 }}>
            Kennwort ändern
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ lineHeight: 1 }}>
            Gib dein neues Passwort ein:
          </Typography>
        </Box>
        <br />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <TextField
            label="E-Mail"
            variant="outlined"
            value={data.email}
            onChange={(e) => setData('email', e.target.value)}
            fullWidth
            required
            type="email"
          />
          <TextField
            label="Passwort"
            variant="outlined"
            value={data.password}
            onChange={(e) => setData('password', e.target.value)}
            fullWidth
            type="password"
          />
          <TextField
            label="Passwort Bestätigung"
            variant="outlined"
            value={data.password_confirmation}
            onChange={(e) => setData('password_confirmation', e.target.value)}
            fullWidth
            type="password"
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Button
            sx={{ mt: 2 }}
            disabled={!isValidInput(data)}
            variant="contained"
            onClick={() =>
              resetPassword(data)
                .then(() => {
                  setSuccessMessage(true);

                  reset('password', 'password_confirmation');

                  setTimeout(() => (window.location.href = '/login'), 5000);
                })
                .catch((error) => {
                  setErrorMessages(error);
                  setErrorMessages(error.response.data.message);
                })
            }
          >
            Ändern
          </Button>

          {successMessage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
              <Alert severity="success">
                {'Ihr Passwort wurde erfolgreich geändert. Sie können nun über die Anmeldung auf Ihr Konto zugreifen'}
              </Alert>
            </Box>
          )}
          {errorMessages && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
              <Alert severity="error">{errorMessages}</Alert>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
}

function isValidInput(inputData: UserFormState) {
  if (inputData.token.trim().length === 0) return false;
  if (inputData.email.trim().length === 0) return false;
  if (inputData.password.length === 0) return false;
  if (inputData.password !== inputData.password_confirmation) return false;
  return true;
}

const resetPassword = (inputData: UserFormState) => {
  const form = new FormData();
  form.append('token', inputData.token);
  form.append('email', inputData.email);
  form.append('password', inputData.password);
  form.append('password_confirmation', inputData.password_confirmation);

  return axios.post(routeToPasswordStore, form);
};
