import axios, { AxiosError } from 'axios';
import * as Types from '../Types';

export const postWebformat = async (
  data: FormData,
  callbackSuccess: (newWerbeformat: Types.Werbeformat) => void,
  callbackError: (error: AxiosError) => void,
) => {
  // if (formatFormState === FormatFormPageState.EDIT) {
  //   url += `/${data.id}`;
  //   formData.append('_method', 'put');
  // }
  try {
    const response = await axios({
      method: 'POST',
      data: data,
      url: '/api/admin/werbeformat',
    });

    const newWerbeformat = response.data as Types.Werbeformat;
    callbackSuccess(newWerbeformat);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 422) {
      callbackError(e);
    }
  }
};

export const putWebformat = async (
  data: FormData,
  slug: string,
  callbackSuccess: (newWerbeformat: Types.Werbeformat) => void,
  callbackError: (error: AxiosError) => void,
) => {
  try {
    const response = await axios({
      method: 'POST',
      data: data,
      url: '/api/admin/werbeformat/' + slug,
    });

    const newWerbeformat = response.data as Types.Werbeformat;
    callbackSuccess(newWerbeformat);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 422) {
      callbackError(e);
    }
  }
};
