import { AlphabeticalSortingIcon, GeneralSortingIcon, Sorting } from '@/ts/Components/Sorting';
import { theme } from '@/ts/theme';
import styled from '@emotion/styled';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type SortFilterBarProps = {
  nameSorting: Sorting;
  toggleNameSorting: () => void;
  dateSorting: Sorting;
  toggleDateSorting: () => void;
  filterFormatDrafts: boolean;
  setFilterFormatDrafts: (value: boolean) => void;
  isAuthenticated: boolean;
};

export default function SortBar({
  nameSorting,
  toggleNameSorting,
  dateSorting,
  toggleDateSorting,
  isAuthenticated,
  filterFormatDrafts,
  setFilterFormatDrafts,
}: SortFilterBarProps) {
  const { t } = useTranslation(['common', 'forms']);

  return (
    <Background>
      {!isAuthenticated ? (
        <Typography color="primary" fontSize="1.5rem" textTransform="uppercase">
          {t('sort_by', { ns: 'common' })}
        </Typography>
      ) : (
        <Box display="flex" gap={4} sx={{ flexWrap: 'wrap' }}>
          <Typography
            color={filterFormatDrafts ? 'primary' : 'white'}
            style={!filterFormatDrafts ? { opacity: 0.3, cursor: 'pointer' } : { cursor: 'pointer' }}
            fontSize="1.5rem"
            textTransform="uppercase"
            onClick={() => setFilterFormatDrafts(true)}
          >
            {t('draft_sort_title', { ns: 'forms' })}
          </Typography>

          <Typography
            color={filterFormatDrafts ? 'white' : 'primary'}
            style={filterFormatDrafts ? { opacity: 0.3, cursor: 'pointer' } : { cursor: 'pointer' }}
            fontSize="1.5rem"
            textTransform="uppercase"
            onClick={() => setFilterFormatDrafts(false)}
          >
            {t('published_sort_title', { ns: 'forms' })}
          </Typography>
        </Box>
      )}

      <Box display="flex" gap={2} sx={{ flexWrap: 'wrap' }}>
        <Button
          variant="outlined"
          endIcon={<AlphabeticalSortingIcon sorting={nameSorting} />}
          onClick={toggleNameSorting}
          sx={{
            borderRadius: 3,
            textTransform: 'uppercase',
            width: 220,
            lineHeight: 1.6,
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            border: '1px solid rgba(231, 253, 112, 0.8)',
            fontSize: '1rem',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          {t('name_label', { ns: 'forms' })}
        </Button>
        <Button
          variant="outlined"
          endIcon={<GeneralSortingIcon sorting={dateSorting} />}
          onClick={toggleDateSorting}
          sx={{
            borderRadius: 3,
            textTransform: 'uppercase',
            width: 220,
            lineHeight: 1.5,
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            border: '1px solid rgba(231, 253, 112, 0.8)',
            fontSize: '1rem',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          {t('start_date_label', { ns: 'forms' })}
        </Button>
      </Box>
    </Background>
  );
}
const Background = styled(Paper)`
  padding: 1rem 26px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  p {
    flex: 1;
  }
`;
