import { CategoryScale, Chart as ChartJS, Filler, LinearScale, LineElement, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { theme } from '@/ts/theme';

export default function AgeDistributionLineChart({ labels, percentages }: { labels: string[]; percentages: number[] }) {
  ChartJS.register(CategoryScale, LinearScale, PointElement, Filler, LineElement);
  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            suggestedMax: 100,
            grid: {
              color: 'rgba(255, 255, 255, 0.12)',
            },
            ticks: {
              stepSize: 25,
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: theme.palette.common.white,
              font: {
                family: 'Roboto',
                size: 14,
              },
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            label: 'Zielgruppenanteil',
            data: percentages,
            backgroundColor: '#D9D9D9',
            borderWidth: 0,
            fill: {
              target: true,
              above: theme.palette.secondary.main,
            },
          },
        ],
      }}
    />
  );
}
