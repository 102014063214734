import { PAGE } from '@/ts/Components/Header';
import Page from '@/ts/Components/Page';

import { ReadablePlatform } from '@/ts/Types/Readable';
import { usePage } from '@inertiajs/react';
import { Button, Container, Grid } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { formIsValid, getFormErrors } from './validateForm';
import * as API from './Api';
import * as Types from './Types';

import { BackendErrorMap } from '@/ts/Components/Form/ErrorsContext';

import WebformatForm from './Components/Form';
import { User } from '@/ts/Types/User';

const title = 'Werbeformat anlegen';

const WERBEFORMAT_INIT = {
  id: '',
  name: '',
  slug: '',
  description: '',
  created_at: '',
  updated_at: '',
  images: [],
  oldImages: [],
  platforms: [],
} as unknown as Types.Werbeformat;

const formatDataCurrentImagesToOld = (advertisementType: Types.Werbeformat) => {
  const addPreview = (image: Types.Werbeformat['images'][number]) => {
    return {
      ...image,
      preview: (image as any).url,
    };
  };

  return {
    ...advertisementType,
    // oldImages: advertisementType?.images || [],
    oldImages: [],
    images: advertisementType?.images.map(addPreview) || [],
  };
};

export default function WebformatManagement() {
  const [publishingInProgress, setPublishingInProgress] = useState(false);
  const [errorMessages, setErrorMessages] = useState<Types.errorMessages>([]);

  const { contentPlatforms, advertisementType, user } = usePage<{
    contentPlatforms: ReadablePlatform[];
    user: User;
    advertisementType: Types.Werbeformat;
  }>().props;

  const onCreate = !advertisementType;

  const [form, setForm] = useState<{ data: Types.Werbeformat; valid: boolean }>({
    data: advertisementType ? (formatDataCurrentImagesToOld(advertisementType) as any) : WERBEFORMAT_INIT,
    valid: false,
  });

  async function saveOrUpdateFormat() {
    setPublishingInProgress(true);

    const callbackError = (error: any) => {
      const errorToStore = Object.entries(error.response.data.errors as BackendErrorMap).map(([field, messages]) => ({
        field: field,
        message: messages.join(', '),
      }));

      if (errorToStore) setErrorMessages(errorToStore);
      if (errorToStore) setPublishingInProgress(false);
    };

    // const callbackSuccess = () => (window.location.href = '/advertisement-types');
    const callbackSuccess = (newWerbeformat: Types.Werbeformat) =>
      (window.location.href = '/advertisement-types/' + newWerbeformat.slug);

    const formData = getFormData(form.data);
    if (onCreate) {
      API.postWebformat(formData, callbackSuccess, callbackError);
    } else {
      API.putWebformat(formData, advertisementType.slug, callbackSuccess, callbackError);
    }
  }

  const _onSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (publishingInProgress) return;
    if (!form.valid) {
      setErrorMessages(getFormErrors(form.data));
      return;
    }
    saveOrUpdateFormat();
  };

  useEffect(() => {
    setForm({ ...form, valid: formIsValid(form.data) });
    if (errorMessages.length > 0) setErrorMessages(getFormErrors(form.data));
  }, [form.data]);

  return (
    <Page title={title} activePage={PAGE.AdvertisementTypes} user={user}>
      <Container disableGutters maxWidth="xl" sx={{ position: 'relative' }}>
        <WebformatForm
          data={form.data}
          onDataChange={(data) => setForm({ data: data, valid: false })}
          errorMessages={errorMessages}
          contentPlatforms={contentPlatforms}
        >
          <Grid item xs={8}>
            <Grid container spacing={2} columns={2}>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    paddingY: 1.5,
                    width: 1,
                    borderRadius: 2,
                    ...(!form.valid ? { backgroundColor: 'gray' } : {}),
                  }}
                  onClick={_onSubmit}
                  // style={!form.valid ? { backgroundColor: 'gray', color: 'white', opacity: 0.3 } : {}}
                  disabled={publishingInProgress}
                >
                  Werbeformat speichern
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </WebformatForm>
      </Container>
    </Page>
  );
}

export function getFormData(data: Types.Werbeformat): FormData {
  const form = new FormData();

  if (data.name) form.append('name', data.name);
  if (data.description) form.append('description', data.description);
  if (data.slug) form.append('slug', data.slug);

  if (data.id) form.append('id', data.id.toString());

  // if (data.images) data.images.map((image, index) => form.append('images[' + index + ']', image));

  let oldImagesIndex = 0;

  if (data.oldImages) {
    data.oldImages.map((oldImage, index) => {
      form.append('old_images[' + index + '][deleted]', oldImage.deleted ? '1' : '0');
      if (!oldImage.deleted) {
        form.append('old_images[' + index + '][new_index]', oldImagesIndex.toString());
        oldImagesIndex++;
      }
    });
  }

  if (data.images) {
    data.images?.map((image, index) => {
      form.append('images[' + index + ']', image);
    });
  }

  if (data.platforms)
    data.platforms.map((platform: Types.Werbeformat['platforms'][number]) =>
      form.append('list_of_platforms[]', platform.name),
    );

  return form;
}
