import BreadcrumbsNavigation from '@/ts/Components/BreadcrumbsNavigation';
import Page from '@/ts/Components/Page';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Link, usePage } from '@inertiajs/react';
import { ROLE_ADMIN, ROLE_CREATOR_MANAGEMENT, User } from '@/ts/Types/User';
import { PAGE } from '@/ts/Components/Header';
import { AdvertisementType } from '@/ts/Types/AdvertisementType';
import { getImageSourceSet, getLargestImage } from '@/ts/Types/Image';
import { theme } from '@/ts/theme';
import { PlatformIcon } from '@/ts/Components/icons/Platform';
import { DEFAULT_IMAGE } from './utils';
import axios from 'axios';
import ConfirmDialog from '../Components/Form/ConfirmDialog';

const ENPOINTS = {
  delete: (slug: string) => '/api/admin/werbeformat/' + slug,
  edit: (slug: string) => '/werbeformats/' + slug + '/edit',
  werbeformateList: '/advertisement-types',
};

export default function AdvertisementTypeDetailPage() {
  const { advertisementType, user } = usePage<{
    advertisementType: AdvertisementType;
    user: User;
  }>().props;

  const images = advertisementType.images.length > 0 ? advertisementType.images : DEFAULT_IMAGE;

  return (
    <Page user={user} title={'Werbeformat: ' + advertisementType.name} activePage={PAGE.AdvertisementTypes}>
      <BreadcrumbsNavigation
        previousPages={[{ name: 'Werbeformate', url: ENPOINTS.werbeformateList }]}
        currentPage={advertisementType.name}
      />
      <Container disableGutters maxWidth="xl" sx={{ position: 'relative' }}>
        <Grid sx={{ marginTop: 15 }} rowSpacing="80px" columnSpacing="20px" container direction="row-reverse">
          <Grid item md={8}>
            <Box sx={{ width: 1, borderRadius: 5.5, overflow: 'hidden', aspectRatio: 16 / 9 }}>
              <img
                alt=""
                srcSet={getImageSourceSet(images)}
                src={getLargestImage(images)?.url}
                style={{ objectFit: 'cover', height: '100%', width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, justifyContent: 'flex-end' }}>
            <Typography variant="h1" color={theme.palette.primary.main}>
              {advertisementType.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2.5 }}>
              {advertisementType.platforms.map((platform) => (
                <PlatformIcon key={platform.name} name={platform.name} size={32} />
              ))}
            </Box>
            <Typography>{advertisementType.description}</Typography>
          </Grid>
        </Grid>

        {(user.role === ROLE_CREATOR_MANAGEMENT || user.role === ROLE_ADMIN) && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 40,
              right: 40,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Link href={ENPOINTS.edit(advertisementType.slug)}>
              <Button
                variant="outlined"
                sx={{
                  fontSize: '1rem',
                  padding: '1.2rem 1.375rem',
                  borderRadius: '22px',
                  color: theme.palette.common.white,
                  borderColor: theme.palette.common.white,
                  ':hover': {
                    borderColor: 'white',
                    color: 'white',
                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  },
                }}
              >
                Bearbeiten
              </Button>
            </Link>

            <ConfirmDialog
              dialogTitle="Werbeformat löschen"
              button={
                <Button
                  variant="outlined"
                  sx={{
                    fontSize: '1rem',
                    padding: '1.2rem 1.375rem',
                    borderRadius: '22px',
                    color: theme.palette.error.light,
                    borderColor: theme.palette.error.light,
                    ':hover': {
                      borderColor: theme.palette.error.main,
                      color: theme.palette.error.main,
                      backgroundColor: 'rgba(255, 255, 255, 0.04)',
                    },
                  }}
                  onClick={() => {
                    axios
                      .delete(ENPOINTS.delete(advertisementType.slug))
                      .then(() => (window.location.href = ENPOINTS.werbeformateList));
                  }}
                >
                  Löschen
                </Button>
              }
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
