import { useEffect, useState } from 'react';
import { useForm } from '@inertiajs/react';
import { Box, Typography, TextField, Button, Container, Alert } from '@mui/material';
import Page from '@/ts/Components/Page';
import { PAGE } from '@/ts/Components/Header';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const routeToRegister = '/register';

type UserFormState = {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export default function Register() {
  const { data, setData, reset, errors } = useForm<UserFormState>({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
  });

  const { t } = useTranslation('forms');

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  useEffect(() => {
    return () => {
      reset('password', 'password_confirmation');
    };
  }, []);

  useEffect(() => {
    // setSuccessMessage(false)
    setErrorMessages(null);
  }, [data]);

  return (
    <Page title={'Register'} activePage={PAGE.Other}>
      <Container maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h2" sx={{ lineHeight: 1 }}>
            {t('register_title')}
          </Typography>
        </Box>
        <br />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <TextField
            label={t('name_label')}
            variant="outlined"
            value={data.name}
            onChange={(e) => setData('name', e.target.value)}
            fullWidth
            required
          />
          <TextField
            label={t('email_label')}
            variant="outlined"
            value={data.email}
            onChange={(e) => setData('email', e.target.value)}
            fullWidth
            required
            type="email"
          />
          <TextField
            label={t('password_label')}
            variant="outlined"
            value={data.password}
            onChange={(e) => setData('password', e.target.value)}
            fullWidth
            type="password"
          />
          <TextField
            label={t('password_confirmation_label')}
            variant="outlined"
            value={data.password_confirmation}
            onChange={(e) => setData('password_confirmation', e.target.value)}
            fullWidth
            type="password"
          />

          <Button
            disabled={!isValidInput(data)}
            variant="contained"
            onClick={() =>
              registerUser(data)
                .then(() => {
                  setSuccessMessage(true);
                  reset('name', 'email', 'password', 'password_confirmation');
                })
                .catch((error) => {
                  setErrorMessages(error);
                  setErrorMessages(error.response.data.message);
                })
            }
          >
            {t('register')}
          </Button>
        </Box>

        {successMessage && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
            <Alert severity="success">{t('register_success')}</Alert>
          </Box>
        )}
        {errorMessages && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
            <Alert severity="error">{errorMessages}</Alert>
          </Box>
        )}
      </Container>
    </Page>
  );
}

function isValidInput(inputData: UserFormState) {
  if (inputData.name.trim().length === 0) return false;
  if (inputData.email.trim().length === 0) return false;
  if (inputData.password.length === 0) return false;
  if (inputData.password !== inputData.password_confirmation) return false;
  return true;
}

const registerUser = (inputData: UserFormState) => {
  const form = new FormData();
  form.append('name', inputData.name);
  form.append('email', inputData.email);
  form.append('password', inputData.password);
  form.append('password_confirmation', inputData.password_confirmation);

  return axios.post(routeToRegister, form);
};
