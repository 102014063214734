export default {
  name_label: 'Name',
  email_label: 'E-Mail Adresse',
  password_label: 'Passwort',
  start_date_label: 'Startdatum',
  password_confirmation_label: 'Passwort Bestätigung',
  forgot_password_label: 'Passwort vergessen',
  register: 'Registrieren',
  register_title: 'Registrierung',
  login: 'Log In',
  submit: 'Abschicken',
  forgot_password_title: 'Passwort vergessen?',
  forgot_password_description:
    'Kein Problem. Teilen Sie uns einfach Ihre E-Mail-Adresse mit und wir senden Ihnen per E-Mail einen Link zum Zurücksetzen Ihres Passworts, mit dem Sie ein neues Passwort auswählen können.',
  forgot_password_success:
    'Sollten Sie zu dieser E-Mail-Adresse einen Account haben, erhalten Sie gleich eine E-Mail zum Zurücksetzen Ihres Passworts.',
  register_success:
    'Die Registrierung wurde erfolgreich abgeschlossen. Sie erhalten eine E-Mail, wenn das Team sie genehmigt.',
  publish: 'Publizieren',
  save_as_draft: 'Als Entwurf speichern',
  update: 'Aktualisieren',
  update_draft: 'Entwurf aktualisieren',
  draft_sort_title: 'Entwurf',
  published_sort_title: 'Publizierte',
};
