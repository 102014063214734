import { User, UserRole, ROLE_ADMIN, ROLE_CREATOR_MANAGEMENT, ROLE_ADVERTISER } from "@/ts/Types/User";
import { useForm } from "@inertiajs/react";
import { Dialog, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { createUser, updateUser, approveUser, deleteUser, approveRequests, rejectUser} from "../api";
import { UserFormState } from "../types";



const ApprovalModal = ({ open, setOpen, user }: { open: boolean; setOpen: (open: boolean) => void; user: User }) => {
    const initData: UserFormState = {
          id: user.id,
          email: user.email,
          name: user.name,
          password: '',
          role: user.role,
        };

    const { data, setData } = useForm<UserFormState>(initData);

    return (
      <Dialog
        // fullWidth
        open={open}
        maxWidth="md"
        onClose={() => setOpen(false)}
        sx={{
          backdropFilter: 'blur(5px)',
          borderRadius: '22px',
        }}
        PaperProps={{
          sx: {
            padding: '1.75rem',
            borderRadius: '22px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1.75rem',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h2" sx={{ lineHeight: 1 }}>
            Den Benutzer genehmigen
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
          <FormControl fullWidth>
            <InputLabel>Rolle</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.role}
              label="Age"
              onChange={(e) => setData('role', e.target.value as UserRole)}
            >
              <MenuItem value={ROLE_ADMIN}>Administrator</MenuItem>
              <MenuItem value={ROLE_CREATOR_MANAGEMENT}>Content Creator Management</MenuItem>
              <MenuItem value={ROLE_ADVERTISER}>Werbetreibender</MenuItem>
            </Select>
          </FormControl>
          <Button
            sx={{ marginTop: 3 }}
            disabled={!isValidInput(data)}
            variant="contained"
            onClick={() => approveRequests(user, data)}
          >
            Genehmigen
          </Button>
          <Button
            disabled={!isValidInput(data)}
            variant="text"
            onClick={() => rejectUser(user)}
          >
            Ablehnen
          </Button>
        </Box>
      </Dialog>
    );
  }

  function isValidInput(inputData: UserFormState) {
    if (inputData.name.trim().length === 0) return false;
    if (inputData.email.trim().length === 0) return false;
    if (inputData.id === null && inputData.password.length === 0) return false;
    if (inputData.role === null) return false;
    return true;
  }




  export default ApprovalModal;