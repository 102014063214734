import { Box, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

export default function PlatformChip({ platform, icon }: { platform: string; icon: ReactElement }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          paddingY: 1,
          paddingX: 1.5,
          borderRadius: '12px',
          border: '1px solid #9747FF',
        }}
      >
        {icon}
        <Typography sx={{ textTransform: 'uppercase', color: '#9747FF', fontSize: '1rem' }}>{platform}</Typography>
      </Box>
    </Box>
  );
}
