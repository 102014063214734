import { FormSectionHeadline, FormSectionPaper } from '@/ts/Pages/FormatFormPage';
import { AdvertisementType } from '@/ts/Types/AdvertisementType';
import { FormState } from '@/ts/Types/Format';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React from 'react';

export default function AdvertisementTypesForm({
  availableAdvertisementTypes,
  formState,
  setAdvertisementTypes,
}: {
  availableAdvertisementTypes: AdvertisementType[];
  formState: FormState;
  setAdvertisementTypes: (advertisementTypes: string[]) => void;
}) {
  function handleAdvertisementTypeSelection(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) setAdvertisementTypes([...formState.advertisementTypes, event.target.name]);
    else setAdvertisementTypes(formState.advertisementTypes.filter((value) => value != event.target.name));
  }

  return (
    <>
      <FormSectionHeadline>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Verfügbare Werbeintegrationen' }} />
      </FormSectionHeadline>
      <FormSectionPaper>
        <FormGroup row>
          {availableAdvertisementTypes.map((advertisementType) => {
            return (
              <FormControlLabel
                key={advertisementType.id}
                value={advertisementType.id}
                control={
                  <Checkbox
                    checked={formState.advertisementTypes.includes(advertisementType.id)}
                    onChange={handleAdvertisementTypeSelection}
                    name={advertisementType.id}
                  />
                }
                label={advertisementType.name}
              />
            );
          })}
        </FormGroup>
      </FormSectionPaper>
    </>
  );
}
