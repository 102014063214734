import { Box, createTheme, InputBase, PaletteMode } from '@mui/material';
import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';

const colorPalette = {
  mode: 'dark' as PaletteMode,
  background: {
    default: '#000000',
    paper: '#121212',
  },
  primary: {
    main: 'rgba(231, 253, 112, 0.8)',
    light: 'rgb(218, 253, 5)',
  },
  secondary: {
    main: '#8258EC',
  },
  warning: {
    main: 'rgba(236, 203, 88, 1)',
    light: 'rgba(236, 203, 88, 0.8)',
  },
  error: {
    main: 'rgba(236, 88, 88, 1)',
    light: 'rgba(236, 88, 88, 0.8)',
  },
  info: {
    main: 'rgba(88, 103, 236, 1)',
    light: 'rgba(88, 103, 236, 0.8)',
  },
  grey: {
    500: 'rgba(255, 255, 255, 0.32)',
    100: '#131313',
    50: '#121212',
  },
  common: {
    white: 'rgba(255, 255, 255, 0.72)',
  },
};

export const theme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    body1: {
      color: colorPalette.common.white,
      fontSize: '1.125rem',
      lineHeight: 1.5,
    },
    body2: {
      color: colorPalette.common.white,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    h1: {
      color: 'white',
      textTransform: 'uppercase',
      fontWeight: 400,
      lineHeight: '120%',
      fontSize: '2.5rem',
      marginTop: 36,
      marginBottom: 36,
    },
    h2: {
      color: colorPalette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 400,
      lineHeight: '120%',
      fontSize: '2.125rem',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
      '@media (min-width:900px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:1536px)': {
        fontSize: '3.5rem',
      },
    },
    subtitle1: {
      color: colorPalette.primary.main,
      fontSize: '1.25rem',
      textTransform: 'uppercase',
      fontWeight: 400,
      lineHeight: '1rem',
    },
    subtitle2: {
      color: colorPalette.primary.main,
      fontSize: '1rem',
      textTransform: 'uppercase',
      fontWeight: 400,
      lineHeight: 1,
    },
    h3: {
      color: colorPalette.primary.main,
      fontSize: '1.5rem',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    h4: {
      color: colorPalette.primary.main,
      fontSize: '1.5rem',
      lineHeight: 1,
      textTransform: 'uppercase',
      fontWeight: 400,
    },
  },
  palette: colorPalette,
  components: {
    MuiAutocomplete: {
      defaultProps: {
        clearIcon: (
          <Close
            sx={{
              fill: colorPalette.error.main,
            }}
          />
        ),
      },
      styleOverrides: {
        root: {
          '& :hover': {
            color: colorPalette.common.white,
          },
        },
        popupIndicator: {
          color: 'inherit',
        },
        popupIndicatorOpen: {
          color: 'inherit',
        },
        clearIndicator: {
          color: 'inherit',
        },
        noOptions: {
          color: colorPalette.grey['500'],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: colorPalette.common.white,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colorPalette.grey['500'],
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colorPalette.grey['500'],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          color: colorPalette.grey['500'],
          '&::placeholder': {
            color: colorPalette.grey['500'],
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colorPalette.common.white,
            },
          },
        },
        input: {
          color: colorPalette.common.white,
        },
        notchedOutline: {
          borderColor: colorPalette.grey['500'],
          borderWidth: '1px',
          color: colorPalette.grey['500'],
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          height: '100%',
        },
        root: {
          button: {
            textTransform: 'none',
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: (
          <Box
            sx={{
              color: colorPalette.error.main,
              border: `1px solid ${colorPalette.error.main}`,
              borderRadius: '50%',
              width: '1.25rem',
              height: '1.25rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Close
              sx={{
                fill: colorPalette.error.main,
                width: '80%',
                height: '80%',
              }}
            />
          </Box>
        ),
      },
      styleOverrides: {
        root: {
          color: colorPalette.common.white,
          fontSize: '1rem',
          lineHeight: 1.125,
          borderColor: colorPalette.common.white,
          borderRadius: '50%',
          borderWidth: '1px',
          height: 'unset',

          '& .MuiChip-label': {
            padding: '0.1875rem 0.625rem',
          },

          '& .MuiChip-deleteIcon': {
            color: 'inherit',
          },
          '& .MuiChip-deleteIcon:hover': {
            color: 'inherit',
          },
        },
      },
    },
  },
});

export const NeverNotNewInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    minWidth: 220,
    backgroundColor: 'transparent',
    zIndex: 2,
    padding: 16,
    '&:focus': {
      borderRadius: 12,
    },
    '& .MuiTypography-root': {
      fontSize: '1rem',
    },
  },
  '& .MuiSvgIcon-root': {
    position: 'absolute',
    right: 16,
    zIndex: 1,
  },
}));
