import { Topic } from '@/ts/Types/Topic';
import { theme } from '@/ts/theme';
import { Close } from '@mui/icons-material';
import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';

export default function TopicsSelector({
  availableTopics,
  selectedTopics,
  setSelectedTopics,
}: {
  availableTopics: Topic[];
  selectedTopics: string[];
  setSelectedTopics: (topics: string[]) => void;
}) {
  return (
    <Autocomplete
      noOptionsText={'Keine Themen gefunden'}
      multiple
      options={availableTopics}
      value={availableTopics.filter((topic) => selectedTopics.includes(topic.id))}
      onChange={(event: any, newTopics: Topic[]) => setSelectedTopics(newTopics.map((topic) => topic.id))}
      getOptionLabel={(topic) => topic.name}
      renderInput={(params) => <TextField {...params} variant="outlined" hiddenLabel placeholder="Themen" />}
    />
  );
}
