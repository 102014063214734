import { Typography } from '@mui/material';
import React from 'react';

export default function ReachInformation({ name, value }: { name: string; value: string }) {
  return (
    <>
      <Typography variant="subtitle2">{name}</Typography>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: {
            lg: '6rem',
            xs: '5rem',
          },
          lineHeight: 1,
          color: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        {value}
      </Typography>
    </>
  );
}
