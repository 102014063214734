import { SvgIcon } from '@mui/material';

export default function SortingNone() {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24">
      <path
        d="M18.1327 17.05L17.3451 17.8951M17.3451 17.8951C17.2799 17.965 17.1946 17.9999 17.1092 18M17.3451 17.8951L21 13.9738M16.0857 17.05L16.8728 17.8951M16.8728 17.8951C16.9381 17.9651 17.0236 18.0001 17.1092 18M16.8728 17.8951L13.2182 13.9738M17.1092 18V6M7.91444 6.95003L7.12683 6.10495M7.12683 6.10495C7.06169 6.03505 6.97632 6.00006 6.89093 6M7.12683 6.10495L10.7818 10.0262M5.86743 6.95003L6.65457 6.10495M6.65457 6.10495C6.71984 6.03492 6.80539 5.99994 6.89093 6M6.65457 6.10495L3 10.0262M6.89093 6V18"
        stroke="#E7FD70"
        strokeOpacity="0.8"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
