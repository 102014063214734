import { FormSectionHeadline, FormSectionPaper } from '@/ts/Pages/FormatFormPage';
import { FormState } from '@/ts/Types/Format';
import { ReadableFormatType } from '@/ts/Types/Readable';
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { useErrors } from './ErrorsContext';

export default function FormatTypesForm({
  availableFormatTypes,
  formState,
  setPrimaryFormatType,
  setSecondaryFormatTypes,
}: {
  availableFormatTypes: ReadableFormatType[];
  formState: FormState;
  setPrimaryFormatType: (formatType: string) => void;
  setSecondaryFormatTypes: (formatTypes: string[]) => void;
}) {
  const { getError } = useErrors();
  const error = getError('primaryFormatType') || getError('secondaryFormatTypes') || getError('format_types');

  function handleSecondaryFormatTypeSelection(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) setSecondaryFormatTypes([...formState.secondaryFormatTypes, event.target.name]);
    else setSecondaryFormatTypes(formState.secondaryFormatTypes.filter((value) => value != event.target.name));
  }

  return (
    <>
      <FormSectionHeadline hasError={!!error} data-has-error={!!error}>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Formattyp' }} />
      </FormSectionHeadline>
      <FormSectionPaper hasError={!!error}>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Primärer Formattyp' }} />
        <RadioGroup
          row
          value={formState.primaryFormatType}
          onChange={(event) => setPrimaryFormatType(event.target.value)}
        >
          {availableFormatTypes.map((formatType) => {
            return (
              <FormControlLabel
                key={formatType.name}
                value={formatType.name}
                control={<Radio />}
                label={formatType.readable}
              />
            );
          })}
        </RadioGroup>
        {!!error && <Typography color="error">{error.message}</Typography>}
      </FormSectionPaper>
      {formState.primaryFormatType != null && (
        <FormSectionPaper>
          <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Sekundäre Formattypen' }} />
          <FormGroup row>
            {availableFormatTypes
              .filter((formatType) => formatType.name != formState.primaryFormatType)
              .map((formatType) => {
                return (
                  <FormControlLabel
                    key={formatType.name}
                    value={formatType.name}
                    control={
                      <Checkbox
                        checked={formState.secondaryFormatTypes.includes(formatType.name)}
                        onChange={handleSecondaryFormatTypeSelection}
                        name={formatType.name}
                      />
                    }
                    label={formatType.readable}
                  />
                );
              })}
          </FormGroup>
        </FormSectionPaper>
      )}
    </>
  );
}
