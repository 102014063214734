import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseContactDialog from '@/ts/Components/icons/CloseContactDialog';
import { theme } from '@/ts/theme';
import styled from '@emotion/styled';

type ContactDialogProps = {
  open: boolean;
  setDialogOpen: (open: boolean) => void;
};

export default function ContactDialog({ open, setDialogOpen }: ContactDialogProps) {
  const [multipleEpisodes, setMultipleEpisodes] = React.useState(false);
  const [customAddress, setCustomAddress] = React.useState(false);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={() => setDialogOpen(false)}
      sx={{
        backdropFilter: 'blur(5px)',
        borderRadius: '22px',
      }}
      PaperProps={{
        sx: {
          padding: '1.75rem',
          borderRadius: '22px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.75rem',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h2" sx={{ lineHeight: 1 }}>
          Anfrage
        </Typography>
        <Box sx={{ cursor: 'pointer' }} onClick={() => setDialogOpen(false)}>
          <CloseContactDialog />
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: '16px',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          padding: '20px',
          display: 'flex',
          gap: '20px',
          flexDirection: 'column',
        }}
      >
        <InputContainer>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Sind Sie an einer Episode oder mehreren Episoden interessiert?
          </Typography>
          <RadioGroup row defaultValue="false" onChange={(e) => setMultipleEpisodes(e.target.value === 'true')}>
            <FormControlLabel value="false" control={<Radio />} label="Eine Episode" />
            <FormControlLabel value="true" control={<Radio />} label="Mehrere Episoden" />
          </RadioGroup>
        </InputContainer>
        {!multipleEpisodes && (
          <InputContainer>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              Welche Episode soll gebucht werden?
            </Typography>
            <RadioGroup row>
              <FormControlLabel value={0} control={<Radio />} label="Episode 1" />
              <FormControlLabel value={1} control={<Radio />} label="Episode 2" />
              <FormControlLabel value={2} control={<Radio />} label="Episode 3" />
              <FormControlLabel value={3} control={<Radio />} label="Episode 4" />
            </RadioGroup>
          </InputContainer>
        )}
        {multipleEpisodes && (
          <InputContainer>
            <Typography variant="subtitle2" fontSize="0.8125rem">
              Welche Episoden sollen gebucht werden?
            </Typography>
            <FormGroup row>
              <FormControlLabel value={0} control={<Checkbox />} label="Episode 1" />
              <FormControlLabel value={1} control={<Checkbox />} label="Episode 2" />
              <FormControlLabel value={2} control={<Checkbox />} label="Episode 3" />
              <FormControlLabel value={3} control={<Checkbox />} label="Episode 4" />
            </FormGroup>
          </InputContainer>
        )}
        <InputContainer>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Welches Werbeformat möchten Sie buchen?
          </Typography>
          <FormGroup row>
            <FormControlLabel value={'werbeformat1'} control={<Checkbox />} label="Werbeformat 1" />
            <FormControlLabel value={'werbeformat2'} control={<Checkbox />} label="Werbeformat 2" />
            <FormControlLabel value={'werbeformat3'} control={<Checkbox />} label="Werbeformat 3" />
            <FormControlLabel value={'werbeformat4'} control={<Checkbox />} label="Werbeformat 4" />
          </FormGroup>
        </InputContainer>
        <InputContainer>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Nachricht
          </Typography>
          <TextField
            hiddenLabel
            placeholder="Was möchten Sie uns noch mitteilen?"
            variant="outlined"
            multiline
            fullWidth
            minRows={3}
          />
        </InputContainer>
        <InputContainer>
          <RadioGroup row defaultValue="false" onChange={(e) => setCustomAddress(e.target.value === 'true')}>
            <FormControlLabel value="false" control={<Radio />} label="Rückmeldung an die Login-Adresse" />
            <FormControlLabel value="true" control={<Radio />} label="Rückmeldung an eine andere E-Mail Adresse" />
          </RadioGroup>
          {customAddress && (
            <TextField hiddenLabel placeholder="E-Mail Adresse" variant="outlined" fullWidth required />
          )}
        </InputContainer>
        <InputContainer>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ borderRadius: 4, color: theme.palette.secondary.main, padding: '12px', fontSize: '1.125rem' }}
          >
            Einsenden
          </Button>
        </InputContainer>
      </Box>
    </Dialog>
  );
}

const InputContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));
