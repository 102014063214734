import BreadcrumbsNavigation from '@/ts/Components/BreadcrumbsNavigation';
import AdvertisementTypes from '@/ts/Components/FormatDetail/AdvertisementTypes';
import AgeDistribution from '@/ts/Components/FormatDetail/AgeDistribution';
import ContactButton from '@/ts/Components/FormatDetail/ContactButton';
import CountryDistributionBarChart from '@/ts/Components/FormatDetail/CountryDistributionBarChart';
import Episode from '@/ts/Components/FormatDetail/Episode';
import GenderDistribution from '@/ts/Components/FormatDetail/GenderDistribution';
import Guests from '@/ts/Components/FormatDetail/Guests';
import HostInformation from '@/ts/Components/FormatDetail/HostInformation';
import ImagesGallery from '@/ts/Components/FormatDetail/ImagesGallery';
import KeyInformation from '@/ts/Components/FormatDetail/KeyInformation';
import MetadataChip from '@/ts/Components/FormatDetail/MetadataChip';
import Navigation from '@/ts/Components/FormatDetail/Navigation';
import PlatformChip from '@/ts/Components/FormatDetail/PlatformChip';
import ReachInformation from '@/ts/Components/FormatDetail/ReachInformation';
import { PAGE } from '@/ts/Components/Header';
import Page from '@/ts/Components/Page';
import { PlatformIcon } from '@/ts/Components/icons/Platform';
import { ContentCreatorData } from '@/ts/Types/ContentCreatorData';
import { Format, readablePrimaryType, sortedDates } from '@/ts/Types/Format';
import { ReadableFormatType } from '@/ts/Types/Readable';
import { ROLE_ADMIN, ROLE_ADVERTISER, ROLE_CREATOR_MANAGEMENT, User } from '@/ts/Types/User';
import { theme } from '@/ts/theme';
import styled from '@emotion/styled';
import { Link, usePage } from '@inertiajs/react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import axios from 'axios';
import ConfirmDialog from '../Components/Form/ConfirmDialog';

export default function FormatDetailPage() {
  const { format, user, formatTypes, contentCreatorData } = usePage<{
    format: Format;
    user: User;
    formatTypes: ReadableFormatType[];
    contentCreatorData: ContentCreatorData[];
  }>().props;

  const episodes = sortedDates(format);

  return (
    <Page user={user} title={format.name} activePage={PAGE.Formats}>
      <BreadcrumbsNavigation previousPages={[{ name: 'Showformate', url: '/formats' }]} currentPage={format.name} />
      <Container disableGutters maxWidth="xl" sx={{ position: 'relative', marginTop: '20px' }}>
        <Grid spacing={'20px'} container>
          <Grid item sm={0} lg={2} sx={{ height: '100%', marginTop: '20px' }} />
          <Grid item xs={12} sm={12} lg={9}>
            <Grid container columns={9} spacing={'20px'}>
              <Grid item sm={9}>
                <NavigatableSection id="about" />
                <Typography variant="h1" color={theme.palette.primary.main}>
                  {format.name}
                </Typography>
              </Grid>

              {format.description && (
                <Grid item sm={9} md={6} sx={{ marginBottom: '40px' }}>
                  <Typography variant="body1">{format.description}</Typography>
                </Grid>
              )}

              {!!format.images.length && (
                <Grid item sm={9} sx={{ marginBottom: '100px' }}>
                  <FormatPagePaper>
                    <ImagesGallery formatImages={format.images} />
                  </FormatPagePaper>
                </Grid>
              )}

              {(!!format.platforms.length || !!format.dates.length || !!format.languages.length) && (
                <Grid item sm={9}>
                  <NavigatableSection id="format" />

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 2,
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {format.platforms.map((platform) => {
                        return (
                          <PlatformChip
                            key={platform.name}
                            platform={platform.name}
                            icon={<PlatformIcon name={platform.name} size={24} />}
                          />
                        );
                      })}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <MetadataChip information={readablePrimaryType(format, formatTypes)} />
                      {format.dates.length > 1 && <MetadataChip information="Serie" />}
                      {format.languages.length > 0 && <MetadataChip information={format.languages[0].language} />}
                    </Box>
                  </Box>
                </Grid>
              )}

              <Grid item sm={9}>
                <FormatPagePaper sx={{ gap: '2rem' }}>
                  <KeyInformation format={format} />
                </FormatPagePaper>
              </Grid>

              <Grid item xl={3} sm={4.5} xs={9}>
                <FormatPagePaper sx={{ gap: '0.75rem', height: 1 }}>
                  <ReachInformation
                    name={'Unique Views'}
                    value={
                      formatNumber(format.expected_unique_views, closestUnit(format.expected_unique_views), 0) +
                      closestUnit(format.expected_unique_views)
                    }
                  />
                </FormatPagePaper>
              </Grid>

              <Grid item xl={3} sm={4.5} xs={9}>
                <FormatPagePaper sx={{ gap: '0.75rem', height: 1 }}>
                  <ReachInformation
                    name={'Social-Media-Reichweite'}
                    value={
                      formatNumber(
                        format.expected_social_media_views,
                        closestUnit(format.expected_social_media_views),
                        2,
                      ) + closestUnit(format.expected_social_media_views)
                    }
                  />
                </FormatPagePaper>
              </Grid>

              <Grid item xl={3} xs={9}>
                <FormatPagePaper sx={{ gap: '0.75rem', height: 1 }}>
                  <ReachInformation
                    name={'Gesamt-Reichweite'}
                    value={
                      formatNumber(format.expected_total_views, closestUnit(format.expected_total_views), 2) +
                      closestUnit(format.expected_total_views)
                    }
                  />
                </FormatPagePaper>
              </Grid>

              {format.host && (
                <Grid item xs={12} sm={9} sx={{ marginBottom: '100px' }}>
                  <NavigatableSection id="host" />
                  <FormatPagePaper sx={{ gap: '0.75rem' }}>
                    <HostInformation contentCreator={format.host} contentCreatorData={contentCreatorData} />
                  </FormatPagePaper>
                </Grid>
              )}

              {!!episodes.length && (
                <Grid
                  item
                  sm={9}
                  xs={12}
                  sx={{
                    marginBottom: '100px',
                    gap: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <NavigatableSection id="episoden" />

                  {episodes.map((episode, index) => {
                    const dateNow = Date.now();
                    const isPast = new Date(episode.start_time).getTime() < dateNow;
                    const isNext =
                      !isPast && index != 0 && new Date(episodes[index - 1].start_time).getTime() < dateNow;

                    return (
                      <Episode
                        key={index}
                        variant={isPast ? 'past' : isNext ? 'next' : 'future'}
                        episode={episode}
                        number={index + 1}
                        contentCreatorData={contentCreatorData}
                      />
                    );
                  })}
                </Grid>
              )}

              {format.dates.length === 1 && !!format.dates[0].guests.length && (
                <Grid
                  item
                  sm={9}
                  sx={{
                    marginBottom: '100px',
                    gap: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <NavigatableSection id="gäste" />
                  <Guests
                    contentCreators={format.dates[0].guests.map((g) => g.content_creator)}
                    contentCreatorData={contentCreatorData}
                  />
                </Grid>
              )}

              {!!format.target_audience && (
                <Grid item xs={9} md={4.5}>
                  <NavigatableSection id="zielgruppe" />
                  <FormatPagePaper sx={{ height: '100%' }}>
                    <AgeDistribution targetAudienceAgeDistribution={format.target_audience.age_ranges} />
                  </FormatPagePaper>
                </Grid>
              )}

              {!!format.target_audience && (
                <Grid item xs={9} md={4.5}>
                  <Box
                    sx={{
                      display: 'grid',
                      overflow: 'hidden',
                      gridTemplateColumns: 'repeat(1, 1fr)',
                      gridAutoRows: '1fr',
                      gridRowGap: '20px',
                      height: '100%',
                    }}
                  >
                    <Paper
                      sx={{
                        padding: '1.75rem',
                        borderRadius: '22px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexGrow: 1,
                        height: '100%',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                        gap: 1,
                      }}
                    >
                      <GenderDistribution targetAudience={format.target_audience} />
                    </Paper>
                    <FormatPagePaper
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        height: '100%',
                        overflow: 'hidden',
                        gap: 2.5,
                      }}
                    >
                      <Typography variant="h2" sx={{ marginBottom: 1 }}>
                        Länder
                      </Typography>

                      <Box sx={{ maxWidth: '100%' }}>
                        <CountryDistributionBarChart countryDistribution={format.target_audience.countries} />
                      </Box>
                    </FormatPagePaper>
                  </Box>
                </Grid>
              )}

              <Grid item xs={9}>
                <NavigatableSection id="werbeformate" />
                <AdvertisementTypes format={format} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* {!format.is_draft && <Navigation multipleEpisodes={format.dates.length > 1} />} */}

        {user.role === ROLE_ADVERTISER && <ContactButton />}
        {(user.role === ROLE_CREATOR_MANAGEMENT || user.role === ROLE_ADMIN) && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 40,
              right: 40,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Link href={'/formats/' + format.id + '/edit'}>
              <Button
                variant="outlined"
                sx={{
                  fontSize: '1rem',
                  padding: '1.2rem 1.375rem',
                  borderRadius: '22px',
                  color: theme.palette.common.white,
                  borderColor: theme.palette.common.white,
                  ':hover': {
                    borderColor: 'white',
                    color: 'white',
                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  },
                }}
              >
                Bearbeiten
              </Button>
            </Link>

            <ConfirmDialog
              dialogTitle="Showformat löschen"
              button={
                <Button
                  variant="outlined"
                  sx={{
                    fontSize: '1rem',
                    padding: '1.2rem 1.375rem',
                    borderRadius: '22px',
                    color: theme.palette.error.light,
                    borderColor: theme.palette.error.light,
                    ':hover': {
                      borderColor: theme.palette.error.main,
                      color: theme.palette.error.main,
                      backgroundColor: 'rgba(255, 255, 255, 0.04)',
                    },
                  }}
                  onClick={() => {
                    axios.delete('/api/formats/' + format.id).then(() => (window.location.href = '/formats'));
                  }}
                >
                  Löschen
                </Button>
              }
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

const FormatPagePaper = styled(Paper)(() => ({
  padding: '1.75rem',
  borderRadius: '22px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
}));

const NavigatableSection = styled(`a`)(() => ({
  display: 'block',
  position: 'relative',
  top: '-100px',
  visibility: 'hidden',
}));

const closestUnit = (value: number | string) => {
  if (Number(value) < 1000000) {
    return 'K';
  } else {
    return 'M';
  }
};

function formatNumber(value: number | string, currentUnit: 'M' | 'K', roundedDecimal?: number): number {
  let valueFormmated = value;
  switch (currentUnit) {
    case 'M':
      valueFormmated = Number(value) / 1000000;
      break;
    case 'K':
      valueFormmated = Number(value) / 1000;
      break;
    default:
      valueFormmated = Number(value);
      break;
  }
  return roundedDecimal ? Math.round(valueFormmated * 10 ** roundedDecimal) / 10 ** roundedDecimal : valueFormmated;
}
