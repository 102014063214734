import { ContentCreatorPlatform, FormContentCreator, isFormContentCreator } from '@/ts/Types/ContentCreator';
import { CreatorPlatformName } from '@/ts/Types/Platform';
import { ReadablePlatform } from '@/ts/Types/Readable';
import { Topic } from '@/ts/Types/Topic';
import { saveContentCreator } from '@/ts/createFormat';
import { theme } from '@/ts/theme';
import { contentCreatorIsValidForPosting } from '@/ts/validateFormat';
import styled from '@emotion/styled';
import { router, useForm } from '@inertiajs/react';
import { CheckCircle, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { FormEvent, useState } from 'react';
import TopicsSelector from './TopicsSelector';

type ContentCreatorFormDialogProps = {
  contentCreator: FormContentCreator;
  onUpdate: (newOrUpdatedContentCreator: FormContentCreator) => void;
  onClose: () => void;
  availableTopics: Topic[];
  availablePlatforms: ReadablePlatform[];
};

export default function ContentCreatorFormDialog({
  contentCreator,
  availablePlatforms,
  onUpdate,
  availableTopics,
  onClose,
}: ContentCreatorFormDialogProps) {
  const [saveInProgress, setSaveInProgress] = useState(false);
  const initialValue: FormContentCreator = contentCreator;

  const { data, setData } = useForm<FormContentCreator>(initialValue);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (!contentCreatorIsValidForPosting(data) || saveInProgress) return;
    setSaveInProgress(true);

    try {
      const res = await saveContentCreator(data);
      const newContentCreator = res?.data?.data;
      if (newContentCreator && isFormContentCreator(newContentCreator)) {
        onUpdate(newContentCreator);
        setSaveInProgress(false);
        onClose();
        router.reload({ only: ['contentCreators'] });
      } else {
        throw new Error('Invalid response');
      }
    } catch (e) {
      setSaveInProgress(false);
    }
  }

  return (
    <Dialog
      open={true}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
        sx: {
          borderRadius: '22px',
          border: `1px solid ${theme.palette.common.white}`,
          maxWidth: '100%',
          width: '64rem',
          background: theme.palette.background.paper,
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(10px)',
          },
        },
      }}
    >
      <DialogTitle sx={{ padding: 1.75 * 16 + 'px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h2"
            dangerouslySetInnerHTML={{
              __html: `${contentCreator.id ? 'Editiere' : 'Erstelle'} Content Creator`,
            }}
          />

          <IconButton
            type="button"
            onClick={onClose}
            title="Abbrechen"
            sx={{ color: theme.palette.common.white, borderRadius: '50%', border: '1px solid currentcolor' }}
          >
            <Close sx={{ color: 'currentcolor' }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 1.75 * 16 + 'px',
        }}
      >
        <ContentCreatorFormFields
          contentCreatorFormState={data}
          onUpdate={setData}
          availablePlatforms={availablePlatforms}
          availableTopics={availableTopics}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 1.75 * 16 + 'px', paddingTop: 0 }}>
        <Button
          type="submit"
          variant="outlined"
          color="secondary"
          sx={{
            paddingY: 1.5,
            width: 1,
            borderRadius: 2,
            color: theme.palette.secondary.main,
            ...(!contentCreatorIsValidForPosting(data) ? { backgroundColor: 'gray' } : {}),
          }}
          onClick={handleSubmit}
          disabled={!contentCreatorIsValidForPosting(data) || saveInProgress}
          style={
            !contentCreatorIsValidForPosting(data) ? { backgroundColor: 'gray', color: 'white', opacity: 0.3 } : {}
          }
        >
          {saveInProgress ? (
            <CircularProgress />
          ) : (
            <>{contentCreator.id ? 'Änderungen speichern' : 'Content Creator anlegen'}</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ContentCreatorFormFields({
  contentCreatorFormState,
  onUpdate,
  availableTopics,
  availablePlatforms,
}: {
  contentCreatorFormState: FormContentCreator;
  onUpdate: (contentCreator: FormContentCreator) => void;
  availableTopics: Topic[];
  availablePlatforms: ReadablePlatform[];
}) {
  return (
    <>
      <FormFieldBox>
        <Typography variant="subtitle2" fontSize="0.8125rem">
          Name
        </Typography>
        <TextField
          hiddenLabel
          value={contentCreatorFormState.name}
          onChange={(event) => {
            const newContentCreator = { ...contentCreatorFormState };
            newContentCreator.name = event.target.value;
            onUpdate(newContentCreator);
          }}
          placeholder="Name"
          variant="outlined"
          fullWidth
          required
        />
      </FormFieldBox>
      <FormFieldBox sx={{ marginTop: 1.5 * 4 }}>
        <FormFieldBox>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Themen
          </Typography>
          <TopicsSelector
            availableTopics={availableTopics}
            selectedTopics={contentCreatorFormState.topics}
            setSelectedTopics={(topics) => {
              const newContentCreatorData = { ...contentCreatorFormState };
              newContentCreatorData.topics = topics;
              onUpdate(newContentCreatorData);
            }}
          />
        </FormFieldBox>
        <FormFieldBox>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Hauptplattform
          </Typography>
          <ToggleButtonGroup
            value={contentCreatorFormState.main_platform}
            exclusive
            onChange={(event, newPlatformName: CreatorPlatformName) => {
              const newContentCreator = { ...contentCreatorFormState };
              newContentCreator.main_platform = newPlatformName;
              onUpdate(newContentCreator);
            }}
            aria-label="Hauptplattformauswahl"
            sx={{
              gap: '.75rem',
              flexWrap: 'wrap',
            }}
          >
            {availablePlatforms.map((platform) => {
              const isActive = platform.name === contentCreatorFormState.main_platform;
              return (
                <ToggleButton
                  key={platform.name}
                  value={platform.name}
                  aria-label={platform.readable}
                  sx={{
                    borderRadius: '12px !important',
                    padding: 0,
                    border: 'none',
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: '12px',
                      border: isActive
                        ? `1px solid ${theme.palette.primary.main}`
                        : `1px solid ${theme.palette.grey['500']}`,
                      padding: '.5rem 1rem',
                      display: 'flex',
                      whiteSpace: 'nowrap',
                      color: isActive ? theme.palette.common.black : theme.palette.primary.main,
                      background: isActive ? theme.palette.primary.main : null,
                    }}
                  >
                    {platform.readable}
                  </Box>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </FormFieldBox>
        <FormFieldBox>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Plattform
          </Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem' }}>
            {availablePlatforms.map((platform) => {
              const isActive = contentCreatorFormState.platforms.find(
                (p) => p.name === platform.name && p.social_handle.trim() !== '',
              );
              const isMainPlatform = platform.name === contentCreatorFormState.main_platform;
              return (
                <FieldGroup key={platform.name}>
                  <FormFieldBox sx={{ gap: '.75rem' }}>
                    <FieldGroupIntro>
                      <Typography variant="subtitle2" fontSize="0.8125rem">
                        {platform.readable}
                      </Typography>
                      {isMainPlatform && (
                        <TinyChip>
                          <Typography variant="subtitle2" fontSize="0.8125rem" color={theme.palette.secondary.main}>
                            Hauptplattform
                          </Typography>
                        </TinyChip>
                      )}
                      {isActive && <CheckCircle sx={{ fill: theme.palette.secondary.main }} />}
                    </FieldGroupIntro>
                    <TextField
                      hiddenLabel
                      value={
                        contentCreatorFormState.platforms.find((p) => p.name === platform.name)?.social_handle ?? ''
                      }
                      onChange={(event) => {
                        const newContentCreator = { ...contentCreatorFormState };
                        const newPlatforms = [
                          ...contentCreatorFormState.platforms.filter((p) => p.name !== platform.name),
                        ];
                        // remove empty social handles
                        if (event.target.value.trim()) {
                          newPlatforms.push({
                            name: platform.name,
                            social_handle: event.target.value,
                          } as ContentCreatorPlatform);
                        }
                        newContentCreator.platforms = newPlatforms;
                        onUpdate(newContentCreator);
                      }}
                      placeholder="Social handle"
                      variant="outlined"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: <span>@&nbsp;</span>,
                      }}
                    />
                  </FormFieldBox>
                </FieldGroup>
              );
            })}
          </Box>
        </FormFieldBox>
      </FormFieldBox>
    </>
  );
}

const FormFieldBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

const TinyChip = styled(Box)(() => ({
  padding: '.25rem',
  color: theme.palette.secondary.main,
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: '4px',
}));

const FieldGroup = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: '1rem',
  border: `1px solid ${theme.palette.grey['500']}`,
  borderRadius: '12px',
}));

const FieldGroupIntro = styled.div`
  display: flex;
  align-items: center;
  gap: 0.38rem;

  & > :first-child {
    margin-right: auto;
  }
`;
