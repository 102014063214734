import { SvgIcon } from '@mui/material';

export default function CheckboxChecked() {
  return (
    <SvgIcon width={18} height={18} viewBox="0 0 18 18">
      <rect width="18" height="18" rx="9" fill="#8258EC" />
      <path
        d="M5.52246 9.00106L8.02252 11.5011L13.0221 6.50098"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </SvgIcon>
  );
}
