import { FormSectionHeadline, FormSectionPaper } from '@/ts/Pages/FormatFormPage';
import { FormState } from '@/ts/Types/Format';
import { ContentPlatformName } from '@/ts/Types/Platform';
import { ReadablePlatform } from '@/ts/Types/Readable';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React from 'react';
import { useErrors } from './ErrorsContext';

type Data = {
  platforms: ContentPlatformName[];
};

export default function PlatformsForm({
  availablePlatforms,
  data,
  setPlatforms,
  errorMessage,
}: {
  availablePlatforms: ReadablePlatform[];
  data: Data;
  setPlatforms: (platforms: ContentPlatformName[]) => void;
  errorMessage?: string;
}) {
  const { getError } = useErrors();

  const handleGetErrors = () => {
    if (errorMessage) {
      return { field: 'platforms', message: errorMessage };
    } else {
      return getError('platforms');
    }
  };

  const error = handleGetErrors();

  function handlePlatformSelection(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) setPlatforms([...data.platforms, event.target.name as ContentPlatformName]);
    else setPlatforms(data.platforms.filter((value) => value != (event.target.name as ContentPlatformName)));
  }

  return (
    <>
      <FormSectionHeadline hasError={!!error} data-has-error={!!error}>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Plattformen' }} />
      </FormSectionHeadline>
      <FormSectionPaper hasError={!!error}>
        <FormGroup row>
          {availablePlatforms.map((platform) => {
            return (
              <FormControlLabel
                key={platform.name}
                value={platform.name}
                control={
                  <Checkbox
                    checked={data.platforms.includes(platform.name as ContentPlatformName)}
                    onChange={handlePlatformSelection}
                    name={platform.name}
                  />
                }
                label={platform.readable}
              />
            );
          })}
        </FormGroup>
        {!!error && <Typography color="error">{error.message}</Typography>}
      </FormSectionPaper>
    </>
  );
}
