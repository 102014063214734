import Page from '@/ts/Components/Page';
import { PAGE } from '@/ts/Components/Header';
import BreadcrumbsNavigation from '@/ts/Components/BreadcrumbsNavigation';
import { Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { usePage } from '@inertiajs/react';
import { User } from '@/ts/Types/User';
import PendingApprovalUser from './PendingApprovalUser';
import ApprovedUser from './ApprovedUser';
import CreateEditModal from './Modals/CreationEditUser';

const title = 'Benutzerkonten-Administration';
const subTitle = 'Warten auf die Bestätigung:';

export default function UserManagementPage() {
  const { approvalPendingUsers, approvedUsers, user } = usePage<{
    approvalPendingUsers: User[];
    approvedUsers: User[];
    user: User;
  }>().props;
  const [creationDialogOpen, setCreationDialogOpen] = React.useState(false);

  return (
    <Page user={user} title={title} activePage={PAGE.Administration}>
      <BreadcrumbsNavigation previousPages={[{ name: 'Administration', url: '/administration' }]} currentPage={title} />
      <Container maxWidth="xl" disableGutters>
        <Typography variant="h1">{title}</Typography>
        {approvalPendingUsers.length > 0 && <Typography variant="h4">{subTitle}</Typography>}
        <br />
        <Grid spacing={'20px'} container>
          {approvalPendingUsers.map((user) => (
            <PendingApprovalUser user={user} key={user.email} />
          ))}
        </Grid>
        <br />

        <Button sx={{ marginBottom: 4 }} variant={'outlined'} onClick={() => setCreationDialogOpen(true)}>
          Neuen Benutzer anlegen
        </Button>
        <Grid spacing={'20px'} container>
          {approvedUsers.map((user) => {
            return <ApprovedUser user={user} key={user.email} />;
          })}
        </Grid>
      </Container>
      <CreateEditModal open={creationDialogOpen} setOpen={setCreationDialogOpen} user={null} />
    </Page>
  );
}
