import * as Sentry from '@sentry/react';

export default function initSentry() {
  Sentry.init({
    dsn: 'https://a64a4e24ef56ce906d3519f28d044a9b@o4507327400640512.ingest.de.sentry.io/4507327517163600',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [window.location.origin],
        networkRequestHeaders: ['Cookie', 'X-Xsrf-Token'],
        networkResponseHeaders: ['Set-Cookie'],
      }),
      // Sentry.feedbackIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
