import { Werbeformat } from './Types';

interface ValidationRule {
  isInValid: (fs: Werbeformat) => boolean;
  error: (fs: Werbeformat) => void;
}

interface ValidationRules {
  [key: string]: ValidationRule | ValidationRule[];
}

export function formIsValid(form: Werbeformat): boolean {
  return getFormErrors(form).length === 0;
}

export type FormError = { field: string; message: string };

export function getFormErrors(form: Werbeformat): FormError[] {
  const errors: FormError[] = [];

  const insertError = (field: string, message: string) => {
    errors.push({ field, message });
  };

  const validationRules: ValidationRules = {
    name: {
      isInValid: (fs: Werbeformat) => fs.name.trim().length === 0,
      error: (fs: Werbeformat) => insertError('name', 'Name ist erforderlich'),
    },
    description: {
      isInValid: (fs: Werbeformat) => fs.description?.trim().length === 0,
      error: (fs: Werbeformat) => insertError('description', 'Beschreibung ist erforderlich'),
    },
    slug: {
      isInValid: (fs: Werbeformat) => {
        const slug = fs.slug?.trim();
        if (!slug || slug.includes(' ')) {
          return true;
        }
        const validCharacters = /^[a-zA-Z0-9-]+$/;
        return !validCharacters.test(slug);
      },
      error: (fs: Werbeformat) =>
        insertError(
          'slug',
          'Slug ist erforderlich und darf keine Leerzeichen enthalten. Verwenden Sie stattdessen "-"',
        ),
    },
    images: {
      isInValid: (fs: Werbeformat) => fs.images.length === 0 && fs.id === '',
      error: (fs: Werbeformat) => insertError('images', 'Bild ist erforderlich'),
    },
    platforms: {
      isInValid: (fs: Werbeformat) => fs.platforms.length === 0,
      error: (fs: Werbeformat) => insertError('list_of_platforms', 'Mindestens eine Plattform muss ausgewählt sein'),
    },
  };

  const validateField = (key: keyof Werbeformat, rule: ValidationRule | ValidationRule[], form: Werbeformat) => {
    if (Array.isArray(rule)) {
      rule.forEach((subRule) => {
        if (subRule.isInValid(form)) {
          subRule.error(form);
        }
      });
    } else {
      if (rule.isInValid(form)) {
        rule.error(form);
      }
    }
  };

  // Iterate over each rule and apply validations
  Object.keys(validationRules).forEach((key) => {
    const rule = validationRules[key];

    validateField(key as keyof Werbeformat, rule, form);
  });

  return errors;
}
