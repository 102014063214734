import { SvgIcon } from '@mui/material';

export default function ArrowDownSecondary() {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24">
      <path
        d="M6 10L11.7224 15.8819M13.202 14.9312L12.277 15.8819M12.277 15.8819C12.1239 16.0394 11.8756 16.0394 11.7224 15.8819M12.277 15.8819L18 10M11.7224 15.8819L10.798 14.9312"
        stroke="#FFFFFF"
        strokeOpacity="0.72"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
