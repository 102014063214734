import { User } from '@/ts/Types/User';
import { theme } from '@/ts/theme';
import { Grid, Paper, Box, Typography } from '@mui/material';
import React from 'react';
import { deleteUser } from './api';

import Edit from '@/ts/Components/icons/Edit';
import Delete from '@/ts/Components/icons/Delete';
import CreateEditModal from './Modals/CreationEditUser';

const ApprovedUser = ({ user }: { user: User }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            width: 1,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.grey['500'],
            borderRadius: '22px',
            paddingY: '22px',
            paddingX: '22px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h3">{user.name}</Typography>
            <Typography variant="subtitle1">{user.role}</Typography>
            <Typography marginTop={1} variant="body1">
              {user.email}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              width: '100%',
              '@media (min-width: 768px)': {
                width: 'auto',
              },
            }}
          >
            <Box sx={{ cursor: 'pointer' }} onClick={() => setDialogOpen(true)}>
              <Edit size={44} color={theme.palette.common.white} />
            </Box>

            <Box sx={{ cursor: 'pointer' }} onClick={() => deleteUser(user)}>
              <Delete size={44} color={theme.palette.error.main} />
            </Box>
          </Box>
        </Paper>
        <CreateEditModal open={dialogOpen} setOpen={setDialogOpen} user={user} />
      </Grid>
    </>
  );
};

export default ApprovedUser;
