import { Box, Dialog, Typography } from '@mui/material';
import ContentCreatorFullInformation from '@/ts/Components/FormatDetail/ContentCreatorFullInformation';
import React from 'react';
import Hide from '@/ts/Components/icons/Hide';
import { ContentCreator } from '@/ts/Types/ContentCreator';
import { ContentCreatorData } from '@/ts/Types/ContentCreatorData';

type ContentCreatorDetailDialogProps = {
  open: boolean;
  isHost: boolean;
  setDialogOpen: (open: boolean) => void;
  contentCreator: ContentCreator;
  additionalPlatformData: ContentCreatorData[];
};

export default function ContentCreatorDetailDialog({
  open,
  isHost,
  setDialogOpen,
  contentCreator,
  additionalPlatformData,
}: ContentCreatorDetailDialogProps) {
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      onClose={() => setDialogOpen(false)}
      sx={{
        backdropFilter: 'blur(5px)',
        borderRadius: '22px',
      }}
      PaperProps={{
        sx: {
          padding: '1.75rem',
          borderRadius: '22px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.75rem',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h2" sx={{ lineHeight: 1 }}>
          {isHost ? 'Host' : 'Gast'}
        </Typography>
        <Box sx={{ cursor: 'pointer' }} onClick={() => setDialogOpen(false)}>
          <Hide size={36} />
        </Box>
      </Box>
      <ContentCreatorFullInformation contentCreator={contentCreator} additionalPlatformData={additionalPlatformData} />
    </Dialog>
  );
}
