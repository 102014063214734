import { FormError } from '@/ts/validateFormat';
import { PropsWithChildren, createContext, useContext } from 'react';

export type BackendErrorMap = { [key: string]: string[] };

type ErrorsContextType = {
  errors: FormError[];
  hasError: (field: string) => boolean;
  getError: (field: string) => FormError | undefined;
};

export const ErrorsContext = createContext<ErrorsContextType | null>(null);

export function ErrorsContextProvider(
  props: PropsWithChildren<{ errors: FormError[]; backendErrors: BackendErrorMap }>,
) {
  const hasError = (field: string) => {
    return props.errors.some((error) => error.field === field) || props.backendErrors[field] !== undefined;
  };

  const getError = (field: string) => {
    const frontendError = props.errors.find((error) => error.field.indexOf(field) === 0);
    if (frontendError) return frontendError;
    const backendErrorKeys = Object.keys(props.backendErrors).filter((key) => key.startsWith(field));

    if (backendErrorKeys.length > 0) {
      return {
        field,
        message: backendErrorKeys.map((key) => props.backendErrors[key].join(', ')).join(', '),
      };
    }
    return undefined;
  };

  return (
    <ErrorsContext.Provider
      value={{
        errors: props.errors,
        hasError,
        getError,
      }}
    >
      {props.children}
    </ErrorsContext.Provider>
  );
}

export function useErrors() {
  const ctx = useContext(ErrorsContext);
  if (!ctx) {
    // throw new Error('useErrors must be used within a ErrorsContextProvider');

    return {
      errors: [],
      hasError: () => false,
      getError: () => undefined,
    };
  }
  return ctx;
}
