import AdvertisementTypesForm from '@/ts/Components/Form/AdvertisementTypesForm';
import ContentCreatorSelect from '@/ts/Components/Form/ContentCreatorSelect';
import EpisodesForm from '@/ts/Components/Form/EpisodesForm';
import FormatTypesForm from '@/ts/Components/Form/FormatTypesForm';
import ImagesForm from '@/ts/Components/Form/ImagesForm';
import LanguageForm from '@/ts/Components/Form/LanguageForm';
import NameAndDescriptionForm from '@/ts/Components/Form/NameAndDescriptionForm';
import PlatformsForm from '@/ts/Components/Form/PlatformsForm';
import EstimatedReachForm from '@/ts/Components/Form/ReachExpectationsForm';
import TargetAudienceForm from '@/ts/Components/Form/TargetAudienceForm';
import TopicsSelector from '@/ts/Components/Form/TopicsSelector';
import { PAGE } from '@/ts/Components/Header';
import Page from '@/ts/Components/Page';
import { AdvertisementType } from '@/ts/Types/AdvertisementType';
import { ContentCreator, FormContentCreator } from '@/ts/Types/ContentCreator';
import { FormDate, FormState, Format, OldImage } from '@/ts/Types/Format';
import { ContentPlatformName } from '@/ts/Types/Platform';
import { ReadableCountry, ReadableFormatType, ReadableLanguage, ReadablePlatform } from '@/ts/Types/Readable';
import { Topic } from '@/ts/Types/Topic';
import { ROLE_ADMIN, ROLE_CREATOR_MANAGEMENT, User } from '@/ts/Types/User';
import { getFormData, getFormState } from '@/ts/createFormat';
import { theme } from '@/ts/theme';
import { formStateIsValid, getFormStateErrors } from '@/ts/validateFormat';
import styled from '@emotion/styled';
import { useForm, usePage } from '@inertiajs/react';
import { Box, Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { FormEvent, useEffect, useRef, useState } from 'react';
import ConfirmDialog from '../Components/Form/ConfirmDialog';
import { BackendErrorMap, ErrorsContextProvider } from '../Components/Form/ErrorsContext';
import { useTranslation } from 'react-i18next';

const title = 'Showformat anlegen';

enum FormatFormPageState {
  EDIT = 'edit',
  CREATE = 'create',
}

export default function FormatFormPage() {
  const { t } = useTranslation('forms');
  const [publishingInProgress, setPublishingInProgress] = useState(false);
  const [savingAsDraftInProgress, setSavingAsDraftInProgress] = useState(false);

  const [errors, setErrors] = useState<ReturnType<typeof getFormStateErrors>>([]);
  const [backendErrors, setBackendErrors] = useState<BackendErrorMap>({});
  const wasSubmittedOnceRef = useRef(false);
  const [formatFormState, setFormatFormState] = useState<FormatFormPageState>(FormatFormPageState.CREATE);

  const {
    languages,
    countries,
    contentPlatforms,
    creatorPlatforms,
    formatTypes,
    topics,
    contentCreators,
    format,
    advertisementTypes,
    user,
  } = usePage<{
    languages: ReadableLanguage[];
    countries: ReadableCountry[];
    contentPlatforms: ReadablePlatform[];
    creatorPlatforms: ReadablePlatform[];
    formatTypes: ReadableFormatType[];
    topics: Topic[];
    contentCreators: ContentCreator[];
    format?: Format;
    advertisementTypes: AdvertisementType[];
    user: User;
  }>().props;

  const { data, setData } = useForm<FormState>(getFormState(format));

  async function saveOrUpdateFormat(mode: 'draft' | 'publish') {
    // is draft form state
    const isDraft = mode === 'draft';

    if (isDraft) {
      setSavingAsDraftInProgress(true);
    } else {
      setPublishingInProgress(true);
    }

    setBackendErrors({});

    const formData = getFormData(data);

    let url = `/api/${isDraft ? 'format-drafts' : 'formats'}`;

    if (formatFormState === FormatFormPageState.EDIT) {
      url += `/${data.id}`;
      formData.append('_method', 'put');
    }

    const requestOptions = {
      method: 'post',
      data: formData,
      url,
    };

    try {
      await axios(requestOptions).then(() => {
        // Redirect logic
        window.location.href = `${formatFormState === FormatFormPageState.EDIT ? `/formats/${data.id}` : '/formats'}`;
      });
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 422) {
        const errors = e.response.data as BackendErrorMap;
        if (errors) setBackendErrors(errors);
        setErrors([]);
        requestAnimationFrame(() => {
          document.querySelector('[data-has-error="true"]')?.scrollIntoView({ behavior: 'smooth' });
        });
      }
    } finally {
      if (isDraft) {
        setSavingAsDraftInProgress(false);
      } else {
        setPublishingInProgress(false);
      }
    }
  }

  const handleSubmit = (event: FormEvent, mode: 'draft' | 'publish') => {
    event.preventDefault();

    if (publishingInProgress) return;

    wasSubmittedOnceRef.current = true;

    // is draft form state
    const isDraft = mode === 'draft';

    const validationState = formStateIsValid(data, isDraft);

    if (!validationState) {
      setErrors(getFormStateErrors(data, isDraft));

      requestAnimationFrame(() => {
        document.querySelector('[data-has-error="true"]')?.scrollIntoView({ behavior: 'smooth' });
      });

      return;
    }

    saveOrUpdateFormat(mode);
  };

  const topicsError = errors.find((e) => e.field === 'topics');
  const hostError = errors.find((e) => e.field === 'host');
  const datesError = errors.find((e) => e.field === 'dates');

  // Set the formatFormPageState to either CREATE or EDIT
  useEffect(() => {
    format == null && data.id == null
      ? setFormatFormState(FormatFormPageState.CREATE)
      : setFormatFormState(FormatFormPageState.EDIT);
  }, [data]);
  return (
    <Page user={user} title={title} activePage={PAGE.Formats}>
      <Container disableGutters maxWidth="xl" sx={{ position: 'relative' }}>
        <form>
          <ErrorsContextProvider errors={errors} backendErrors={backendErrors}>
            <Grid container rowSpacing={12.5} justifyContent="center" sx={{ marginTop: 0 }}>
              <Grid xs={12} sm={8}>
                <NameAndDescriptionForm
                  formState={data}
                  setName={(name: string) => setData('name', name)}
                  setDescription={(description: string) => setData('description', description)}
                  setShortDescription={(shortDescription: string) => setData('shortDescription', shortDescription)}
                  setMinimumAdvertisingCost={(cost: number) => setData('minimumAdvertisingCost', cost)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <ImagesForm
                  data={data}
                  setImages={(
                    images: (File & {
                      preview: string;
                    })[],
                  ) => setData('images', images)}
                  setOldImages={(oldImages: OldImage[]) => setData('oldImages', oldImages)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormatTypesForm
                  availableFormatTypes={formatTypes}
                  formState={data}
                  setPrimaryFormatType={(formatType: string) => setData('primaryFormatType', formatType)}
                  setSecondaryFormatTypes={(formatTypes: string[]) => setData('secondaryFormatTypes', formatTypes)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <PlatformsForm
                  availablePlatforms={contentPlatforms}
                  data={data}
                  setPlatforms={(selectedPlatforms: ContentPlatformName[]) => setData('platforms', selectedPlatforms)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <LanguageForm
                  availableLanguages={languages}
                  formState={data}
                  setLanguage={(languageCode: string) => setData('language', languageCode)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormSectionHeadline hasError={!!topicsError} data-has-error={!!topicsError}>
                  <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Themen' }} />
                </FormSectionHeadline>
                <FormSectionPaper hasError={!!topicsError}>
                  <TopicsSelector
                    availableTopics={topics}
                    selectedTopics={data.topics}
                    setSelectedTopics={(selectedTopics: string[]) => setData('topics', selectedTopics)}
                  />
                  {!!topicsError && <Typography color="error">{topicsError.message}</Typography>}
                </FormSectionPaper>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormSectionHeadline hasError={!!hostError} data-has-error={!!hostError}>
                  <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Host der Show' }} />
                </FormSectionHeadline>
                <FormSectionPaper hasError={!!hostError}>
                  {data.host != null && (
                    <Button
                      onClick={() => setData('host', null)}
                      variant="outlined"
                      color="error"
                      sx={{ width: 'unset', alignSelf: 'flex-end' }}
                    >
                      Zurücksetzen
                    </Button>
                  )}
                  <ContentCreatorSelect
                    contentCreatorFormState={data.host}
                    setContentCreator={(contentCreator: FormContentCreator) => setData('host', contentCreator)}
                    availableTopics={topics}
                    availableContentCreators={contentCreators}
                    availablePlatforms={creatorPlatforms}
                  />
                  {!!hostError && <Typography color="error">{hostError.message}</Typography>}
                </FormSectionPaper>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormSectionHeadline hasError={!!datesError} data-has-error={!!datesError}>
                  <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Episoden' }} />
                </FormSectionHeadline>
                <EpisodesForm
                  formState={data}
                  setEpisodes={(episodes: FormDate[]) => setData('dates', episodes)}
                  availableContentCreators={contentCreators}
                  availableTopics={topics}
                  availablePlatforms={creatorPlatforms}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <EstimatedReachForm
                  formState={data}
                  setEstimatedUniqueViews={(estimatedViews: number) => setData('estimatedUniqueViews', estimatedViews)}
                  setEstimatedSocialMediaViews={(estimatedViews: number) =>
                    setData('estimatedSocialMediaViews', estimatedViews)
                  }
                  setEstimatedTotalViews={(estimatedViews: number) => setData('estimatedTotalViews', estimatedViews)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TargetAudienceForm
                  availableCountries={countries}
                  formState={data}
                  setMalePercentage={(percentage) => setData('targetAudienceMalePercentage', percentage)}
                  setFemalePercentage={(percentage) => setData('targetAudienceFemalePercentage', percentage)}
                  setDiversePercentage={(percentage) => setData('targetAudienceDiversePercentage', percentage)}
                  setCountries={(countries) => setData('targetAudienceCountries', countries)}
                  setTo18Percentage={(percentage) => setData('targetAudienceTo18Percentage', percentage)}
                  setTo24Percentage={(percentage) => setData('targetAudienceTo24Percentage', percentage)}
                  setTo34Percentage={(percentage) => setData('targetAudienceTo34Percentage', percentage)}
                  setTo44Percentage={(percentage) => setData('targetAudienceTo44Percentage', percentage)}
                  setOver44Percentage={(percentage) => setData('targetAudienceOver44Percentage', percentage)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <AdvertisementTypesForm
                  formState={data}
                  availableAdvertisementTypes={advertisementTypes}
                  setAdvertisementTypes={(advertisementTypes: string[]) =>
                    setData('advertisementTypes', advertisementTypes)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <Grid container spacing={2} columns={2}>
                  <Grid item xs>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        paddingY: 1.5,
                        width: 1,
                        borderRadius: 2,
                        ...(!formStateIsValid(data, false) ? { backgroundColor: 'gray' } : {}),
                      }}
                      onClick={(e) => handleSubmit(e, 'publish')}
                      style={
                        !formStateIsValid(data, false) ? { backgroundColor: 'gray', color: 'white', opacity: 0.3 } : {}
                      }
                    >
                      {publishingInProgress ? (
                        <CircularProgress />
                      ) : (
                        `${formatFormState === FormatFormPageState.EDIT && !data.isDraft ? t('update') : t('publish')}`
                      )}
                    </Button>
                  </Grid>

                  <Grid item xs>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        paddingY: 1.5,
                        width: 1,
                        borderRadius: 2,
                        ...(!formStateIsValid(data, true) ? { backgroundColor: 'gray' } : {}),
                      }}
                      onClick={(e) => handleSubmit(e, 'draft')}
                      style={
                        !formStateIsValid(data, true) ? { backgroundColor: 'gray', color: 'white', opacity: 0.3 } : {}
                      }
                    >
                      {savingAsDraftInProgress ? (
                        <CircularProgress />
                      ) : (
                        `${
                          formatFormState === FormatFormPageState.EDIT && data.isDraft
                            ? t('update_draft')
                            : t('save_as_draft')
                        }`
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ErrorsContextProvider>
        </form>

        {user.role === ROLE_CREATOR_MANAGEMENT ||
          (user.role === ROLE_ADMIN && (
            <Box
              sx={{
                position: 'fixed',
                bottom: 40,
                right: 40,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <ConfirmDialog
                dialogTitle="Showformat löschen"
                button={
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: '1rem',
                      padding: '1.2rem 1.375rem',
                      borderRadius: '22px',
                      color: theme.palette.error.light,
                      borderColor: theme.palette.error.light,
                      ':hover': {
                        borderColor: theme.palette.error.main,
                        color: theme.palette.error.main,
                        backgroundColor: 'rgba(255, 255, 255, 0.04)',
                      },
                    }}
                    onClick={() => {
                      axios.delete('/api/formats/' + data.id).then(() => (window.location.href = '/formats'));
                    }}
                  >
                    Löschen
                  </Button>
                }
              />
            </Box>
          ))}
      </Container>
    </Page>
  );
}

export const FormSectionHeadline = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<{ hasError?: boolean }>((props) => ({
  padding: '1rem 1.75rem',
  borderRadius: '22px',
  width: '100%',
  borderWidth: '1px',
  borderStyle: 'solid',
  marginBottom: '20px',
  borderColor: props.hasError ? theme.palette.error.main : theme.palette.primary.main,
  '& .MuiTypography-root': {
    color: props.hasError ? theme.palette.error.main : theme.palette.primary.main,
  },
}));

export const FormSectionPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<{ hasError?: boolean }>((props) => ({
  padding: '1.75rem',
  borderRadius: '22px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '24px',
  borderWidth: '1px',
  borderStyle: 'solid',
  marginBottom: '20px',
  borderColor: props.hasError ? theme.palette.error.main : theme.palette.background.paper,
}));

export const FormFieldLabelBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));
