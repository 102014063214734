import React from 'react';
import { Head } from '@inertiajs/react';
import { Box, Container, createTheme, CssBaseline, ThemeProvider, Typography } from '@mui/material';
import LogoutButton from '../Components/LogoutButton';

export default function TestPage() {
  return (
    <>
      <Head>
        <title>TEST PAGE</title>
      </Head>
      <ThemeProvider theme={createTheme()}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h6">
              Hello world!
            </Typography>
            <LogoutButton />
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
