import ContentCreatorSelect from '@/ts/Components/Form/ContentCreatorSelect';
import { ContentCreator } from '@/ts/Types/ContentCreator';
import { FormDate } from '@/ts/Types/Format';
import { ReadablePlatform } from '@/ts/Types/Readable';
import { Topic } from '@/ts/Types/Topic';
import { theme } from '@/ts/theme';
import styled from '@emotion/styled';
import { Box, Button, TextField, Typography } from '@mui/material';
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import { useErrors } from './ErrorsContext';

export default function DateForm({
  formDate,
  setDate,
  deleteDate,
  availableTopics,
  availableContentCreators,
  availablePlatforms,
  dateIndex,
  hostId,
}: {
  formDate: FormDate;
  setDate: (date: FormDate) => void;
  deleteDate: () => void;
  availableTopics: Topic[];
  availableContentCreators: ContentCreator[];
  availablePlatforms: ReadablePlatform[];
  dateIndex: number;
  hostId?: string;
}) {
  const { getError } = useErrors();
  const startingDateError = getError(`dates[${dateIndex}].startingDate`) || getError(`dates.${dateIndex}.start_time`);
  const startingTimeError = getError(`dates[${dateIndex}].startingTime`) || getError(`dates.${dateIndex}.end_time`);
  const durationError = getError(`dates[${dateIndex}].duration`);
  const guestsError = getError(`dates[${dateIndex}].guests`);

  return (
    <>
      <Button onClick={deleteDate} variant="outlined" color="error" sx={{ width: 'unset', alignSelf: 'flex-end' }}>
        Entfernen
      </Button>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormFieldBox>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Datum
          </Typography>
          <DateField
            value={dayjs(formDate.startingDate)}
            onChange={(newValue) => {
              const newFormDate = { ...formDate };
              newFormDate.startingDate = newValue?.valueOf() ?? null;
              setDate(newFormDate);
            }}
            format="DD.MM.YYYY"
            InputProps={{
              error: !!startingDateError,
            }}
            helperText={startingDateError?.message}
          />
        </FormFieldBox>
        <FormFieldBox>
          <Typography variant="subtitle2" fontSize="0.8125rem">
            Uhrzeit
          </Typography>
          <TimeField
            value={dayjs(formDate.startingTime)}
            onChange={(newValue) => {
              const newFormDate = { ...formDate };
              newFormDate.startingTime = newValue?.valueOf() ?? null;
              setDate(newFormDate);
            }}
            format="HH:mm"
            InputProps={{
              error: !!startingTimeError,
            }}
            helperText={startingTimeError?.message}
          />
        </FormFieldBox>
      </LocalizationProvider>
      <FormFieldBox>
        <Typography variant="subtitle2" fontSize="0.8125rem">
          Dauer
        </Typography>
        <TextField
          hiddenLabel
          value={formDate.duration ?? ''}
          onChange={(event) => {
            const newFormDate = { ...formDate };
            newFormDate.duration = event.target.value;
            setDate(newFormDate);
          }}
          placeholder="HH:mm"
          variant="outlined"
          fullWidth
          required
          error={!!durationError}
          helperText={durationError?.message}
        />
      </FormFieldBox>
      <Typography variant="h2" dangerouslySetInnerHTML={{ __html: 'Gäste' }} />
      {formDate.guests.map((guest, index) => {
        return (
          <Box
            key={index}
            sx={{
              width: 1,
              borderRadius: '12px',
              borderWidth: '1px',
              borderColor: theme.palette.grey['500'],
              borderStyle: 'solid',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Button
              onClick={() => {
                const newFormDate = { ...formDate };
                newFormDate.guests = formDate.guests.filter((g) => g != guest);
                setDate(newFormDate);
              }}
              variant="outlined"
              color="error"
              sx={{ width: 'unset', alignSelf: 'flex-end' }}
            >
              Entfernen
            </Button>
            <ContentCreatorSelect
              contentCreatorFormState={guest}
              setContentCreator={(contentCreator) => {
                const newDate = { ...formDate };
                newDate.guests = [...newDate.guests.filter((g) => g != guest), contentCreator];
                setDate(newDate);
              }}
              availableTopics={availableTopics}
              availableContentCreators={availableContentCreators}
              availablePlatforms={availablePlatforms}
              excludeContentCreatorId={hostId}
            />
          </Box>
        );
      })}
      {guestsError && <Typography color="error">{guestsError.message}</Typography>}
      <Button
        variant="outlined"
        onClick={() => {
          const newDate = { ...formDate };
          newDate.guests = [...formDate.guests, null];
          setDate(newDate);
        }}
      >
        Gast hinzufügen
      </Button>
    </>
  );
}

const FormFieldBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));
