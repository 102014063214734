import { PAGE } from '@/ts/Components/Header';
import BreadcrumbsNavigation from '@/ts/Components/BreadcrumbsNavigation';
import { theme } from '@/ts/theme';
import React from 'react';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import Page from '@/ts/Components/Page';
import OpenLink from '@/ts/Components/icons/OpenLink';
import { Link, usePage } from '@inertiajs/react';
import { User } from '@/ts/Types/User';

const title = 'Administration';

type AdministrationLink = {
  url: string;
  text: string;
};

const administrationLinks: AdministrationLink[] = [
  { url: '/administration/content-creators', text: 'Content Creators' },
  { url: '/administration/topics', text: 'Themen' },
  { url: '/administration/users', text: 'Benutzerkonten' },
  { url: '/formats/create', text: 'Showformat anlegen' },
  { url: '/werbeformats/create', text: 'Werbeformat anlegen' },
];

export default function AdministrationPage() {
  const { user } = usePage<{
    user: User;
  }>().props;

  return (
    <Page user={user} title={title} activePage={PAGE.Administration}>
      <BreadcrumbsNavigation currentPage={title} />
      <Container maxWidth="xl" disableGutters>
        <Typography variant="h1" dangerouslySetInnerHTML={{ __html: title }} />
        <Grid spacing={'20px'} container>
          {administrationLinks.map((link, index) => {
            return (
              <Grid key={index} item xs={12} lg={4} md={6}>
                <AdministrationLinkCard url={link.url} text={link.text} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Page>
  );
}

function AdministrationLinkCard({ url, text }: AdministrationLink) {
  return (
    <Link href={url} style={{ textDecoration: 'none' }}>
      <Paper
        sx={{
          width: 1,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.palette.grey['500'],
          borderRadius: '22px',
          paddingY: '22px',
          paddingX: '22px',
          minHeight: '8rem',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Typography variant="h2">{text}</Typography>
        <Box sx={{ flexShrink: 0 }}>
          <OpenLink size={28} />
        </Box>
      </Paper>
    </Link>
  );
}
